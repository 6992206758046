import {
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SAVE_ORGNANIZATION,
  ACTIVATE_APPLICATION
} from "@actions/settings/organization"
import { addGlobalMessage } from "@api/global_messages"
import { get, put } from "@src/helpers/fetch"

/**
 * Fetches user organization from the server
 *
 * @returns {Promise}
 */

export const getOrganization = () => (dispatch, getState) =>
  dispatch(get(GET_ORGANIZATION, `organizations/${getState().user.organization_id}`))

/**
 * Updates a user organization in state.settings.organization
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const updateOrganization = payload => dispatch =>
  dispatch({
    type: UPDATE_ORGANIZATION,
    payload
  })

/**
 * Saves a user organization to the server
 *
 * @todo fix payload from key/value to payload object
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const saveOrganization = (key, value) => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_ORGNANIZATION,
      `organizations/${getState().user.organization_id}`,
      { [key]: value },
      () => dispatch(addGlobalMessage("Saved!", "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )

/**
 * Activates an app for an organization or navigates to plan page
 *
 * @param {String} [app]
 *
 * @returns {Promise/Function}
 */

export const activateApplication = app => (dispatch, getState) => {
  const can_upgrade = getState().user.organization_id !== null
  if (can_upgrade) {
    dispatch(addApplication(app))
  } else {
    getState().utilities.history.push("settings/plans?show_application_requires_team_account_warning=true")
  }
}

/**
 * Activates an app for an organization or navigates to plan page
 *
 * @param {String} [app]
 *
 * @returns {Promise}
 */

const addApplication = app => (dispatch, getState) =>
  dispatch(
    put(
      ACTIVATE_APPLICATION,
      `organizations/${getState().user.organization_id}/settings/apps/enable`,
      { value: app },
      () => dispatch(addGlobalMessage("App activated!", "success")),
      () => dispatch(addGlobalMessage("An error occurred", "error"))
    )
  )
