const PERSONAL_FIELDS = [
  {
    name: "Personal Profile",
    inputs: [
      {
        key: "full_name",
        label: "Full Name",
        type: "input",
        className: "pr1 col-6",
        disabled: false
      },
      {
        key: "imdb",
        label: "IMDB Page",
        type: "input",
        className: "pl1 col-6",
        disabled: false
      },
      {
        key: "about",
        label: "About Me",
        type: "textarea",
        className: "col-12",
        disabled: false
      },
      {
        key: "city",
        label: "City",
        type: "input",
        className: "pr1 col-6",
        disabled: false
      },
      {
        key: "country_code",
        label: "Country",
        type: "select",
        className: "pl1 col-6",
        disabled: false,
        options: "countries"
      }
    ]
  },
  {
    name: "Contact Information",
    inputs: [
      {
        key: "email",
        label: "Email",
        type: "input",
        className: "pr1 col-6",
        disabled: true
      },
      {
        key: "mobile_number",
        label: "Phone Number",
        type: "input",
        className: "pl1 col-6",
        disabled: false
      }
    ]
  }
]

export default PERSONAL_FIELDS
