import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import { updateAccount, sendResetLink } from "@api/account"

const Forgot = ({ account, updateAccount, sendResetLink }) => {
  return (
    <>
      <Helmet title='CineSend | Forgot Password'/>
      <form>
        <label className='cs-label' htmlFor={`__input-email`}>Email</label>
        <input
          autoFocus={true}
          value={account.email}
          data-test-id={`account-email-input`}
          className={`cs-input mb2 col-12 ${account.validation_errors.includes('email') ? "error" : ""}`}
          type={'text'}
          id={`__input-email`}
          onChange={event => updateAccount({ email: event.target.value })}/>
        {account.validation_errors.includes('email') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-email-error-text`}
            className='block red'>
            Valid email is required.
          </small>
        )}
        {account.error_message && (
          <small data-test-id='account-error-text' className='red block mt1 mb2'>
            {account.error_message}
          </small>
        )}
        <button
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={sendResetLink}
          disabled={account.status === "PENDING" || !account.email}
          className={`cs-button ${account.status.toLowerCase()} col-12`}>
          Send Reset Link
        </button>
      </form>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  sendResetLink: bindActionCreators(sendResetLink, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)