import React from "react"
import AsperaLogo from "@src/media/icons/aspera.png"
import { can, VIEW_INVOICES, VIEW_USERS_AND_ROLES, MODIFY_SETTINGS } from "@src/helpers/authorization"

const isValidOrganization = organization => organization && organization._id

export const canWhiteLabel = organization =>
  isValidOrganization(organization) && (organization.plan.can_white_label || false)

export const canAccessFestivals = organization =>
  isValidOrganization(organization) && (organization.plan.can_access_festivals || false)

export const canAccessFiles = (user, organization) =>
  !organization || (organization.plan && organization.plan.can_access_files !== false)

const createSidebar = (user, organization) => {
  const canViewTeamProfile = isValidOrganization(organization) && can(user, [MODIFY_SETTINGS])
  const canViewUsage = canAccessFiles(user, organization)
  const canViewBilling = !isValidOrganization(organization) || can(user, [VIEW_INVOICES])
  const canViewUsersAndRoles = isValidOrganization(organization) && can(user, [VIEW_USERS_AND_ROLES])
  const canViewScreenersCustomization = canWhiteLabel(organization) && can(user, [MODIFY_SETTINGS])
  const canViewAsperaEntitlements = (canAccessFestivals(organization) || canAccessFiles(user, organization)) && isValidOrganization(organization) && can(user, [MODIFY_SETTINGS])
  return [
    {
      to: "/settings/apps",
      text: "My Apps",
      icon: "apps",
      show: true,
      label_before: "My Settings"
    },
    {
      to: "/settings/profile",
      text: "Personal Profile",
      icon: "person",
      show: true
    },
    {
      to: "/settings/notifications",
      text: "Notifications",
      icon: "notifications",
      show: true
    },
    {
      to: "/settings/security",
      text: "Security",
      icon: "lock",
      show: true
    },
    {
      to: "/settings/team",
      text: "Team Profile",
      icon: "apartment",
      show: canViewTeamProfile,
      label_before: "Team Settings"
    },
    {
      to: "/settings/billing",
      text: canViewUsage ? "Billing and Usage" : "Billing",
      icon: "receipt",
      show: canViewBilling
    },
    {
      to: "/settings/users",
      text: "Users",
      icon: "groups",
      show: canViewUsersAndRoles
    },
    {
      to: "/settings/roles",
      text: "Roles",
      icon: "verified_user",
      show: canViewUsersAndRoles
    },
    {
      to: "/settings/aspera",
      text: "Aspera Entitlements",
      icon: (
        <img
          className='cs-img'
          src={AsperaLogo}
          alt='Aspera'
          style={{
            width: '24px',
            height: '24px'
          }}/>
      ),
      show: canViewAsperaEntitlements,
    },
    {
      to: "/settings/screeners",
      text: "Screeners Customization",
      icon: "ondemand_video",
      show: canViewScreenersCustomization,
      label_before: "CineSend Files",
    }
  ].filter(opt => opt.show)
}

export default createSidebar
