import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

const TEXT = {
  credit_card_cardholder_name: "Cardholder name is required",
  credit_card_card_number: "A valid credit card number is required",
  credit_card_cvc: "A valid CVC is required",
  credit_card_expiry: "A valid expiry date is required",
  gift_card_pin: "A 4 digit pin is required",
  gift_card_number: "A 16 digit number is required"
}

const Errors = ({ errors, stripeError, giftCardError }) => (
  <React.Fragment>
    {(errors.length || stripeError || giftCardError) && (
      <ul className='list-style-none p0'>
        {errors.map(error => (
          <li key={error} className='flex items-center red'>
            <span className='material-icons mr1'>error</span>
            {TEXT[error]}
          </li>
        ))}
        {stripeError && (
          <li className='flex items-center red'>
            <span className='material-icons mr1'>error</span>
            {stripeError}
          </li>
        )}
        {giftCardError && (
          <li className='flex items-center red'>
            <span className='material-icons mr1'>error</span>
            {giftCardError}
          </li>
        )}
      </ul>
    )}
  </React.Fragment>
)

Errors.propTypes = {
  errors: PropTypes.array.isRequired,
  stripeError: PropTypes.string.isRequired,
  giftCardError: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  errors: state.settings.billing.new.errors,
  stripeError: state.settings.billing.new.stripe_error,
  giftCardError: state.settings.billing.new.gift_card_error
})

export default connect(
  mapStateToProps,
  null
)(Errors)
