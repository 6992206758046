import { GET_INVOICES_PENDING, GET_INVOICES_FAILED, GET_INVOICES_FULFILLED } from "@actions/settings/invoices"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_INVOICES_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_INVOICES_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_INVOICES_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.list,
        currentPage: parseInt(action.payload.current_page),
        totalResults: parseInt(action.payload.total_results)
      }

    default:
      return state
  }
}
