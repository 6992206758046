import React from "react"
import PropTypes from "prop-types"
import amex from "@src/media/payment/amex-logo.png"
import visa from "@src/media/payment/visa-logo.png"
import mastercard from "@src/media/payment/mastercard-logo.png"

const LOGO = {
  visa,
  mastercard,
  american_express: amex
}

const Footer = ({ expiry, brand, isExpired, isFailed }) => (
  <div
    style={{
      opacity: (isExpired || isFailed) ? "0.25" : "1"
    }}
    className='flex items-end p1'>
    <div className='flex-auto'>
      <small className='muted white block'>Expiry Date</small>
      <strong
        style={{
          lineHeight: '0.8em',
          marginTop: '0.3em'
        }}
        className='white block caption'>
        {expiry}
      </strong>
    </div>
    <img
      style={{
        maxWidth: '50px',
        maxHeight: '40px'
      }}
      src={LOGO[brand]}
      alt={brand}/>
  </div>
)

Footer.propTypes = {
  expiry: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired
}

export default Footer
