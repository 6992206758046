export const UPDATE_TEAM_ROLES = "UPDATE_TEAM_ROLES"

export const GET_TEAM_ROLES = "GET_TEAM_ROLES"
export const GET_TEAM_ROLES_PENDING = "GET_TEAM_ROLES_PENDING"
export const GET_TEAM_ROLES_FAILED = "GET_TEAM_ROLES_REJECTED"
export const GET_TEAM_ROLES_FULFILLED = "GET_TEAM_ROLES_FULFILLED"

export const UPDATE_NEW_ROLE = "UPDATE_NEW_ROLE"

export const GET_TEAM_ROLES_DEFAULTS = "GET_TEAM_ROLES_DEFAULTS"
export const GET_TEAM_ROLES_DEFAULTS_PENDING = "GET_TEAM_ROLES_DEFAULTS_PENDING"
export const GET_TEAM_ROLES_DEFAULTS_FAILED = "GET_TEAM_ROLES_DEFAULTS_REJECTED"
export const GET_TEAM_ROLES_DEFAULTS_FULFILLED = "GET_TEAM_ROLES_DEFAULTS_FULFILLED"

export const CREATE_ROLE = "CREATE_ROLE"
export const CREATE_ROLE_PENDING = "CREATE_ROLE_PENDING"
export const CREATE_ROLE_FAILED = "CREATE_ROLE_REJECTED"
export const CREATE_ROLE_FULFILLED = "CREATE_ROLE_FULFILLED"

export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_PENDING = "DELETE_ROLE_PENDING"
export const DELETE_ROLE_FAILED = "DELETE_ROLE_REJECTED"
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED"

export const OPEN_EDIT_ROLE = "OPEN_EDIT_ROLE"
export const CLOSE_EDIT_ROLE = "CLOSE_EDIT_ROLE"
export const UPDATE_EDIT_ROLE = "UPDATE_EDIT_ROLE"

export const SAVE_ROLE = "SAVE_ROLE"
export const SAVE_ROLE_PENDING = "SAVE_ROLE_PENDING"
export const SAVE_ROLE_FAILED = "SAVE_ROLE_REJECTED"
export const SAVE_ROLE_FULFILLED = "SAVE_ROLE_FULFILLED"
