const client = `https://accounts.cinesend.com`
const api = `https://api.cinesend.com/api`
const stripeKey = `pk_live_Wl0SxWj9MQR0cUz2bLGMAeZV`

export const GLOBALS = {
  PORT: 8009,
  BUILD_ENV: 'production',
  URL: client,
  API_URL: api,
  SOCIAL_AUTH_URL: `${api}/auth/google`,
  ACCOUNT_REDIRECT_URL: `${client}/login`,
  DEFAULT_REDIRECT_URL: client,
  TESTING: true,
  STRIPE_KEY: stripeKey,
  ERROR_URL: client + `/error`
}