export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"

export const LOGIN = "LOGIN"
export const LOGIN_PENDING = "LOGIN_PENDING"
export const LOGIN_FAILED = "LOGIN_REJECTED"
export const LOGIN_FULFILLED = "LOGIN_FULFILLED"

export const VALIDATE_USER_EMAIL = "VALIDATE_USER_EMAIL"
export const VALIDATE_USER_EMAIL_PENDING = "VALIDATE_USER_EMAIL_PENDING"
export const VALIDATE_USER_EMAIL_FAILED = "VALIDATE_USER_EMAIL_REJECTED"
export const VALIDATE_USER_EMAIL_FULFILLED = "VALIDATE_USER_EMAIL_FULFILLED"

export const SIGNUP = "SIGNUP"
export const SIGNUP_FAILED = "SIGNUP_REJECTED"
export const SIGNUP_FULFILLED = "SIGNUP_FULFILLED"
export const SIGNUP_PENDING = "SIGNUP_PENDING"

export const SEND_RESET_LINK = "SEND_RESET_LINK"
export const SEND_RESET_LINK_PENDING = "SEND_RESET_LINK_PENDING"
export const SEND_RESET_LINK_FAILED = "SEND_RESET_LINK_REJECTED"
export const SEND_RESET_LINK_FULFILLED = "SEND_RESET_LINK_FULFILLED"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_REJECTED"
export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED"

export const GET_INVITE = "GET_INVITE"
export const GET_INVITE_PENDING = "GET_INVITE_PENDING"
export const GET_INVITE_FAILED = "GET_INVITE_REJECTED"
export const GET_INVITE_FULFILLED = "GET_INVITE_FULFILLED"

export const ACCEPT_INVITE = "ACCEPT_INVITE"
export const ACCEPT_INVITE_PENDING = "ACCEPT_INVITE_PENDING"
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_REJECTED"
export const ACCEPT_INVITE_FULFILLED = "ACCEPT_INVITE_FULFILLED"

export const SUBMIT_MFA_LOGIN = "SUBMIT_MFA_LOGIN"
export const SUBMIT_MFA_LOGIN_PENDING = "SUBMIT_MFA_LOGIN_PENDING"
export const SUBMIT_MFA_LOGIN_FAILED = "SUBMIT_MFA_LOGIN_REJECTED"
export const SUBMIT_MFA_LOGIN_FULFILLED = "SUBMIT_MFA_LOGIN_FULFILLED"

export const SET_ACCOUNT_STATUS = "SET_ACCOUNT_STATUS"
export const EXTERNAL_LOGIN = "EXTERNAL_LOGIN"
export const EXTERNAL_LOGIN_FULFILLED = "EXTERNAL_LOGIN_FULFILLED"