import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateRolesSettings, createRole } from "@api/settings/roles"

const Header = ({ updateRolesSettings, createRole, search }) => (
  <div className='flex items-center'>
    <h4>Roles</h4>
    <div className='flex items-center justify-end flex-auto'>
      <input
        value={search}
        className='cs-input'
        placeholder='Search for a role...'
        onChange={e => updateRolesSettings({ search: e.target.value })}/>
      <button onClick={() => createRole()} className='ml2 cs-button'>
        Create New Role
      </button>
    </div>
  </div>
)

Header.propTypes = {
  updateRolesSettings: PropTypes.func.isRequired,
  createRole: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  search: state.settings.roles.search
})

const mapDispatchToProps = dispatch => ({
  updateRolesSettings: bindActionCreators(updateRolesSettings, dispatch),
  createRole: bindActionCreators(createRole, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
