import {
  GET_PAYMENT_METHODS_PENDING,
  GET_PAYMENT_METHODS_FAILED,
  GET_PAYMENT_METHODS_FULFILLED,
  DELETE_CREDIT_CARD_PENDING,
  DELETE_CREDIT_CARD_FAILED,
  DELETE_CREDIT_CARD_FULFILLED,
  UPDATE_DEFAULT_CREDIT_CARD_PENDING,
  UPDATE_DEFAULT_CREDIT_CARD_FAILED,
  UPDATE_DEFAULT_CREDIT_CARD_FULFILLED,
  UPDATE_NEW_PAYMENT_METHOD,
  ADD_CREDIT_CARD_PENDING,
  ADD_CREDIT_CARD_FAILED,
  ADD_CREDIT_CARD_FULFILLED,
  ADD_GIFT_CARD_PENDING,
  ADD_GIFT_CARD_FAILED,
  ADD_GIFT_CARD_FULFILLED,
  RETRY_PAYMENT_PENDING,
  RETRY_PAYMENT_FAILED,
  RETRY_PAYMENT_FULFILLED,
  RESET_NEW_PAYMENT_METHOD,
  GET_UPCOMING_INVOICE_FULFILLED
} from "@actions/settings/billing"
import {
  CREATE_SUBSCRIPTION_PENDING,
  CREATE_SUBSCRIPTION_FULFILLED,
  SET_STORAGE_AMOUNT_PENDING,
  SET_STORAGE_AMOUNT_FULFILLED,
  CANCEL_SUBSCRIPTION_PENDING,
  CANCEL_SUBSCRIPTION_FULFILLED
} from "@actions/settings/subscription"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_DEFAULT_CREDIT_CARD_PENDING:
    case GET_PAYMENT_METHODS_PENDING:
    case DELETE_CREDIT_CARD_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case UPDATE_DEFAULT_CREDIT_CARD_FAILED:
    case GET_PAYMENT_METHODS_FAILED:
    case DELETE_CREDIT_CARD_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case UPDATE_DEFAULT_CREDIT_CARD_FULFILLED:
    case GET_PAYMENT_METHODS_FULFILLED:
    case DELETE_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    case RESET_NEW_PAYMENT_METHOD:
      return {
        ...state,
        new: INITIAL_STATE.new
      }

    case UPDATE_NEW_PAYMENT_METHOD:
      return {
        ...state,
        new: {
          ...state.new,
          ...action.payload
        }
      }

    case ADD_GIFT_CARD_PENDING:
    case ADD_CREDIT_CARD_PENDING:
      return {
        ...state,
        new: {
          ...state.new,
          status: "PENDING"
        }
      }

    case ADD_GIFT_CARD_FAILED:
      return {
        ...state,
        new: {
          ...state.new,
          status: "READY",
          gift_card_error: action.error.message
        }
      }

    case ADD_CREDIT_CARD_FAILED:
      return {
        ...state,
        new: {
          ...state.new,
          status: "ERROR",
          stripe_error: action.payload.message
        }
      }

    case ADD_CREDIT_CARD_FULFILLED:
      return {
        ...state,
        new: INITIAL_STATE.new,
        credit_cards: action.payload
      }

    case ADD_GIFT_CARD_FULFILLED:
      return {
        ...state,
        new: INITIAL_STATE.new,
        ...action.payload
      }

    case RETRY_PAYMENT_PENDING:
      return {
        ...state
      }

    case RETRY_PAYMENT_FAILED:
      return {
        ...state
      }

    case RETRY_PAYMENT_FULFILLED:
      return {
        ...state,
        ...action.payload.payment_methods
      }

    case GET_UPCOMING_INVOICE_FULFILLED:
      return {
        ...state,
        upcoming_invoice: action.payload.invoice
      }

    case SET_STORAGE_AMOUNT_PENDING:
    case CANCEL_SUBSCRIPTION_PENDING:
    case CREATE_SUBSCRIPTION_PENDING:
      return {
        ...state,
        status: 'UPDATING'
      }

    case SET_STORAGE_AMOUNT_FULFILLED:
    case CANCEL_SUBSCRIPTION_FULFILLED:
    case CREATE_SUBSCRIPTION_FULFILLED:
      return {
        ...state,
        status: 'READY'
      }
    default:
      return state
  }
}
