import { GET_BRANDING, UPDATE_BRANDING, SAVE_BRANDING, SAVE_FONT, SAVE_FONT_PENDING } from "@actions/settings/branding"
import { get, put, post } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"

/**
 * Fetches user organization team roles from the server
 *
 * @returns {Promise}
 */

export const getBranding = () => (dispatch, getState) =>
  dispatch(get(GET_BRANDING, `organizations/${getState().user.organization_id}/branding`))

export const updateBranding = (key, value) => dispatch =>
  dispatch({
    type: UPDATE_BRANDING,
    payload: { key, value }
  })

export const saveBranding = (data) => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_BRANDING,
      `organizations/${getState().user.organization_id}/branding`,
      data,
      () => {
        dispatch(addGlobalMessage("Saved!", "success"))
      },
      () => {
        dispatch(addGlobalMessage("Error. Please try again.", "error"))
      }
    )
  )

export const saveFont = (name, url) => (dispatch, getState) =>
  dispatch(
    post(
      SAVE_FONT,
      `organizations/${getState().user.organization_id}/branding/fonts`,
      {
        name,
        url
      },
      res => {
        dispatch(addGlobalMessage("Your font has been uploaded. You can now select it from the list.", "success"))
      }
    )
  )

export const setToPending = () => dispatch =>
  dispatch({
    type: SAVE_FONT_PENDING
  })
