export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION"
export const CREATE_SUBSCRIPTION_PENDING = "CREATE_SUBSCRIPTION_PENDING"
export const CREATE_SUBSCRIPTION_FULFILLED = "CREATE_SUBSCRIPTION_FULFILLED"
export const CREATE_SUBSCRIPTION_FAILED = "CREATE_SUBSCRIPTION_REJECTED"

export const SET_STORAGE_AMOUNT = "SET_STORAGE_AMOUNT"
export const SET_STORAGE_AMOUNT_PENDING = "SET_STORAGE_AMOUNT_PENDING"
export const SET_STORAGE_AMOUNT_FULFILLED = "SET_STORAGE_AMOUNT_FULFILLED"
export const SET_STORAGE_AMOUNT_FAILED = "SET_STORAGE_AMOUNT_REJECTED"

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION_PENDING = "CANCEL_SUBSCRIPTION_PENDING"
export const CANCEL_SUBSCRIPTION_FULFILLED = "CANCEL_SUBSCRIPTION_FULFILLED"
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_REJECTED"