import {
  GET_SUBSCRIPTION_PRICE_FULFILLED,
  GET_STORAGE_CYCLE_PRICE_FULFILLED,
  GET_STORAGE_CYCLE_PRICE_PENDING,
  GET_STORAGE_PRORATED_PRICE_FULFILLED,
  GET_STORAGE_PRORATED_PRICE_PENDING,
  GET_TEAM_SEAT_CYCLE_PRICE_FULFILLED,
} from "@actions/prices"

const initialState = {
  subscription: {
    status: 'PENDING',
    price: {
      monthly: {},
      yearly: {}
    }
  },
  storage: {
    status: 'PENDING',
    price: {}
  },
  team_seat: {
    status: 'PENDING',
    price: {}
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SUBSCRIPTION_PRICE_FULFILLED:
      return {
        ...state,
        subscription: {
          status: 'READY',
          price: action.payload.price
        }
      }
    case GET_STORAGE_CYCLE_PRICE_PENDING:
    case GET_STORAGE_PRORATED_PRICE_PENDING:
      return {
        ...state,
        storage: {
          ...state.storage,
          status: 'PENDING',
        }
      }
    case GET_STORAGE_CYCLE_PRICE_FULFILLED:
    case GET_STORAGE_PRORATED_PRICE_FULFILLED:
      return {
        ...state,
        storage: {
          status: "READY",
          price: action.payload.price,
        }
      }
    case GET_TEAM_SEAT_CYCLE_PRICE_FULFILLED:
      return {
        ...state,
        team_seat: {
          status: 'READY',
          price: action.payload.price
        }
      }

    default:
      return state
  }
}
