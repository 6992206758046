const INITIAL_STATE = {
  status: "PENDING",
  list: [],
  items: [],
  selectedEntitlement: null,
  showPurchaseModal: false,
  isSubmitting: false,
  isLoadingItems: true,
  currentPage: 0,
  totalResults: 0,
  quote: {
    status: "PENDING",
    subtotal: 0,
    tax: 0,
    total: 0
  }
}

export default INITIAL_STATE
