import {
  UPDATE_TEAM_SETTINGS,
  GET_TEAM_MEMBERS,
  CHANGE_USER_ROLE,
  TOGGLE_USER_STATUS,
  DELETE_USER,
  RESEND_USER_INVITE,
  UPDATE_NEW_USER,
  INVITE_USER
} from "@actions/settings/team"
import { get, post, del, put } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"
import { VALIDATE_EMAIL } from "@src/constants/validate"

/**
 * Fetches user organization team members from the server
 *
 * @returns {Promise}
 */

export const getTeamMembers = () => (dispatch, getState) =>
  dispatch(get(GET_TEAM_MEMBERS, `organizations/${getState().user.organization_id}/users`))

/**
 * Updates settings.organization.team
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const updateTeamSettings = payload => dispatch => dispatch({ type: UPDATE_TEAM_SETTINGS, payload })

/**
 * Updates a users role
 *
 * @param {String} [userID]
 * @param {String} [roleID]
 *
 * @returns {Promise}
 */

export const changeUserRole = (userID, roleID) => (dispatch, getState) =>
  dispatch(
    post(
      CHANGE_USER_ROLE,
      `organizations/${getState().user.organization_id}/roles/${roleID}/users/${userID}`,
      {},
      () => dispatch(addGlobalMessage("Role Updated", "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )

/**
 * Toggles a users status (deactivated, activated)
 *
 * @param {Object} [user]
 *
 * @returns {Promise}
 */

export const toggleUserStatus = user => (dispatch, getState) =>
  dispatch(
    post(
      TOGGLE_USER_STATUS,
      `organizations/${getState().user.organization_id}/users/${user._id}/${
        user.status === "Active" ? "deactivate" : "reactivate"
      }`,
      {},
      () => dispatch(addGlobalMessage(`User Updated`, "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )

/**
 * Removes a user from an organization
 *
 * @param {Object} [user]
 *
 * @returns {Promise}
 */

export const deleteUser = user => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to delete " + user.full_name + "?")) {
    dispatch(
      del(
        DELETE_USER,
        `organizations/${getState().user.organization_id}/users/${user._id}`,
        {},
        () => dispatch(addGlobalMessage("User Deleted", "success")),
        () => dispatch(addGlobalMessage("An error occurred.", "error"))
      )
    )
  }
}

/**
 * Resend a user organization invite
 *
 * @param {Object} [user]
 *
 * @returns {Promise}
 */

export const resendUserInvite = user => (dispatch, getState) =>
  dispatch(
    put(
      RESEND_USER_INVITE,
      `organizations/${getState().user.organization_id}/users/${user._id}/resend`,
      {},
      () => dispatch(addGlobalMessage(`Invite resent to ${user.full_name}.`, "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )

/**
 * Updates state.settings.team.new
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const updateNewUser = payload => dispatch =>
  dispatch({
    type: UPDATE_NEW_USER,
    payload
  })

/**
 * Validates a new user payload
 *
 * @returns {Promise}
 */

export const validateNewUser = () => (dispatch, getState) => {
  const new_user = getState().settings.team.new
  const errors = ["full_name", "role_id", "email"].filter(key => {
    if (key === "email") {
      return !VALIDATE_EMAIL.test(new_user.email)
    } else {
      return !new_user[key]
    }
  })
  dispatch(updateNewUser({ errors }))
  if (!errors.length) {
    dispatch(submitNewUser())
  }
}

/**
 * Creates a new user in an organization
 *
 * @returns {Promise}
 */

export const submitNewUser = () => (dispatch, getState) =>
  dispatch(
    post(
      INVITE_USER,
      `organizations/${getState().user.organization_id}/users`,
      {
        users: [
          {
            name: getState().settings.team.new.full_name,
            email: getState().settings.team.new.email,
            role_id: getState().settings.team.new.role_id
          }
        ]
      },
      () => dispatch(addGlobalMessage("User Invited", "success")),
      error => dispatch(addGlobalMessage(error.message || "An error occurred.", "error"))
    )
  )
