import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getOrganization } from "@api/settings/organization"
import { Status } from "@bitcine/cs-theme"
import { useDidMount } from "rooks"
import Helmet from "react-helmet"
import Form from "./components/form"

const Organization = ({ getOrganization, organization }) => {
  useDidMount(() => {
    getOrganization()
  })
  return (
    <div data-test-id='organization-profile'>
      <Helmet title='CineSend | Organization'/>
      <Status pending={organization.status === "PENDING"} error={organization.status === "ERROR"}>
        <Form/>
      </Status>
    </div>
  )
}

Organization.propTypes = {
  organization: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired,
  getOrganization: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.settings.organization
})

const mapDispatchToProps = dispatch => ({
  getOrganization: bindActionCreators(getOrganization, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization)
