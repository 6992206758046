import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"

const TermsAndConditions = ({ value, update }) => {
  const [terms, setTerms] = useState(value)
  const [previewModalOpen, togglePreviewModal] = useState(false)
  return (
    <>
      <p>
        Enter the terms and conditions that audiences viewing your screeners will need to agree to. This input form
        supports HTML tags such as:
      </p>
      <h4>{"<h4>Heading</h4>"}</h4>
      <b>{"<b>bold</b>"}</b>
      <br/>
      <i>{"<i>italics</i>"}</i>
      <br/>
      <textarea
        className='col-12 mt2 cs-textarea'
        value={terms}
        onChange={e => setTerms(e.target.value)}
        onBlur={e => update(e.target.value)}/>
      <button className='small white mt2 align-right cs-button' onClick={() => togglePreviewModal(!previewModalOpen)}>
        Preview Terms and Conditions
      </button>
      {previewModalOpen && (
        <Modal open width={3} title='Preview of Terms and Conditions' onClose={() => togglePreviewModal(false)}>
          <div dangerouslySetInnerHTML={createMarkup(terms)}/>
        </Modal>
      )}
    </>
  )
}

const createMarkup = value => {
  const markup = value.replace(/(?:\r\n|\r|\n)/g, "<br>")
  return { __html: markup }
}

TermsAndConditions.propTypes = {
  value: PropTypes.string,
  update: PropTypes.func.isRequired
}

export default TermsAndConditions
