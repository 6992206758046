import { AUTH_CHECK_FULFILLED } from "@actions/auth"
import { LOGIN_FULFILLED, ACCEPT_INVITE_FULFILLED, SUBMIT_MFA_LOGIN_FULFILLED, EXTERNAL_LOGIN_FULFILLED } from "@actions/account"
import { UPDATE_USER } from "@actions/user"
import { VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED, DISABLE_MFA_FULFILLED } from "@actions/settings/security"
import { SET_STORAGE_AMOUNT_FULFILLED, CANCEL_SUBSCRIPTION_FULFILLED, CREATE_SUBSCRIPTION_FULFILLED } from "@actions/settings/subscription"
import { ACTIVATE_APPLICATION_FULFILLED } from "@actions/settings/organization"
import { PURCHASE_ASPERA_ENTITLEMENT_FULFILLED } from "@actions/settings/aspera_entitlements"

const INITIAL_STATE = {}

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOGIN_FULFILLED:
    case EXTERNAL_LOGIN_FULFILLED:
    case AUTH_CHECK_FULFILLED:
    case SUBMIT_MFA_LOGIN_FULFILLED:
    case SET_STORAGE_AMOUNT_FULFILLED:
      return {
        ...action.payload.user
      }

    case ACCEPT_INVITE_FULFILLED:
    case ACTIVATE_APPLICATION_FULFILLED:
      return {
        ...action.payload
      }

    case UPDATE_USER:
      return {
        ...state,
        ...action.payload
      }

    case CREATE_SUBSCRIPTION_FULFILLED:
    case CANCEL_SUBSCRIPTION_FULFILLED:
    case VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED:
      return {
        ...state,
        ...action.payload.user
      }

    case DISABLE_MFA_FULFILLED:
      return {
        ...state,
        ...action.payload
      }

    case PURCHASE_ASPERA_ENTITLEMENT_FULFILLED:
      return {
        ...state,
        organization: {
          ...state.organization,
          aspera_entitlement_bytes_remaining: action.payload.new_total
        }
      }

    default:
      return state
  }
}
