import React from "react"

const ExternalLogin = ({ info }) => {  
  const handleRedirect = () => {
    window.location = info.redirect_url
  }

  return (
    <div className="flex flex-column items-center">
      {info.logo_url && <div className="flex">
        <img src={info.logo_url} style={{ maxWidth: '100%', padding: '32px' }} alt={`Logo for ${info.organization_name}`}/>
      </div>}
      <p>{info?.header_text}</p>
      <button className="cs-button" onClick={handleRedirect}>
        {info?.button_text}
      </button>
    </div>
  )
}

export default ExternalLogin
