import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { logOut } from "@api/auth"
import { useDidMount } from "rooks"

const LogOut = ({ logOut }) => {
  useDidMount(() => logOut())
  return (
    <div className='center py4'>
      <div className='spinner m2'/>
    </div>
  )
}

LogOut.propTypes = {
  logOut: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(LogOut)
