import { VALIDATE_PASSWORD } from "./validate"

export const INPUTS = [
  {
    label: "Current Password",
    type: "password",
    key: "current",
    validate: (val, obj) => val,
    error_message: "Current password is required"
  },
  {
    label: "New Password",
    type: "password",
    key: "new",
    validate: (val, obj) => VALIDATE_PASSWORD.test(val),
    error_message: "Passwords must contain 8 characters including 1 special character"
  },
  {
    label: "Confirm New Password",
    type: "password",
    key: "confirm",
    validate: (val, obj) => val === obj.new,
    error_message: "Passwords do not match"
  }
]
