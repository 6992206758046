import React, { useState, useEffect } from "react"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Range from "@src/components/range/"
import { Status, Modal } from "@bitcine/cs-theme"
import { updateStorage } from "@api/settings/subscription"
import { getStorageProratedPrice, getStorageCyclePrice } from "@api/prices"
import humanFileSize from "@src/helpers/humanFileSize"

const ONE_TERABYTE_IN_BYTES = 1099511627776

const Storage = ({ user, storage, price, updateStorage, getStorageProratedPrice, getStorageCyclePrice, pending }) => {
  const [amount, setAmount] = useState(user.subscription.storage_count || 0)
  const [modalOpen, setModalOpen] = useState(false)
  const [sliding, setSliding] = useState(false)
  const getTotalStorage = newAmount => {
    const newBytes = newAmount * ONE_TERABYTE_IN_BYTES
    return humanFileSize(user.subscription.storage.base + newBytes)
  }
  const canChangeToAmount = newAmount => {
    const newBytes = newAmount * ONE_TERABYTE_IN_BYTES
    const newTotalStorage = user.subscription.storage.base + newBytes
    return newTotalStorage > user.subscription.storage.used
  }
  const isCurrentAmount = newAmount => {
    return user.subscription.storage_count === newAmount
  }
  useEffect(() => {
    getStorageCyclePrice()
  }, [getStorageCyclePrice])
  return (
    <div className={'border border-gray-5 rounded p1 mt1'}>
      <div className={'mb1'}>
        <progress value={user.subscription.storage.used} max={user.subscription.storage.total} className={'cs-progress red'}/>
      </div>
      <div className={'flex items-center justify-between'}>
        <p>
          You have currently used <strong>{humanFileSize(user.subscription.storage.used, 2)}</strong> of{" "}
          <strong>{humanFileSize(user.subscription.storage.total)}</strong> total storage.{" "}
        </p>
        {!["free", "enterprise"].includes(user.subscription.type) && !user.subscription.stripe_subscription_ends_at &&
          <span
            className='pointer underline bold'
            onClick={() => setModalOpen(true)}>
            Modify your storage
          </span>}
      </div>
      {modalOpen && <Modal width={2} open onClose={() => setModalOpen(false)} title={"Modify Storage"}>
        <Status pending={pending}>
          <p className={'mb2'}>
            Your current total storage amount is <b>{humanFileSize(user.subscription.storage.total)}</b>. Select a new total storage amount.
          </p>
          <Range
            value={amount + 1}
            onChange={value => {
              setAmount(value)
              setSliding(true)
            }}
            onChangeComplete={() => {
              if (!isCurrentAmount(amount)) {
                getStorageProratedPrice(amount)
                setSliding(false)
              }
            }}
            max={100}
            step={1}
            format={value => value + " TB"}
            labels={[1, 25, 50, 75, 100].reduce((all, v) => ({ ...all, [v]: `${v} TB` }), {})}
            min={0}/>
          <div className='right-align mt4'>
            {!canChangeToAmount(amount) && (
              <div className='py1 red'>
                You are using too much space to switch to this amount of storage. Please delete items from your
                account.
              </div>
            )}
            {storage.error && <div className='py1 red'>{storage.error}</div>}
          </div>
          {!isCurrentAmount(amount) && !sliding && price.interval_amount > 0 && storage.status !== 'PENDING' && <div className={'bg-gray-5 rounded p2 my1'}>
            <div>Your new additional storage charge will be <b>{price.interval_amount_formatted}</b>.</div>
            {price.prorated_amount &&
            <div>
              Approximate prorated amount being charged today for your current billing cycle: <b>{price.prorated_amount_formatted}</b>.
            </div>}
          </div>}
          {price.unit_amount_formatted && <p className={'italic muted small'}>
            Additional storage is billed at <strong>{price.unit_amount_formatted}</strong>.
          </p>}
          <div className={'flex items-center justify-end mt2'}>
            <button
              className={`cs-button ${storage.status === 'PENDING' && 'pending'}`}
              disabled={isCurrentAmount(amount) || storage.status === 'PENDING'}
              onClick={() => {
                updateStorage(amount)
                setModalOpen(false)
              }}>
              Modify storage to {getTotalStorage(amount)}{price.prorated_amount_formatted && <span> and pay {price.prorated_amount_formatted} now</span>}
            </button>
          </div>
        </Status>
      </Modal>}
    </div>
  )
}

Storage.propTypes = {
  user: PropTypes.shape({}).isRequired,
  updateStorage: PropTypes.func.isRequired,
  getStorageProratedPrice: PropTypes.func.isRequired,
  getStorageCyclePrice: PropTypes.func.isRequired,
  storage: PropTypes.object,
  pending: PropTypes.bool
}

const mapStateToProps = state => ({
  user: state.user,
  pending: state.prices.storage.status === 'PENDING',
  storage: state.prices.storage,
  price: state.prices.storage.price
})

const mapDispatchToProps = dispatch => ({
  updateStorage: bindActionCreators(updateStorage, dispatch),
  getStorageCyclePrice: bindActionCreators(getStorageCyclePrice, dispatch),
  getStorageProratedPrice: bindActionCreators(getStorageProratedPrice, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Storage)
