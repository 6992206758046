import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter, Redirect } from "react-router-dom"
import queryString from "query-string"
import dayjs from "dayjs"
import Helmet from "react-helmet"
import { updateAccount, signUp } from "@api/account"
import { getSubscriptionPrice } from "@api/prices"
import CreditCardForm from '@src/pages/settings/pages/billing/components/add/form'

const perks = [
  '1TB of storage',
  '20 monthly studio-grade DRM-encoded screeners',
  'Screener rooms and analytics',
  'Aspera file requests',
  '... and so much more!'
]

const Payment = ({ price, getSubscriptionPrice, account, card, updateAccount, signUp, submitting, error, location, history }) => {
  const googleID = queryString.parse(window.location.search).googleID || null
  const countryCode = queryString.parse(window.location.search).countryCode || account.country_code || 'US'
  useEffect(() => {
    if (googleID) {
      updateAccount({
        googleID,
        country_code: queryString.parse(window.location.search).countryCode,
        email: queryString.parse(window.location.search).email,
        full_name: queryString.parse(window.location.search).fullName,
      })
    }
  }, [updateAccount, googleID])
  const unset = !googleID && (!account.email || !account.full_name || !account.password || !account.country_code)
  useEffect(() => {
    getSubscriptionPrice({ countryCode })
  }, [countryCode, getSubscriptionPrice])
  return (
    <>
      <Helmet title='CineSend | Free Trial'/>
      {unset && <Redirect to='/signup'/>}
      <form>
        <h4 className={'center border-gray-5 border-bottom mb1 pb2'}>Sign up for a free 30-day trial!</h4>
        <div className={'fs12 bold italic mb1'}>Your subscription includes:</div>
        {perks.map((perk, i) => <li className='fs12' key={i}>{perk}</li>)}
        <div className={'border-gray-5 border-top mt1 pt2'}/>
        <CreditCardForm newPaymentMethod={'credit-card'}/>
        {error && <div className={'mb1 red bold'}>
          {error}
        </div>}
        <div className={'bg-gray-5 rounded p2 small my1'}>
          Your 30-day free trial lasts until <b>{dayjs.utc().add(30, 'days').format("MMM Do, YYYY")}</b>. You can cancel your subscription before the trial ends and you won't be charged. Otherwise, you'll pay {price.interval_amount_formatted} for the service as long as your account is open. You can cancel at any time.
        </div>
        <div className={'fs12 muted mt1'}>By clicking you agree to the <a rel="noopener noreferrer" href='https://cinesend.com/terms-and-conditions' target='_blank'>Terms and Conditions.</a></div>
        <button
          id='cinesend-final-sign-up-button'
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            signUp(googleID)
          }}
          disabled={submitting || account.status === "PENDING" || [
            "credit_card_cardholder_name",
            "credit_card_card_number",
            "credit_card_cvc",
            "credit_card_expiry"
          ].filter(key => !card[key]).length > 0}
          className={`cs-button ${account.status.toLowerCase()} col-12 cinesend-final-sign-up-button`}>
          Sign up
        </button>
      </form>
    </>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  countries: state.utilities.countries.list,
  card: state.settings.billing.new,
  price: state.prices.subscription.price.monthly,
  submitting: state.account.status === 'PENDING',
  error: state.account.error_message
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  signUp: bindActionCreators(signUp, dispatch),
  getSubscriptionPrice: bindActionCreators(getSubscriptionPrice, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment))