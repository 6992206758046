import {
  UPDATE_TEAM_ROLES,
  GET_TEAM_ROLES,
  CREATE_ROLE,
  DELETE_ROLE,
  OPEN_EDIT_ROLE,
  CLOSE_EDIT_ROLE,
  UPDATE_EDIT_ROLE,
  SAVE_ROLE
} from "@actions/settings/roles"
import { get, post, del, put } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"

/**
 * Fetches user organization team roles from the server
 *
 * @returns {Promise}
 */

export const getTeamRoles = () => (dispatch, getState) =>
  dispatch(get(GET_TEAM_ROLES, `organizations/${getState().user.organization_id}/roles`))

/**
 * Updates settings.organization.team
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const updateRolesSettings = payload => dispatch =>
  dispatch({
    type: UPDATE_TEAM_ROLES,
    payload
  })

/**
 * Creates a new role in an organization
 *
 * @returns {Promise}
 */

export const createRole = () => (dispatch, getState) =>
  dispatch(
    post(
      CREATE_ROLE,
      `organizations/${getState().user.organization_id}/roles`,
      {},
      () => dispatch(addGlobalMessage("New role created", "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )
/**
	 * Deletes a role in an organization
	 *
	 * @param {Object} [role]

	 * @returns {Promise}
	 */

export const deleteRole = role => (dispatch, getState) => {
  if (window.confirm("Are you sure you want to delete " + role.name + "?")) {
    dispatch(
      del(
        DELETE_ROLE,
        `organizations/${getState().user.organization_id}/roles/${role._id}`,
        {},
        () => dispatch(addGlobalMessage("Role deleted", "success")),
        () => dispatch(addGlobalMessage("An error occurred.", "error"))
      )
    )
  }
}

/**
	 * Updates a role on the server
	 * @todo improve payload creation
	 *
	 * @param {Object} [role]

	 * @returns {Promise}
	 */

export const saveRole = role => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_ROLE,
      `organizations/${getState().user.organization_id}/roles/${role._id}`,
      {
        name: getState().settings.roles.edit.role.name,
        description: getState().settings.roles.edit.role.description,
        permissions: getState().settings.roles.edit.role.permissions
      },
      () => dispatch(addGlobalMessage("Role updated", "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )

export const openEditRole = role => dispatch => dispatch({ type: OPEN_EDIT_ROLE, role })
export const closeEditRole = () => dispatch => dispatch({ type: CLOSE_EDIT_ROLE })

export const updateEditRole = payload => dispatch => dispatch({ type: UPDATE_EDIT_ROLE, payload })
