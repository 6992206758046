import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import { getBranding, updateBranding, saveBranding } from "@api/settings/branding"
import { Status, Checkbox } from "@bitcine/cs-theme"
import { Prompt } from "react-router"
import GoogleFontPicker from "./components/google_font_picker"
import CustomFontPicker from "./components/custom_font_picker"
import Logo from "./components/logo"
import FontUploader from "./components/font_uploader"
import ColorPicker from "./components/color_picker"
import WebSafeFontPicker from "./components/web_safe_font_picker"
import TermsAndConditions from "./components/terms_and_conditions"

class Branding extends React.Component {
  constructor() {
    super()
    this.state = {
      uploadingScreenerLogo: false
    }
  }
  componentDidMount() {
    this.props.getBranding()
  }
  componentDidUpdate = () => {
    const {
      branding: { initHash, newHash }
    } = this.props
    if (initHash !== newHash) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }
  render() {
    const {
      branding: { status, initHash, newHash, items },
      updateBranding,
      saveBranding,
      organization
    } = this.props
    const { uploadingScreenerLogo } = this.state
    const requiresSaving = initHash !== newHash
    const updateAndSaveBranding = (key, value) => {
      updateBranding(key, value)
      saveBranding({ [key]: value })
    }
    return (
      <Status pending={status === "PENDING"}>
        <Prompt when={requiresSaving} message='You have unsaved changes, are you sure you want to leave?'/>
        <div>
          <Helmet title='CineSend | Branding'/>
          <div className='bg-white rounded box-shadow p2 mb2'>
            <h4 className='flex-auto mb1'>Fonts</h4>
            <div className='flex mb1'>
              <div className='mr2'>
                <Checkbox
                  label='Use Google font'
                  checked={!items.use_custom_font}
                  onChange={() => updateAndSaveBranding("use_custom_font", false)}/>
              </div>
              <div>
                <Checkbox
                  label='Use custom font'
                  checked={items.use_custom_font}
                  onChange={() => updateAndSaveBranding("use_custom_font", true)}/>
              </div>
            </div>
            {items.use_custom_font ? (
              <div className='flex items-center my2'>
                <div className='mr1'>
                  <strong>&nbsp;</strong>
                  <FontUploader organization={organization} onUploadStart={() => {}} onUploadComplete={() => {}}/>
                </div>
                <div className='flex-auto mr1'>
                  <strong>Header Text</strong>
                  <CustomFontPicker
                    selected={items.custom_header_font_id}
                    onChange={option => updateAndSaveBranding("custom_header_font_id", option)}/>
                </div>
                <div className='flex-auto'>
                  <strong>Body Text</strong>
                  <CustomFontPicker
                    selected={items.custom_body_font_id}
                    onChange={option => updateAndSaveBranding("custom_body_font_id", option)}/>
                </div>
              </div>
            ) : (
              <div className='flex items-center my2'>
                <div className='col-12 mr1'>
                  <strong>Header Text</strong>
                  <GoogleFontPicker
                    selected={items.header_text_font_family}
                    onChange={option => updateAndSaveBranding("header_text_font_family", option)}/>
                </div>
                <div className='col-12 ml1'>
                  <strong>Body Text</strong>
                  <GoogleFontPicker
                    selected={items.body_text_font_family}
                    onChange={option => updateAndSaveBranding("body_text_font_family", option)}/>
                </div>
              </div>
            )}
          </div>
          <div className='bg-white rounded box-shadow p2 mb2'>
            <h4 className='flex-auto'>Colors</h4>
            <div className='mt2 flex items-center block'>
              <ColorPicker
                className='mb3'
                color={items.body_background_color}
                onChange={color => updateAndSaveBranding("body_background_color", color)}
                label={"Body Background"}/>
              <ColorPicker
                className='mb3'
                color={items.header_text_font_color}
                onChange={color => updateAndSaveBranding("header_text_font_color", color)}
                label={"Header Text"}/>
              <ColorPicker
                className='mb3'
                color={items.body_text_font_color}
                onChange={color => updateAndSaveBranding("body_text_font_color", color)}
                label={"Body Text"}/>
              <ColorPicker
                className='mb3'
                color={items.sidebar_background_color}
                onChange={color => updateAndSaveBranding("sidebar_background_color", color)}
                label={"Sidebar Background"}/>
              <ColorPicker
                className='mb3'
                color={items.sidebar_text_font_color}
                onChange={color => updateAndSaveBranding("sidebar_text_font_color", color)}
                label={"Sidebar Text"}/>
            </div>
          </div>
          <div className='bg-white rounded box-shadow p2 mb2'>
            <div className='mb2 flex items-center'>
              <h4 className='flex-auto'>Organization Details</h4>
            </div>
            <div className='flex items-center mb2'>
              <div className='mr2'>
                <Logo
                  url={items.screener_logo_url}
                  organization={organization}
                  backgroundColor={items.sidebar_background_color ? items.sidebar_background_color.hex : "white"}
                  onUploadStart={() => this.setState({ uploadingScreenerLogo: true })}
                  onUploadComplete={(data, url) => {
                    this.setState({ uploadingScreenerLogo: false })
                    updateBranding('screener_logo_url', data)
                    saveBranding({ screener_logo_url: url })
                  }}/>
              </div>
              <div className='flex flex-auto pl1 items-center'>
                <strong className='flex-auto bold'>
                  {uploadingScreenerLogo ? "Uploading..." : "Select a logo for the screeners page"}
                </strong>
                <button
                  disabled={!items.screener_logo_url || uploadingScreenerLogo}
                  className='cs-button white small'
                  onClick={() => updateAndSaveBranding("screener_logo_url", null)}>
                  Remove Image
                </button>
              </div>
            </div>
            <div className='flex items-center mb2'>
              <Checkbox
                label='Display company name below logo'
                checked={items.show_company_name}
                onChange={() => updateAndSaveBranding("show_company_name", !items.show_company_name)}/>
            </div>
          </div>
          <div className='bg-white rounded box-shadow p2 mb2'>
            <div className='mb2 flex items-center'>
              <h4 className='flex-auto'>Emails</h4>
            </div>
            <div className='flex items-center mt2'>
              <div className='mr1 col-6'>
                <strong>
                  Font Family<span className='muted small ml1'>(email client compatible)</span>
                </strong>
                <WebSafeFontPicker
                  selected={items.email_text_font_family}
                  onChange={option => updateAndSaveBranding("email_text_font_family", option)}/>
              </div>
            </div>
          </div>
          <div className='bg-white rounded box-shadow p2 mb2'>
            <div className='mb2 flex items-center justify-between'>
              <h4 className='flex-auto'>Terms and Conditions</h4>
              <button
                className='cs-button white small'
                onClick={() => updateAndSaveBranding("terms_and_conditions_enabled", !items.terms_and_conditions_enabled)}>
                {items.terms_and_conditions_enabled ? "Disable " : "Enable "}Terms and Conditions
              </button>
            </div>
            <div className='mt2'>
              {items.terms_and_conditions_enabled && (
                <TermsAndConditions
                  update={value => updateAndSaveBranding("terms_and_conditions", value)}
                  value={items.terms_and_conditions}/>
              )}
            </div>
          </div>
        </div>
      </Status>
    )
  }
}

Branding.propTypes = {
  organization: PropTypes.object.isRequired,
  getBranding: PropTypes.func.isRequired,
  branding: PropTypes.object.isRequired,
  updateBranding: PropTypes.func.isRequired,
  saveBranding: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.user.organization,
  branding: state.settings.branding
})

const mapDispatchToProps = dispatch => ({
  getBranding: bindActionCreators(getBranding, dispatch),
  updateBranding: bindActionCreators(updateBranding, dispatch),
  saveBranding: bindActionCreators(saveBranding, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Branding)
