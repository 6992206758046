import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { deleteCreditCard, setDefaultCreditCard } from "@api/settings/billing/index"
import { confirmAction } from "@api/utilities"
import styled from 'styled-components'

const Icon = styled.button`
  border-radius: 50%;
  width: 1.7em;
  height: 1.7em;
  min-width: 0px;
  margin-right: -0.3em;
  padding: 0;
`

const Header = ({ isDefault, deleteCreditCard, setDefaultCreditCard, card, isExpired, isFailed }) => (
  <div className='flex items-center justify-end p1'>
    {(isDefault || isExpired || isFailed) && (
      <strong className={`white flex flex-auto items-center caption`}>
        <Icon
          style={{
            fontSize: '1.7em',
            background: 'transparent',
            border: 'transparent'
          }}
          className={`cs-button material-icons tooltip`}
          data-tooltip={
            isFailed
              ? 'This card has recently failed a payment.'
              : isExpired
                ? 'This card has expired.'
                : 'This card is set as your default payment method.'
          }>
          {(isExpired || isFailed) ? 'error_outline' : 'check_circle'}
        </Icon>
        &nbsp; {isFailed 
          ? 'Payment Error'
          : isExpired
            ? 'Expired'
            : 'Default Card'}
      </strong>
    )}
    <ButtonDropdown
      button={{
        className: "material-icons link",
        menu: false,
        style: { color: "white" },
        text: "more_vert"
      }}
      dropdown={{
        clickCloses: true,
        content: [
          {
            text: "Set as Default Credit Card",
            disabled: isDefault || isExpired || isFailed,
            onClick: () => setDefaultCreditCard(card._id),
            show: true
          },
          {
            text: "Remove",
            onClick: () => {
              confirmAction("Are you sure you want to delete this credit card?")
                .then(() => deleteCreditCard(card._id))
                .catch(() => null)
            },
            show: true
          }
        ].filter((opt) => opt.show)
      }}/>
  </div>
)

Header.propTypes = {
  isDefault: PropTypes.bool.isRequired,
  card: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  deleteCreditCard: PropTypes.func.isRequired,
  setDefaultCreditCard: PropTypes.func.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired
}

const mapDispatchToProps = dispatch => ({
  deleteCreditCard: bindActionCreators(deleteCreditCard, dispatch),
  setDefaultCreditCard: bindActionCreators(setDefaultCreditCard, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(Header)
