import React from "react"
import PropTypes from "prop-types"
import { Checkbox } from "@bitcine/cs-theme"

const Form = ({ form, apps, errors, onUpdate }) => (
  <form>
    <div className='mb2'>
      <label htmlFor='role-name'>Role Name</label>
      <input
        autoFocus
        value={form.name}
        placeholder='Accounting'
        onChange={e => onUpdate("name", e.target.value)}
        className='cs-input col-12 block'/>
      {errors.includes("name") && <div className='red'>A role name is required</div>}
    </div>
    <div className='mb2 pb2 border-bottom border-gray-5'>
      <label htmlFor='role-name'>Role Description</label>
      <textarea
        value={form.description}
        placeholder='A short description about the role'
        onChange={e => onUpdate("description", e.target.value)}
        className='cs-textarea col-12 block'/>
      {errors.includes("description") && <div className='red'>A role description is required</div>}
    </div>
    {Object.keys(form.permissions).map(key => (
      <div key={key} className='mb2'>
        <strong className='block mb1'>{form.permissions[key].label}</strong>
        {Object.keys(form.permissions[key].permissions)
          .filter(k => !(key === "products") || (apps[k.split("_")[k.split("_").length - 1]]))
          .map(k => (
            <div key={k}>
              <Checkbox
                label={form.permissions[key].permissions[k].label}
                checked={form.permissions[key].permissions[k].value}
                onChange={() =>
                  onUpdate("permissions", {
                    ...form.permissions,
                    [key]: {
                      ...form.permissions[key],
                      permissions: {
                        ...form.permissions[key].permissions,
                        [k]: {
                          ...form.permissions[key].permissions[k],
                          value: !form.permissions[key].permissions[k].value
                        }
                      }
                    }
                  })
                }/>
            </div>
          ))
        }
      </div>
    ))}
  </form>
)

Form.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    permissions: PropTypes.object.isRequired
  }).isRequired,
  apps: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired
}

export default Form
