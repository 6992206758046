import {
  UPDATE_ACCOUNT,
  LOGIN_PENDING,
  LOGIN_FAILED,
  LOGIN_FULFILLED,
  VALIDATE_USER_EMAIL_FAILED,
  VALIDATE_USER_EMAIL_PENDING,
  VALIDATE_USER_EMAIL_FULFILLED,
  SIGNUP_FAILED,
  SIGNUP_PENDING,
  SEND_RESET_LINK_PENDING,
  SEND_RESET_LINK_FULFILLED,
  SEND_RESET_LINK_FAILED,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_FAILED,
  GET_INVITE_PENDING,
  GET_INVITE_FAILED,
  GET_INVITE_FULFILLED,
  ACCEPT_INVITE_PENDING,
  ACCEPT_INVITE_FAILED,
  ACCEPT_INVITE_FULFILLED,
  SUBMIT_MFA_LOGIN_PENDING,
  SUBMIT_MFA_LOGIN_FULFILLED,
  SUBMIT_MFA_LOGIN_FAILED,
  SET_ACCOUNT_STATUS,
  EXTERNAL_LOGIN,
  EXTERNAL_LOGIN_FULFILLED
} from "@actions/account"
import { GET_USER_LOCATION_FULFILLED } from "@actions/utilities"

const initialState = {
  status: "READY",
  full_name: "",
  email: "",
  password: "",
  confirm_password: "",
  country_code: "",
  validation_errors: [],
  error_message: "",
  invite_status: "PENDING",
  google2fa_code: "",
  festival: ""
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        ...action.payload
      }

    case ACCEPT_INVITE_PENDING:
    case RESET_PASSWORD_PENDING:
    case SEND_RESET_LINK_PENDING:
    case SIGNUP_PENDING:
    case LOGIN_PENDING:
    case SUBMIT_MFA_LOGIN_PENDING:
    case VALIDATE_USER_EMAIL_PENDING:
      return {
        ...state,
        error_message: "",
        status: "PENDING"
      }

    case ACCEPT_INVITE_FAILED:
    case RESET_PASSWORD_FAILED:
    case SEND_RESET_LINK_FAILED:
    case SIGNUP_FAILED:
    case SUBMIT_MFA_LOGIN_FAILED:
    case LOGIN_FAILED:
    case VALIDATE_USER_EMAIL_FAILED:
      return {
        ...state,
        status: "READY",
        error_message: action.payload.error || action.payload.message || "An error occurred"
      }

    case ACCEPT_INVITE_FULFILLED:
    case RESET_PASSWORD_FULFILLED:
    case SEND_RESET_LINK_FULFILLED:
    case SUBMIT_MFA_LOGIN_FULFILLED:
    case VALIDATE_USER_EMAIL_FULFILLED:
    case LOGIN_FULFILLED:
      return {
        ...state,
        status: "READY"
      }

    case GET_USER_LOCATION_FULFILLED:
      return {
        ...state,
        country_code: action.payload.country_code
      }

    case GET_INVITE_PENDING:
      return {
        ...state,
        invite_status: "PENDING"
      }

    case GET_INVITE_FAILED:
      return {
        ...state,
        invite_status: "ERROR"
      }

    case GET_INVITE_FULFILLED:
      return {
        ...state,
        invite_status: "READY",
        ...action.payload
      }

    case SET_ACCOUNT_STATUS:
      return {
        ...state,
        status: action.payload.status
      }
    case EXTERNAL_LOGIN:
    case EXTERNAL_LOGIN_FULFILLED:
      return {
        ...state,
        status: "READY"
      }
    default:
      return state
  }
}
