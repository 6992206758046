export const VALIDATE_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const VALIDATE_PASSWORD = new RegExp(
  [
    `^`, // match the start of the string
    `(`, // first capture group
    `(?=.*[\\d]+.*)`, // must contain a digit
    `|`, // or...
    `(?=.*[\\W]+.*)`, // must contain a special char
    `)`, // end first capture group
    `(?=.*[\\w]+.*)`, // must contain letters
    `[\\d\\w~!@#$%^&*,.?]`, // match any digit, word or special character
    `{8,}`, // match 8 or more of the preceding token
    `$` // match the end of the string
  ].join("")
)
