import React from "react"
import Select from "@src/components/select/"
import PropTypes from "prop-types"

class WebSafeFontPicker extends React.Component {
  constructor() {
    super()
    this.state = {
      items: [
        "Arial",
        "Arial Black",
        "Roboto",
        "Bookman",
        "Candara",
        "Century Gothic",
        "Comic Sans MS",
        "Courier",
        "Courier New",
        "Garamond",
        "Geneva",
        "Georgia",
        "Impact",
        "Lucida",
        "Lucida Sans",
        "Lucida Grande",
        "Palatino",
        "Tahoma",
        "Times",
        "Times New Roman",
        "Trebuchet MS",
        "Verdana"
      ].map(opt => ({ value: opt, label: opt }))
    }
  }
  render() {
    const { items } = this.state
    const { selected, onChange } = this.props
    const value = selected
      ? {
        value: selected,
        label: selected
      }
      : null
    return (
      <Select
        options={[
          {
            value: null,
            label: "-- None --"
          },
          ...items
        ]}
        value={value}
        className='col-12 mr2'
        onChange={option => {
          onChange(option.value)
        }}/>
    )
  }
}

WebSafeFontPicker.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default WebSafeFontPicker
