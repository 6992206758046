import {
  GET_EMAIL_NOTIFICATIONS,
  UPDATE_EMAIL_NOTIFICATION,
  SAVE_EMAIL_NOTIFICATION
} from "@actions/settings/notifications"
import { get, put } from "@src/helpers/fetch"

/**
 * Fetches user notifications from the server
 *
 * @returns {Promise}
 */

export const getEmailNotifications = () => (dispatch, getState) =>
  dispatch(get(GET_EMAIL_NOTIFICATIONS, `users/${getState().user._id}/settings/email_notifications`))

/**
 * Updates a user email notification in state.settings.notifications
 *
 * @param {Object} [payload]
 *
 * @returns {Promise}
 */

export const updateEmailNotification = payload => dispatch =>
  dispatch({
    type: UPDATE_EMAIL_NOTIFICATION,
    payload
  })

/**
 * Saves a user email notification to the server
 *
 * @param {Object} [value]
 *
 * @returns {Promise}
 */

export const saveEmailNotification = value => (dispatch, getState) =>
  dispatch(put(SAVE_EMAIL_NOTIFICATION, `users/${getState().user._id}/settings/email_notifications`, { value }))
