import { LOG_OUT, AUTH_CHECK, GET_LOGIN_TYPE, GET_LOGIN_TYPE_FULFILLED } from "@actions/auth"
import { post, get } from "@src/helpers/fetch"

export const authCheck = () => dispatch =>
  dispatch(
    get(
      AUTH_CHECK,
      `auth/check`
    )
  )

export const logOut = () => dispatch => dispatch(post(LOG_OUT, `auth/logout`, null, null))

export const getLoginType = () => dispatch => {
  dispatch(
    get(
      GET_LOGIN_TYPE,
      'auth/login-type',
      res => {
        dispatch({ type: GET_LOGIN_TYPE_FULFILLED, payload: res })
      }
    )
  );
};