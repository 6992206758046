import {
  GET_ASPERA_DOWNLOADS_PENDING,
  GET_ASPERA_DOWNLOADS_FAILED,
  GET_ASPERA_DOWNLOADS_FULFILLED
} from "@actions/settings/aspera_downloads"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_ASPERA_DOWNLOADS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_ASPERA_DOWNLOADS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_ASPERA_DOWNLOADS_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.list,
        currentPage: parseInt(action.payload.current_page),
        totalResults: parseInt(action.payload.total_results)
      }

    default:
      return state
  }
}
