import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import { updateUser, saveUser } from "@api/user"
import Select from "@src/components/select/"
import PERSONAL_FIELDS from "./personal_fields"

const PersonalSettings = ({ user, updateUser, saveUser, ...rest }) => (
  <div data-test-id='personal-profile'>
    <Helmet title='CineSend | Profile'/>
    {PERSONAL_FIELDS.map((field, index) => (
      <div key={index} className='box-shadow rounded mb2 p2 bg-white max-width-3'>
        <h4>{field.name}</h4>
        <div className='flex items-start flex-wrap'>
          {field.inputs.map(input => (
            <div className={`${input.className} mt2`} key={input.key}>
              <label className='block col-12 cs-label' htmlFor={input.key}>
                {input.label}
              </label>
              {input.type === "input" ? (
                <input
                  id={input.key}
                  data-test-id={"personal-profile-input-" + input.key}
                  value={user[input.key] || ""}
                  className='cs-input block col-12'
                  disabled={input.disabled}
                  onChange={e => updateUser({ [input.key]: e.target.value })}
                  onBlur={e => saveUser({ [input.key]: e.target.value })}/>
              ) : input.type === "textarea" ? (
                <textarea
                  id={input.key}
                  data-test-id={"personal-profile-textarea-" + input.key}
                  value={user[input.key] || ""}
                  className='cs-textarea block col-12'
                  disabled={input.disabled}
                  onChange={e => updateUser({ [input.key]: e.target.value })}
                  onBlur={e => saveUser({ [input.key]: e.target.value })}/>
              ) : (
                <Select
                  options={rest[input.options]}
                  value={rest[input.options].find(obj => obj.value === user[input.key])}
                  className='col-12'
                  isLoading={!rest[input.options] || !rest[input.options].length}
                  onChange={event => {
                    updateUser({ [input.key]: event.value })
                    saveUser({ [input.key]: event.value })
                  }}
                  clearable={false}/>
              )}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

PersonalSettings.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    imdb: PropTypes.string,
    about: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  saveUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  countries: state.utilities.countries.list
})

const mapDispatchToProps = dispatch => ({
  updateUser: bindActionCreators(updateUser, dispatch),
  saveUser: bindActionCreators(saveUser, dispatch)
  // getCountries: bindActionCreators(getCountries, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalSettings)
