// ToDo: Move this to cs-theme
import React from "react"
import Select from "react-select"

const styles = {
  valueContainer: provided => ({
    ...provided,
    height: 38,
    position: "static"
  })
}

const theme = theme => ({
  ...theme,
  borderRadius: "3px",
  primary: "red",
  colors: {
    ...theme.colors,
    primary: "#fb0f3b",
    primary75: "#f98a9e",
    primary50: "#f98a9e",
    primary25: "#f98a9e",
    neutral20: "#92999F"
  }
})

const SelectWrapper = props => <Select {...props} styles={styles} theme={theme}/>

export default SelectWrapper
