import {
  GET_ORGANIZATION_PENDING,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_FULFILLED,
  UPDATE_ORGANIZATION,
  ACTIVATE_APPLICATION_PENDING,
  ACTIVATE_APPLICATION_FAILED,
  ACTIVATE_APPLICATION_FULFILLED
} from "@actions/settings/organization"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_ORGANIZATION_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_ORGANIZATION_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_ORGANIZATION_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        ...action.payload
      }

    case ACTIVATE_APPLICATION_PENDING:
      return {
        ...state,
        activation_status: "PENDING"
      }

    case ACTIVATE_APPLICATION_FULFILLED:
    case ACTIVATE_APPLICATION_FAILED:
      return {
        ...state,
        activation_status: "READY"
      }

    default:
      return state
  }
}
