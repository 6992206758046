import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, withRouter } from "react-router-dom"
import { GLOBALS } from "@src/config"
import RedirectWithStatus from "./redirect_with_status"

const PrivateRoute = ({ component: Component, authenticated, location, ...rest }) => {
  const params = location.search ? location.search + "&" : "?"
  const redirect_pathname = (location.pathname || "").replace("index.html", "")
  const search = `${params}redirect=${encodeURIComponent(GLOBALS.URL + redirect_pathname)}`
  const pathname = "/login"
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? <Component {...props}/> : <RedirectWithStatus status={401} to={{ pathname, search }}/>
      }/>
  )
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  authenticated: state.auth.status === "AUTHENTICATED"
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PrivateRoute)
)
