import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import { Redirect } from "react-router-dom"
import { updateAccount, mfa } from "@api/account"

const MFA = ({ account, updateAccount, mfa }) => {
  const unset = !account.email
  return (
    <>
      {unset && <Redirect to='/login'/>}
      <Helmet title='CineSend | Login'/>
      <form>
        <p>Please enter the code found in your Google Authenticator app.</p>
        <label className='cs-label' htmlFor={`__input-mfa`}>2FA Code</label>
        <input
          autoFocus={true}
          value={account.google2fa_code}
          data-test-id={`account-mfa-input`}
          className={`cs-input mb2 col-12`}
          placeholder='Enter your 2FA code here.'
          type={'text'}
          id={`__input-email`}
          onChange={event => updateAccount({ google2fa_code: event.target.value })}/>
        {account.validation_errors.includes('google2fa_code') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-google2fa-code-error-text`}
            className='block red'>
            Invalid code.
          </small>
        )}
        {account.error_message && (
          <small data-test-id='account-error-text' className='red block mt1 mb2'>
            {account.error_message}
          </small>
        )}
        <button
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={mfa}
          disabled={account.status === "PENDING" || !account.email}
          className={`cs-button ${account.status.toLowerCase()} col-12`}>
          Submit Code
        </button>
      </form>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  mfa: bindActionCreators(mfa, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MFA)