import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import { Status } from '@bitcine/cs-theme'
import Subscription from "./components/subscription"
import PaymentMethods from "./components/payment_methods"
import UpcomingInvoice from './components/upcoming_invoice'
import Invoices from "./components/invoices"

const Billing = ({ updating, showSubscription, showUpcomingInvoice }) => (
  <div data-test-id='billing'>
    <Helmet title='CineSend | Billing'/>
    <Status pending={updating}>
      {showSubscription && <Subscription/>}
      <PaymentMethods/>
      {showUpcomingInvoice && <UpcomingInvoice/>}
      <Invoices/>
    </Status>
  </div>
)

Billing.propTypes = {
  showSubscription: PropTypes.bool,
  showUpcomingInvoice: PropTypes.bool,
  updating: PropTypes.bool
}

const mapStateToProps = state => ({
  updating: state.settings.billing.status === 'UPDATING',
  showUpcomingInvoice: state.user.subscription.stripe_plan_id
    || (state.user.organization && !state.user.organization.is_enterprise),
  showSubscription: !state.user.organization || (state.user.organization && !state.user.organization.is_enterprise)
    || (state.user.organization.plan && state.user.organization.plan.can_access_files !== false)
})

export default connect(
  mapStateToProps,
)(Billing)
