import React from "react"
import Select from "@src/components/select/"
import PropTypes from "prop-types"

class GoogleFontPicker extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      items: [],
      error: false
    }
  }
  componentDidMount() {
    this.setState({ error: false, items: [], loading: true })
    fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBEgZ-_MB8_pKap6pRIx6ZgqS8SLSUfUsc")
      .then(res => res.json())
      .then(res => {
        this.setState({
          items: res.items.map(item => {
            return {
              value: item.family,
              label: item.family
            }
          }),
          loading: false,
          error: false
        })
      })
      .catch(() => {
        this.setState({ error: true })
      })
  }
  render() {
    const { items, loading, error } = this.state
    const { selected, onChange } = this.props
    const value = selected
      ? {
        value: selected,
        label: selected
      }
      : null
    return error ? (
      <span className='red'>Error loading Google Fonts. Please refresh the page and try again.</span>
    ) : (
      <Select
        options={[
          {
            value: null,
            label: "-- None --"
          },
          ...items
        ]}
        isLoading={loading}
        value={value}
        className='col-12 mr2'
        onChange={option => {
          onChange(option.value)
        }}/>
    )
  }
}

GoogleFontPicker.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default GoogleFontPicker
