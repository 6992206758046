import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewPassword, submitNewPassword } from "@api/settings/security"
import { INPUTS } from "@src/constants/new_password"
import { Status } from '@bitcine/cs-theme'

const Password = ({ password, updateNewPassword, submitNewPassword }) => (
  <form className='box-shadow rounded mr2 p2 bg-white col-12 max-width-2'>
    <h4>Change Password</h4>
    <Status pending={password.status === 'PENDING'}>
      {INPUTS.map(input => (
        <div className='mt2 max-width-2' key={input.key}>
          <label className='cs-label' htmlFor={input.key}>{input.label}</label>
          <input
            id={input.key}
            type={input.type}
            value={password[input.key]}
            className={"cs-input col-12 " + (password.validation_errors.includes(input.key) ? "error" : "")}
            onChange={e => updateNewPassword({ [input.key]: e.target.value })}/>
          {password.validation_errors.includes(input.key) && <small className='red'>{input.error_message}</small>}
          {input.key === "new" && (
            <ul className='block muted pl2 mt1'>
              <li>Must contain at least 8 characters</li>
              <li>Must contain at least 1 number or special character</li>
            </ul>
          )}
        </div>
      ))}
      {password.error && <div className='red mt2'>{password.error}</div>}
      <div className='right-align mt2'>
        <button
          disabled={password.status === "PENDING"}
          className="cs-button small"
          onClick={e => {
            e.preventDefault()
            submitNewPassword()
          }}>
          Change Password
        </button>
      </div>
    </Status>
  </form>
)

Password.propTypes = {
  password: PropTypes.shape({
    new: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    validation_errors: PropTypes.array.isRequired,
    error: PropTypes.string
  }).isRequired,
  updateNewPassword: PropTypes.func.isRequired,
  submitNewPassword: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  password: state.settings.security.new_password
})

const mapDispatchToProps = dispatch => ({
  updateNewPassword: bindActionCreators(updateNewPassword, dispatch),
  submitNewPassword: bindActionCreators(submitNewPassword, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Password)
