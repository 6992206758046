const INITIAL_STATE = {
  new_password: {
    current: "",
    new: "",
    confirm: "",
    status: "READY",
    validation_errors: [],
    error: ""
  },
  mfa: {
    enabling: false,
    qr_code: null,
    status: "READY",
    code_status: "READY",
    disabling_status: "READY",
    error: "",
    code: ""
  }
}

export default INITIAL_STATE
