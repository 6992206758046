import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import dayjs from "dayjs"
import { getInvoices } from "@api/settings/invoices"
import { Status, ButtonDropdown, Table } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/invoices.empty.inline.svg"
import { GLOBALS } from '@src/config'
import { useLocation } from 'react-router-dom'

const Invoices = ({ getInvoices, invoices }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const invoiceID = searchParams.get('invoiceID')
  const baseInvoicePath = GLOBALS.API_URL + '/settings/invoices/'

  useDidMount(() => {
    if (invoiceID) window.location.replace(baseInvoicePath + invoiceID)
    getInvoices()
  })

  return (
    <div className='p2 bg-white rounded box-shadow'>
      <div className="flex items-center justify-between">
        <h4>Invoices</h4>
      </div>
      <Status pending={invoices.status === "PENDING"} error={invoices.status === "ERROR"}>
        <Table
          widths={["auto", 140, 120, 65]}
          className='mt2'
          header={{
            columns: ["Invoice", "Date", "Amount", ""].map(key => ({ text: key }))
          }}
          body={{
            data: invoices.list,
            row: {
              compact: true,
              render: [
                data => data.description || "N/A",
                data => dayjs.utc(data.created_at).format("MMM Do, YYYY"),
                data => data.total_formatted,
                data => (
                  <ButtonDropdown
                    button={{
                      className: "link material-icons",
                      menu: true,
                      text: "more_vert"
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: "Download",
                          onClick: () =>
                            window.open(baseInvoicePath + data._id, "_blank"),
                          icon: "cloud_download"
                        }
                      ]
                    }}/>
                )
              ]
            },
            empty: {
              icon: <Icon/>,
              title: "No invoices",
              text: "After you create orders, the invoices will show here"
            }
          }}
          paginate={{
            currentPage: invoices.currentPage,
            pageCount: Math.ceil(invoices.totalResults / 10),
            onPageChange: skip => getInvoices(skip)
          }}/>
      </Status>
    </div>
  )
}

Invoices.propTypes = {
  invoices: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    totalResults: PropTypes.number
  }).isRequired,
  getInvoices: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  invoices: state.settings.invoices
})

const mapDispatchToProps = dispatch => ({
  getInvoices: bindActionCreators(getInvoices, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices)
