import React from "react"
import PropTypes from "prop-types"
import visa from "@src/media/payment/visa-card-background.png"
import mastercard from "@src/media/payment/mastercard-card-background.png"
import amex from "@src/media/payment/amex-card-background.png"

const BG = {
  visa: `url(${visa})`,
  mastercard: `url(${mastercard})`,
  american_express: `url(${amex})`,
  "gift-card": "linear-gradient(138.33deg, #2E2658 0%, #120E28 100%)"
}

const Background = ({ brand, children }) => (
  <div
    style={{
      backgroundImage: `${BG[brand]}`,
      top: '0.5em',
      bottom: '0.5em',
      right: '0.5em',
      left: '0.5em',
      borderRadius: `${brand === "gift-card" ? "5px" : "3px"}`
    }}
    className='absolute box-shadow flex flex-column items-stretch bg-cover'>
    {children}
  </div>
)

Background.propTypes = {
  brand: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
}

export default Background
