/**
 * Constants
 */
export const MANAGE_CREDIT_CARDS = "billing_and_plans:can_manage_credit_cards"
export const MODIFY_PLAN = "billing_and_plans:can_modify_plan"
export const VIEW_INVOICES = "billing_and_plans:can_view_invoices"
export const MODIFY_SETTINGS = "organization_settings:can_modify"
export const VIEW_SETTINGS = "organization_settings:can_view"
export const MANAGE_SCREENERS = "screeners:can_manage"
export const VIEW_USERS_AND_ROLES = "users_and_roles:can_view"
export const MODIFY_USERS_AND_ROLES = "users_and_roles:can_modify"

/**
 * Check if a user can access a resource
 *
 * @param  Object  user     The authenticated user object (Must container the permissions object)
 * @param  Array   actions  An array of permissions to check against user. Each action must be true
 *                          For the function to return true.
 *
 * Usage example:
 *        import { can, VIEW_PROJECTS, MODIFY_PROJECTS } from '@src/helpers/authorization'
 *
 *        if(can(user, [VIEW_PROJECTS, MODIFY_PROJECTS])) {
 *            viewProject();
 *            editProject();
 *        }
 *
 * @return bool
 */
export const can = (user, actions = []) => {
  if (!user.organization_id) {
    // Permissions are only relavent to Organizations.
    // Let's return true regardless of actions if no organization is present.
    return true
  }

  if (!actions.length) {
    return true
  }

  return actions.filter(action => user.permissions[action]).length === actions.length
}
