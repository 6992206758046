import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import humanFileSize from "@src/helpers/humanFileSize"
import { Modal } from "@bitcine/cs-theme"
import { togglePurchaseModal } from "@api/settings/aspera_entitlements"
import Downloads from "./components/downloads"
import Entitlements from "./components/entitlements"
import Purchase from "./components/purchase"

const AsperaDownloads = ({ remaining, showPurchaseModal, togglePurchaseModal, isSubmitting }) => (
  <div data-test-id='plan'>
    <Helmet title='CineSend | Aspera Entitlements'/>
    <div className='p2 bg-white rounded box-shadow mb2'>
      <div className='flex items-center justify-between'>
        <h4>Aspera Usage</h4>
        <button className='cs-button' onClick={togglePurchaseModal}>
          Purchase Aspera Entitlements
        </button>
      </div>
      <p>
        You currently have <strong>{remaining ? humanFileSize(remaining, 2) : "no"}</strong> Aspera download bandwidth
        remaining.
      </p>
    </div>
    <div className='mt2 p2 bg-white rounded box-shadow mb2'>
      <Entitlements/>
    </div>
    <div className='mt2 p2 bg-white rounded box-shadow mb2'>
      <Downloads/>
    </div>
    <Modal
      open={showPurchaseModal}
      onClose={togglePurchaseModal}
      isDismissable={!isSubmitting}
      title='Purchase Aspera Entitlements'>
      <Purchase/>
    </Modal>
  </div>
)

AsperaDownloads.propTypes = {
  remaining: PropTypes.number.isRequired,
  showPurchaseModal: PropTypes.bool.isRequired,
  togglePurchaseModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  remaining: state.user.organization.aspera_entitlement_bytes_remaining || 0,
  showPurchaseModal: state.settings.aspera_entitlements.showPurchaseModal,
  isSubmitting: state.settings.aspera_entitlements.isSubmitting
})

const mapDispatchToProps = dispatch => ({
  togglePurchaseModal: bindActionCreators(togglePurchaseModal, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsperaDownloads)
