import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import auth from "./auth"
import utilities from "./utilities"
import account from "./account"
import user from "./user"
import messages from "./global_messages"
import settings from "./settings"
import prices from "./prices"

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  utilities,
  account,
  user,
  global_messages: messages,
  settings,
  prices
})

export default createRootReducer
