import {
  UPDATE_NEW_PASSWORD,
  POST_UPDATE_USER_PASSWORD_PENDING,
  POST_UPDATE_USER_PASSWORD_FAILED,
  POST_UPDATE_USER_PASSWORD_FULFILLED,
  UPDATE_MFA,
  GET_MFA_QR_CODE_PENDING,
  GET_MFA_QR_CODE_FAILED,
  GET_MFA_QR_CODE_FULFILLED,
  VALIDATE_ENABLE_MFA_QR_CODE_PENDING,
  VALIDATE_ENABLE_MFA_QR_CODE_FAILED,
  VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED,
  DISABLE_MFA_PENDING,
  DISABLE_MFA_FAILED,
  DISABLE_MFA_FULFILLED
} from "@actions/settings/security"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_NEW_PASSWORD:
      return {
        ...state,
        new_password: {
          ...state.new_password,
          ...action.payload
        }
      }

    case POST_UPDATE_USER_PASSWORD_PENDING:
      return {
        ...state,
        new_password: {
          ...state.new_password,
          status: "PENDING",
          error: ""
        }
      }

    case POST_UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        new_password: {
          ...state.new_password,
          status: "READY",
          error: action.error.message
        }
      }

    case POST_UPDATE_USER_PASSWORD_FULFILLED:
      return {
        ...state,
        new_password: INITIAL_STATE.new_password
      }

    case UPDATE_MFA:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...action.payload
        }
      }

    case GET_MFA_QR_CODE_PENDING:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          code_status: "PENDING"
        }
      }

    case GET_MFA_QR_CODE_FAILED:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          code_status: "ERROR"
        }
      }

    case GET_MFA_QR_CODE_FULFILLED:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          code_status: "READY",
          qr_code: action.payload.mfa_qr_code
        }
      }

    case VALIDATE_ENABLE_MFA_QR_CODE_PENDING:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          status: "PENDING"
        }
      }

    case VALIDATE_ENABLE_MFA_QR_CODE_FAILED:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          status: "ERROR",
          error: action.error.message
        }
      }

    case VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED:
      return {
        ...state,
        mfa: INITIAL_STATE.mfa
      }

    case DISABLE_MFA_PENDING:
      return {
        ...state,
        mfa: {
          ...state,
          disabling_status: "PENDING"
        }
      }
    case DISABLE_MFA_FAILED:
      return {
        ...state,
        mfa: {
          ...state,
          disabling_status: "ERROR"
        }
      }

    case DISABLE_MFA_FULFILLED:
      return {
        ...state,
        mfa: INITIAL_STATE.mfa
      }

    default:
      return state
  }
}
