import {
  GET_EMAIL_NOTIFICATIONS_PENDING,
  GET_EMAIL_NOTIFICATIONS_FAILED,
  GET_EMAIL_NOTIFICATIONS_FULFILLED,
  UPDATE_EMAIL_NOTIFICATION
} from "@actions/settings/notifications"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_EMAIL_NOTIFICATIONS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_EMAIL_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload
      }

    case UPDATE_EMAIL_NOTIFICATION:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        }
      }

    default:
      return state
  }
}
