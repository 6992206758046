import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewPaymentMethod } from "@api/settings/billing/index"
import { ReactComponent as Amex } from "@src/media/payment/svgs/amex.inline.svg"
import { ReactComponent as Mastercard } from "@src/media/payment/svgs/mastercard.inline.svg"
import { ReactComponent as Visa } from "@src/media/payment/svgs/visa.inline.svg"

const UI = {
  "credit-card": {
    label: "Credit Card",
    icon: "credit_card",
    show_allowed_credit_cards: true
  },
  "gift-card": {
    label: "Gift Card",
    icon: "card_giftcard",
    show_allowed_credit_cards: false
  }
}

const Header = ({ mode, updateNewPaymentMethod, allow }) => (
  <div className='border-bottom border-gray-5 pb2 mb2'>
    <div className='flex-auto flex items-center'>
      {["credit-card", "gift-card"]
        .filter(key => allow.includes(key))
        .map(key => (
          <button
            key={key}
            onClick={() => updateNewPaymentMethod({ mode: key })}
            style={{
              height: '100px',
              width: '115px',
              boxShadow: `${mode === key ? "inset 0 1px 3px 0 rgba(0,0,0,.5)" : "none"}`,
              border: `1px solid ${mode === key ? "" : "#e0e3e4"}`
            }}
            className='flex items-center justify-center mr2 link'>
            <div className='center'>
              <span className='material-icons'>{UI[key].icon}</span>
              <div className='regular'>{UI[key].label}</div>
            </div>
          </button>
        ))}
    </div>
    {UI[mode].show_allowed_credit_cards && (
      <div className='bg-gray-6 border border-gray-5 rounded p1 mt2 flex items-center justify-between'>
        Accepted Cards
        <div className='flex items-center justify-end'>
          <Visa alt='Visa'/>
          <Mastercard className='ml1' alt='Mastercard'/>
          <Amex className='ml1' alt='American Express'/>
        </div>
      </div>
    )}
  </div>
)

Header.propTypes = {
  mode: PropTypes.oneOf(["credit-card", "gift-card"]).isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  allow: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  mode: state.settings.billing.new.mode
})

const mapDispatchToProps = dispatch => ({
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
