import {
  UPDATE_TEAM_ROLES,
  GET_TEAM_ROLES_PENDING,
  GET_TEAM_ROLES_FAILED,
  GET_TEAM_ROLES_FULFILLED,
  CREATE_ROLE_PENDING,
  CREATE_ROLE_FAILED,
  CREATE_ROLE_FULFILLED,
  DELETE_ROLE_FULFILLED,
  OPEN_EDIT_ROLE,
  CLOSE_EDIT_ROLE,
  UPDATE_EDIT_ROLE,
  SAVE_ROLE_PENDING,
  SAVE_ROLE_FAILED,
  SAVE_ROLE_FULFILLED
} from "@actions/settings/roles"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_TEAM_ROLES:
      return {
        ...state,
        ...action.payload
      }

    case GET_TEAM_ROLES_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_TEAM_ROLES_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_TEAM_ROLES_FULFILLED:
      return {
        ...state,
        list: action.payload,
        status: "READY"
      }

    case CREATE_ROLE_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case CREATE_ROLE_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: "ERROR"
        }
      }
    case CREATE_ROLE_FULFILLED:
      return {
        ...state,
        status: "READY",
        edit: {
          ...state.edit,
          show: true,
          status: "READY",
          role: action.payload.new_role
        },
        list: action.payload.roles
      }

    case DELETE_ROLE_FULFILLED:
      return {
        ...state,
        list: action.payload
      }

    case OPEN_EDIT_ROLE:
      return {
        ...state,
        edit: {
          ...state.edit,
          show: true,
          role: action.role
        }
      }

    case CLOSE_EDIT_ROLE:
      return {
        ...state,
        edit: INITIAL_STATE.edit
      }

    case UPDATE_EDIT_ROLE:
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload
        }
      }

    case SAVE_ROLE_PENDING:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: "PENDING"
        }
      }

    case SAVE_ROLE_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          status: "ERROR"
        }
      }

    case SAVE_ROLE_FULFILLED:
      return {
        ...state,
        edit: INITIAL_STATE.edit,
        list: action.payload
      }

    default:
      return state
  }
}
