import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { resetNewPaymentMethod } from "@api/settings/billing/index"
import { Modal, Status } from "@bitcine/cs-theme"
import Header from "./header"
import Form from "./form"
import Footer from "./footer"
import Errors from "./errors"

const Add = ({ resetNewPaymentMethod, status, allow }) => (
  <Modal open={true} onClose={resetNewPaymentMethod} title='Add Payment Method'>
    <Status pending={status === "PENDING"}>
      <Header allow={allow}/>
      <Form/>
      <Errors/>
      <Footer/>
    </Status>
  </Modal>
)

Add.propTypes = {
  resetNewPaymentMethod: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  allow: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  status: state.settings.billing.new.status
})

const mapDispatchToProps = dispatch => ({
  resetNewPaymentMethod: bindActionCreators(resetNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Add)
