import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

const LinkWrapper = styled.li`
  width: 100%;
  @media (min-width: 40em) {
    width: 50%;
  }
  @media (min-width: 64em) {
    width: 33%;
  }
  @media (min-width: 80em) {
    width: 25%;
  }
`

const Wrap = ({ isDeleting, children }) => (
  <LinkWrapper>
    <div
      style={{
        opacity: isDeleting ? "0.4" : "1",
        transition: 'opacity 0.2s linear'
      }}
      className='col-12 relative'>
      <div
        style={{
          paddingTop: '56.25%'
        }}>
        {children}
      </div>
    </div>
  </LinkWrapper>
)

Wrap.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired
}

export default Wrap
