import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateAccount, resetPassword } from "@api/account"

const Reset = ({ account, updateAccount, resetPassword }) => {
  return (
    <form>
      <label className='cs-label' htmlFor={`__input-email`}>Email</label>
      <input
        value={account.email}
        data-test-id={`account-email-input`}
        className={`cs-input col-12 ${account.validation_errors.includes('email') ? "error" : ""}`}
        type={'text'}
        id={`__input-email`}
        onChange={event => updateAccount({ email: event.target.value })}/>
      {account.validation_errors.includes('email') && (
        <small
          style={{
            marginTop: '0.3em'
          }}
          data-test-id={`account-email-error-text`}
          className='block red'>
          Valid email is required.
        </small>
      )}
      <label className='cs-label mt2'  htmlFor={`__input-password`}>New Password</label>
      <input
        value={account.password}
        data-test-id={`account-password-input`}
        className={`cs-input col-12 ${account.validation_errors.includes('password') ? "error" : ""}`}
        type={'password'}
        id={`__input-password`}
        onChange={event => updateAccount({ password: event.target.value })}/>
      {account.validation_errors.includes('password') && (
        <small
          style={{
            marginTop: '0.3em'
          }}
          data-test-id={`account-password-error-text`}
          className='block red'>
          Password is required.
        </small>
      )}
      {account.error_message && (
        <small data-test-id='account-error-text' className='red block mt1 mb2'>
          {account.error_message}
        </small>
      )}
      <button
        type='submit'
        tabIndex='1'
        data-test-id='account-submit-btn'
        onClick={resetPassword}
        disabled={account.status === "PENDING" || !account.email || !account.password}
        className={`cs-button ${account.status.toLowerCase()} col-12 mt2`}>
        Reset Password
      </button>
    </form>
  )
}

const mapStateToProps = state => ({
  account: state.account
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  resetPassword: bindActionCreators(resetPassword, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Reset)