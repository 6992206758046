import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateOrganization, saveOrganization } from "@api/settings/organization"
import { withRouter, NavLink } from "react-router-dom"

const authFields = [
  { label: 'Authorize Endpoint', key: 'openid_connect_authorize_endpoint' },
  { label: 'Token Endpoint', key: 'openid_connect_token_endpoint' },
  { label: 'User Info Endpoint', key: 'openid_connect_userinfo_endpoint' },
  { label: 'Client ID', key: 'openid_connect_client_id' },
  { label: 'Client Secret', key: 'openid_connect_client_secret', hidden: true },
  { label: 'Header Text', key: 'openid_connect_header_text' },
  { label: 'Button Text', key: 'openid_connect_button_text' }
]

const ExternalAuth = ({ organization, updateOrganization, saveOrganization }) => {
  return (
    <div className='box-shadow rounded p2 bg-white mt2'>
      <div className="flex justify-between">
        <h4>OpenID Connect</h4>
      </div>
        <div className='flex-auto'>
          <p>This integration enables a connection between an OpenID Connect application and CineSend.</p>
          <div className="flex flex-wrap items-center">
            {authFields.map((item) => <div className="col-6 px1 mt2">
              <label className='capitalize cs-label' htmlFor={item.key}>
                {item.label}
              </label>
              <input
                id={item.key}
                type={item.hidden ? 'password' : ''}
                value={organization[item.key]}
                className={"cs-input col-12 block "}
                onChange={e => updateOrganization({ [item.key]: e.target.value })}
                onBlur={e => {
                  if (e.target.value) {
                    saveOrganization(item.key, e.target.value)
                  }
                }} />
            </div>)}
          </div>
        </div>
        <div className='mt2 p1'>
          <div>Note: when using external authentication, it is recommended to set a default role.</div>
          <NavLink
            to={{
              pathname: `/settings/roles`
            }}>
            <button className='cs-button'>Set a default role</button>
          </NavLink>
        </div>
    </div>)
}

ExternalAuth.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  updateOrganization: PropTypes.func.isRequired,
  saveOrganization: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.settings.organization
})

const mapDispatchToProps = dispatch => ({
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch)
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAuth))
