import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateOrganization, saveOrganization } from "@api/settings/organization"

const Links = ({ organization, updateOrganization, saveOrganization }) => (
  <div className='box-shadow rounded p2 bg-white mt2'>
    <h4>Links</h4>
    <div
      style={{
        margin: '0 -0.5em'
      }}
      className='flex flex-wrap items-center'>
      {["facebook_url", "twitter_url", "instagram_url", "website_url"].map(key => (
        <div key={key} className='mt2 px1 col-6'>
          <label className='capitalize cs-label' htmlFor={key}>
            {key
              .split("_")
              .join(" ")
              .replace("url", "URL")}
          </label>
          <input
            value={organization[key] || ""}
            onChange={e => updateOrganization({ [key]: e.target.value })}
            onBlur={e => saveOrganization(key, e.target.value)}
            className='cs-input col-12 block'/>
        </div>
      ))}
    </div>
  </div>
)

Links.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  updateOrganization: PropTypes.func.isRequired,
  saveOrganization: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.settings.organization
})

const mapDispatchToProps = dispatch => ({
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Links)
