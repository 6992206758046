import React from 'react'
import { Modal, Table } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/invoices.empty.inline.svg"
import dayjs from "dayjs"
import humanFileSize from "@src/helpers/humanFileSize"

const DownloadUsage = ({ asperaEntitlement, asperaDownloads, asperaDownloadsUsed, onClose }) =>
  <Modal onClose={onClose} open width={3} title='Aspera bandwidth usage'>
    <div className='block light small'>{asperaEntitlement._id}</div>
    <Table
      widths={["auto", 100, 100, 180, 180]}
      className='mt2'
      header={{
        columns: [{ text: "File Name" }, { text: "Consumed" }, { text: "File Size" }, { text: "Downloader" }, { text: "Last Updated" }]
      }}
      body={{
        data: asperaDownloads,
        row: {
          compact: true,
          render: [
            data => (
              <div className='truncate'>
                <div className='truncate'>{data.file_name}</div>
                <div className='block light small'>{data._id}</div>
              </div>
            ),
            data => humanFileSize(asperaDownloadsUsed.find(opt => opt.aspera_download_id === data._id).bytes_used),
            data => humanFileSize(data.total_size),
            data => (
              <div className='truncate'>
                {data.downloader}
                <span className='block light small'>
                  {data.location} - {data.connection}
                </span>
              </div>
            ),
            data =>
              dayjs
                .utc(data.updated_at)
                .local()
                .format("MMM D, YYYY h:mma")
          ]
        },
        empty: {
          icon: <Icon/>,
          title: "No downloads",
          text: "Once your content is downloaded via Aspera, activity will appear here."
        }
      }}/>
  </Modal>

export default DownloadUsage