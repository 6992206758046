import React from "react"
import { ReactComponent as Icon } from "@src/media/empty.inline.svg"
import { Empty } from "@bitcine/cs-theme"

const PageError = () => (
  <section data-test-id='500-section'>
    <Empty icon={<Icon/>} title='Error' text='An error occured loading this page.'/>
  </section>
)

export default PageError
