import React from "react"
import PropTypes from "prop-types"

const Number = ({ number, brand, isExpired, isFailed }) => (
  <strong
    style={{
      fontFamily: 'monospace',
      fontSize: '1.4em',
      opacity: (isExpired || isFailed) ? "0.25" : "1"
    }}
    className='flex-auto col-12 flex items-center justify-center white'>
    <span
      style={{
        paddingTop: '0.25em',
        paddingRight: brand === "american_express" ? "0" : ".33em"
      }}>
      {brand === "american_express" ? "**** ****** *" : "**** **** ****"}
    </span>
    {number}
  </strong>
)

Number.propTypes = {
  number: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired
}

export default Number
