import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { updateNewUser, validateNewUser } from "@api/settings/team"
import { getTeamSeatCyclePrice } from "@api/prices"
import Select from "@src/components/select/"

const ERRORS = {
  full_name: "A name is required",
  email: "A valid email is required",
  role_id: "A role is required"
}

const Add = ({ newUser, updateNewUser, validateNewUser, roles, getTeamSeatCyclePrice, price, isEnterprise, defaultRole }) => {
  useEffect(() => {
    if (!isEnterprise) {
      getTeamSeatCyclePrice()
    }
  }, [isEnterprise])

  return (
    <Modal open={true} title='Add Team Members' onClose={() => updateNewUser({ show: false })}>
      <Status pending={(!isEnterprise && !price.interval_amount) || newUser.status === 'PENDING'}>
        <form>
          {["full_name", "email"].map(key => (
            <div className='mb1' key={key}>
              <label htmlFor={key} className='capitalize col-12 block'>
                {key.split("_").join(" ")}
              </label>
              <input
                value={newUser[key]}
                onChange={e => {
                  let value = e.target.value
                  if (key === "email") value = value.toLowerCase().trim()
                  updateNewUser({ [key]: value })
                }}
                className={"cs-input block col-12 " + (newUser.errors.includes(key) ? "error" : "")}/>
              {newUser.errors.includes(key) && <div className='red'>{ERRORS[key]}</div>}
            </div>
          ))}
          <div className='mb1'>
            <label className='col-12 block' htmlFor='role_id'>
              Role
            </label>
            <Select
              options={roles}
              defaultValue={roles.find(({ _id }) => _id === defaultRole)}
              value={roles.find(({ _id }) => _id === newUser.role_id)}
              clearable={false}
              className={"block col-12 " + (newUser.errors.includes("role_id") ? "error" : "")}
              onChange={role => updateNewUser({ role_id: role.value })}/>
            {newUser.errors.includes("role_id") && <div className='red'>{ERRORS.role_id}</div>}
          </div>
          {!isEnterprise && price.prorated_amount_formatted && <div className={'bg-gray-5 rounded p2 my2'}>
            Approximate prorated amount being charged today for your current billing cycle: <b>{price.prorated_amount_formatted}</b>.
          </div>}
          {
            !isEnterprise && price.unit_amount_formatted &&
            <p className={'italic muted small'}>
              Additional team seats are billed at <strong>{price.unit_amount_formatted}</strong>.
            </p>
          }
          <div className='mt3 right-align'>
            <button
              disabled={newUser.status === "PENDING" || !newUser.email || !newUser.full_name || !newUser.role_id}
              className={`${newUser.status === "PENDING" ? "pending" : ""} cs-button`}
              onClick={e => {
                e.preventDefault()
                validateNewUser()
              }}>
              Invite new user{!isEnterprise && price.prorated_amount_formatted && <span> and pay {price.prorated_amount_formatted} now</span>}
            </button>
          </div>
        </form>
      </Status>
    </Modal>
  )
}

Add.propTypes = {
  newUser: PropTypes.shape({
    status: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired
  }).isRequired,
  updateNewUser: PropTypes.func.isRequired,
  validateNewUser: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  getTeamSeatCyclePrice: PropTypes.func.isRequired,
  price: PropTypes.object
}

const mapStateToProps = state => ({
  newUser: state.settings.team.new,
  roles: state.settings.roles.list.map(role => ({ _id: role._id, value: role._id, label: role.name })),
  price: state.prices.team_seat.price,
  isEnterprise: state.user.organization.is_enterprise,
  defaultRole: state.settings.organization.default_role
})

const mapDispatchToProps = dispatch => ({
  updateNewUser: bindActionCreators(updateNewUser, dispatch),
  validateNewUser: bindActionCreators(validateNewUser, dispatch),
  getTeamSeatCyclePrice: bindActionCreators(getTeamSeatCyclePrice, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Add)
