import { GET_ASPERA_DOWNLOADS } from "@actions/settings/aspera_downloads"
import { get } from "@src/helpers/fetch"

/**
 * Fetches user/organization aspera downloads from the server
 *
 * @returns {Promise}
 */

export const getAsperaDownloads = (page = 0) => dispatch =>
  dispatch(get(GET_ASPERA_DOWNLOADS, `organizations/settings/aspera-downloads?page=${page}`))
