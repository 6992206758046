import {
  UPDATE_NEW_PASSWORD,
  POST_UPDATE_USER_PASSWORD,
  UPDATE_MFA,
  GET_MFA_QR_CODE,
  VALIDATE_ENABLE_MFA_QR_CODE,
  DISABLE_MFA
} from "@actions/settings/security"
import { INPUTS } from "@src/constants/new_password"
import { put, get, post } from "@src/helpers/fetch"
import { addGlobalMessage } from "../global_messages"

export const updateNewPassword = payload => (dispatch, getState) => dispatch({ type: UPDATE_NEW_PASSWORD, payload })

export const submitNewPassword = () => (dispatch, getState) => {
  const obj = getState().settings.security.new_password
  const errors = INPUTS.filter(field => !field.validate(obj[field.key], obj)).map(({ key }) => key)
  dispatch(
    updateNewPassword({
      validation_errors: errors,
      status: errors.length ? "READY" : "PENDING"
    })
  )
  if (!errors.length) {
    dispatch(
      put(
        POST_UPDATE_USER_PASSWORD,
        `users/${getState().user._id}/password`,
        {
          new_password: obj.new,
          old_password: obj.current
        },
        () => dispatch(addGlobalMessage("Password updated!", "success"))
      )
    )
  }
}

export const updateMFA = payload => dispatch => dispatch({ type: UPDATE_MFA, payload })

export const getMfaQRCode = () => dispatch => dispatch(get(GET_MFA_QR_CODE, `auth/mfa/qr-code`))

export const validateEnableMfaQrCode = () => (dispatch, getState) => {
  const google2fa_code = getState().settings.security.mfa.code
  if (google2fa_code.length !== 6) {
    dispatch(updateMFA({ error: "Code must be 6 characters long" }))
  } else {
    dispatch(post(VALIDATE_ENABLE_MFA_QR_CODE, `auth/mfa/validate-enable`, { google2fa_code }))
  }
}

export const disableMfa = () => dispatch => {
  if (window.confirm("Are you sure you want to disable Two-Factor authentication?")) {
    dispatch(post(DISABLE_MFA, `auth/mfa/disable`))
  }
}
