import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link, withRouter } from "react-router-dom"
import queryString from "query-string"
import Helmet from "react-helmet"
import { updateAccount, getInvite, acceptInvite } from "@api/account"
import Select from "@src/components/select/"
import { ReactComponent as GoogleIcon } from "@src/media/icons/btn_google_light_normal_ios.svg"
import { GLOBALS } from "@src/config"

const Invitation = ({ account, updateAccount, getInvite, acceptInvite, countries, location }) => {
  const callbackQueries = `?${queryString.stringify(queryString.parse(window.location.search))}`
  const token = queryString.parse(window.location.search).token
  useEffect(() => {
    if (token) {
      getInvite(token)
    }
  }, [token])
  return (
    <>
      <Helmet title='CineSend | Accept Invitation'/>
      <form>
        <label className='cs-label' htmlFor={`__input-name`}>Name</label>
        <input
          autoFocus={true}
          value={account.name}
          data-test-id={`account-name-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('name') ? "error" : ""}`}
          type={'text'}
          id={`__input-name`}
          onChange={event => updateAccount({ name: event.target.value })}/>
        <label className='cs-label mt2' htmlFor={`__input-email`}>Email</label>
        <input
          autoFocus={true}
          value={account.email}
          data-test-id={`account-email-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('email') ? "error" : ""}`}
          type={'text'}
          disabled={!!token}
          id={`__input-email`}
          onChange={event => updateAccount({ email: event.target.value })}/>
        {account.validation_errors.includes('email') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-email-error-text`}
            className='block red'>
            Valid email is required.
          </small>
        )}
        <label className='cs-label mt2'  htmlFor={`__input-password`}>Password</label>
        <input
          value={account.password}
          data-test-id={`account-password-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('password') ? "error" : ""}`}
          type={'password'}
          id={`__input-password`}
          onChange={event => updateAccount({ password: event.target.value })}/>
        {account.validation_errors.includes('password') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-password-error-text`}
            className='block red'>
            Password is required.
          </small>
        )}
        <label className='cs-label mt2'  htmlFor={`__input-country`}>Country</label>
        <Select
          options={countries}
          value={countries.find(opt => opt.value === account.country_code)}
          className={`col-12 ${account.validation_errors.includes('country_code') ? "error" : ""}`}
          isLoading={!countries || !countries.length}
          onChange={event => updateAccount({ country_code: event.value })}
          clearable={false}/>
        <button
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={acceptInvite}
          disabled={account.status === "PENDING"}
          className={`cs-button ${account.status.toLowerCase()} col-12 mt2`}>
          Accept invite
        </button>
      </form>
      <div className='mt3 py1 flex justify-center'>
        Or, connect your Google Account:
      </div>
      <a href={GLOBALS.SOCIAL_AUTH_URL + callbackQueries}>
        <button
          className='cs-button white col-12 flex items-center p0'
          tabIndex='2'
          onClick={e => {
            e.preventDefault()
            window.location = GLOBALS.SOCIAL_AUTH_URL + callbackQueries
          }}>
          <GoogleIcon style={{ height: '38px' }}className='absolute'/>
          <div className='flex flex-auto justify-center'>
            Sign in with Google
          </div>
        </button>
      </a>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  countries: state.utilities.countries.list,
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  acceptInvite: bindActionCreators(acceptInvite, dispatch),
  getInvite: bindActionCreators(getInvite, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invitation))