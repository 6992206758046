import React from "react"
import Helmet from "react-helmet"
import MFA from "./mfa"
import Password from "./password"

const Security = () => (
  <div>
    <Helmet title='CineSend | Security'/>
    <div className='flex items-start'>
      <Password/>
      <MFA/>
    </div>
  </div>
)

Security.propTypes = {}

export default Security
