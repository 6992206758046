import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Logo } from "@src/media/logos/cinesend/red.inline.svg"

const GiftCard = ({ card }) => (
  <React.Fragment>
    <strong
      style={{
        fontFamily: 'monospace',
        fontSize: '1.4em'
      }}
      className='flex-auto col-12 flex items-center justify-center white'>
      {card.code}
    </strong>
    <div className='px1 flex items-end white'>
      <div
        className='pr2'
        style={{
          paddingBottom: '0.2em'
        }}>
        <small className='muted bold block'>Amount</small>
        <strong className='block'>
          {card.currency_symbol}
          {card.original_amount}
        </strong>
      </div>
      <div
        style={{
          paddingBottom: '0.2em'
        }}>
        <small className='muted bold block'>Remaining</small>
        <strong className='block red'>
          {card.currency_symbol}
          {card.current_amount.toFixed(2)}
        </strong>
      </div>
      <div className='flex-auto flex justify-end pb1'>
        <Logo
          alt='CineSend'
          style={{
            maxWidth: '70px',
            maxHeight: '70px',
            height: '100%'
          }}
          className='col-12'/>
      </div>
    </div>
  </React.Fragment>
)

GiftCard.propTypes = {
  card: PropTypes.shape({
    currency_symbol: PropTypes.string.isRequired,
    original_amount: PropTypes.string.isRequired,
    current_amount: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired
}

export default GiftCard
