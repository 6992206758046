import React from "react"
import { connect, useSelector } from "react-redux"
import { withRouter, Redirect, Switch, Route } from "react-router-dom"
import Toggle from './components/toggle'
import Login from './components/login'
import Signup from './components/signup'
import Invitation from './components/invitation'
import Payment from './components/payment'
import Forgot from './components/forgot'
import Reset from './components/reset'
import MFA from './components/mfa'

const Form = ({ authenticated }) => {
  const externalAuthentication = useSelector(state => state.auth?.externalAuthentication.external_authentication_enabled)
  return (
    <>
      {authenticated && <Redirect to='/'/>}
      <section
        className='flex items-center justify-center col-12 mx-auto'
        style={{
          paddingTop: '50px',
          paddingBottom: '50px',
          minHeight: '550px',
          maxWidth: '380px'
        }}>
        <div className='col-12'>
          <div className='bg-white rounded box-shadow p3 mb2'>
            <Switch>
              <Route path='/login' component={Login}/>
              <Route path='/invite' component={Invitation}/>
              <Route path='/signup' component={Signup}/>
              <Route path='/payment' component={Payment}/>
              <Route path='/forgot' component={Forgot}/>
              <Route path='/reset-password' component={Reset}/>
              <Route path='/mfa' component={MFA}/>
            </Switch>
          </div>
          {!externalAuthentication && <Toggle/>}
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  authenticated: state.auth.status === "AUTHENTICATED"
})

export default withRouter(connect(mapStateToProps)(Form))
