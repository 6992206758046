import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status, Table, ButtonDropdown } from "@bitcine/cs-theme"
import { deleteRole, openEditRole } from "@api/settings/roles"
import { can, MODIFY_USERS_AND_ROLES } from "@src/helpers/authorization"
import styled from 'styled-components'
import { updateOrganization, saveOrganization } from "@api/settings/organization"


const Icon = styled.button`
  border-radius: 50%;
  width: 2em;
  height: 2em;
  margin-right: -1em;
  background: #bfc5c9;
  border: 2px solid #f9f9fa;
  display: inline-block;
  min-width: 0px;
  padding: 0;
`

const MAX_LIST_LENGTH = 10

const List = ({ user, roles, deleteRole, openEditRole, organization, updateOrganization, saveOrganization }) => (
  <Status pending={roles.status === "PENDING"} error={roles.status === "ERROR"}>
    <Table
      widths={[45, 240, "auto", 200, 65]}
      className='mt2'
      header={{
        columns: [{ text: "Default" }, { text: "Role Name" }, { text: "Description" }, { text: "Users" }, { text: "" }]
      }}
      body={{
        data: roles.list.filter(({ name = "" }) =>
          !roles.search || (name && name
            .toLowerCase()
            .trim()
            .includes(roles.search.toLowerCase().trim()))
        ),
        row: {
          render: [
            data => organization?.default_role === data._id ? <span className="green material-icons">check_circle</span> : null, 
            data => data.name,
            data => <span className='truncate'>{data.description}</span>,
            data => (
              <div className='flex items-center'>
                <div
                  className='flex'
                  style={{
                    flexDirection: 'row-reverse',
                    marginRight: '1em'
                  }}>
                  {data.users
                    .filter((user, index) => index < MAX_LIST_LENGTH)
                    .map(user => (
                      <Icon
                        key={user._id}
                        className='tooltip cs-button'
                        data-tooltip={user.full_name}>
                        {user.full_name ? user.full_name.charAt(0).toUpperCase() : <span>&nbsp;</span>}
                      </Icon>
                    ))}
                </div>
                {data.users.length > MAX_LIST_LENGTH && (
                  <small className='ml1 bold'>+{data.users.length - MAX_LIST_LENGTH} More</small>
                )}
              </div>
            ),
            data => can(user, [MODIFY_USERS_AND_ROLES]) &&
              <ButtonDropdown
                button={{
                  className: "material-icons",
                  text: "more_vert",
                  menu: true
                }}
                dropdown={{
                  content: [
                    {
                      text: "Edit Role",
                      icon: "mode_edit",
                      onClick: () => openEditRole(data),
                      show: !data.is_admin
                    },
                    {
                      text: "Delete Role",
                      icon: "delete",
                      onClick: () => {
                        if (data.users.length) {
                          window.alert("You must remove all users from this role before you can delete it.")
                        }
                        else {
                          deleteRole(data)
                        }
                      },
                      show: !data.is_admin
                    },
                    {
                      text: "Make Default",
                      icon: "check_circle",
                      onClick: () => {
                        updateOrganization({ "default_role": data._id })
                        saveOrganization("default_role", data._id)
                      },
                      show: true
                    }
                  ].filter(opt => opt.show),
                  clickCloses: true
                }}/>
          ]
        }
      }}/>
  </Status>
)

List.propTypes = {
  roles: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  deleteRole: PropTypes.func.isRequired,
  openEditRole: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  roles: state.settings.roles,
  user: state.user,
  organization: state.settings.organization,
})

const mapDispatchToProps = dispatch => ({
  deleteRole: bindActionCreators(deleteRole, dispatch),
  openEditRole: bindActionCreators(openEditRole, dispatch), 
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
