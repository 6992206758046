import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link, withRouter } from "react-router-dom"
import { updateAccount, logIn } from "@api/account"
import { ReactComponent as GoogleIcon } from "@src/media/icons/btn_google_light_normal_ios.svg"
import { GLOBALS } from "@src/config"
import queryString from "query-string"

const InternalLogin = ({ account, updateAccount, logIn, location }) => {
  const search = window.location.search.replace("logout", "")
  const param = queryString.parse(search).redirect
  const redirect = param ? `?redirect=${param}` : ""

  return (
    <>
      <form>
        <label className='cs-label' htmlFor={`__input-email`}>Email</label>
        <input
          autoFocus={true}
          value={account.email}
          data-test-id={`account-email-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('email') ? "error" : ""}`}
          type={'text'}
          id={`__input-email`}
          onChange={event => updateAccount({ email: event.target.value })} />
        {account.validation_errors.includes('email') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-email-error-text`}
            className='block red'>
            Valid email is required.
          </small>
        )}
        <label className='cs-label mt2' htmlFor={`__input-password`}>Password</label>
        <input
          value={account.password}
          data-test-id={`account-password-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('password') ? "error" : ""}`}
          type={'password'}
          id={`__input-password`}
          onChange={event => updateAccount({ password: event.target.value })} />
        {account.validation_errors.includes('password') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-password-error-text`}
            className='block red'>
            Password is required.
          </small>
        )}
        <div className='flex flex-auto justify-end my2'>
          <Link
            to={{
              pathname: "/forgot",
              search: location.search
            }}
            data-test-id='account-forgot-password-link'
            className='link small pointer'>
            {account.error_code === "blocked_device" ? "Reset Password" : "Forgot Password?"}
          </Link>
        </div>
        {account.error_message && (
          <small data-test-id='account-error-text' className='red block mt1 mb2'>
            {account.error_message}
          </small>
        )}
        <button
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={logIn}
          disabled={account.status === "PENDING" || !account.email || !account.password}
          className={`cs-button ${account.status.toLowerCase()} col-12`}>
          Log in
        </button>
      </form>
      <div className='mt3 py1 flex justify-center'>
        Or, sign in with your Google Account:
      </div>
      <a href={GLOBALS.SOCIAL_AUTH_URL + redirect}>
        <button
          id='cinesend-login-button'
          className='cs-button white col-12 flex items-center p0'
          tabIndex='2'
          onClick={e => {
            e.preventDefault()
            window.location = GLOBALS.SOCIAL_AUTH_URL + redirect
          }}>
          <GoogleIcon style={{ height: '38px' }} className='absolute' />
          <div className='flex flex-auto justify-center'>
            Sign in with Google
          </div>
        </button>
      </a>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  account: state.account
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  logIn: bindActionCreators(logIn, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InternalLogin))