import { GLOBALS } from "@src/config"

/**
 * Sets the Stripe in stripe.js. This needs to occur before
 * any Stripe client APIs are used.
 *
 * @returns {Promise}
 */

export const setStripeKey = () =>
  new Promise((resolve, reject) => {
    try {
      window.Stripe.setPublishableKey(GLOBALS.STRIPE_KEY)
      return resolve()
    } catch (error) {
      return reject(error)
    }
  })

/**
 * Adds a credit card to Stripe. This does not add a credit
 * card on our server, but creates a token needed for it.
 *
 * @param {String} [name] Cardholder name
 * @param {String} [number] Card number
 * @param {String} [cvc]
 * @param {String} [exp_month]
 * @param {String} [exp_year]
 *
 * @returns {Promise}
 */

export const getStripeToken = (name, number, cvc, exp_month, exp_year) =>
  new Promise((resolve, reject) => {
    try {
      return window.Stripe.card.createToken({ name, number, cvc, exp_month, exp_year }, (status, response) => {
        if (response.error) {
          return reject(response.error.message)
        } else {
          return resolve(response.id)
        }
      })
    } catch (error) {
      return reject(error)
    }
  })
