import { AUTH_CHECK_FAILED, AUTH_CHECK_FULFILLED, LOG_OUT_FULFILLED, GET_LOGIN_TYPE_FAILED, GET_LOGIN_TYPE_FULFILLED, GET_LOGIN_TYPE_PENDING } from "@actions/auth"
import { LOGIN_FULFILLED, ACCEPT_INVITE_FULFILLED, SUBMIT_MFA_LOGIN_FULFILLED, EXTERNAL_LOGIN_FULFILLED } from "@actions/account"

const INITIAL_STATE = {
  status: "PENDING",
  externalAuthentication: {
    enabled: false,
    status: "PENDING"
  }
}

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AUTH_CHECK_FAILED:
      return {
        ...state,
        status: "GUEST"
      }
    case AUTH_CHECK_FULFILLED:
    case ACCEPT_INVITE_FULFILLED:
    case SUBMIT_MFA_LOGIN_FULFILLED:
      return {
        ...state,
        status: "AUTHENTICATED"
      }

    case LOGIN_FULFILLED:
      return {
        ...state,
        status: action.payload.mfa_required ? "GUEST" : "AUTHENTICATED"
      }

    case LOG_OUT_FULFILLED:
      return {
        ...state,
        status: "GUEST"
      }
      case GET_LOGIN_TYPE_PENDING:
        return {
          ...state,
          externalAuthentication: {
            ...state.externalAuthentication,
            status: 'PENDING',
          },
        };
      case GET_LOGIN_TYPE_FULFILLED:
        return {
          ...state,
          externalAuthentication: {
            ...action.payload,
            status: 'FULFILLED',
          },
        };
      case GET_LOGIN_TYPE_FAILED:
        return {
          ...state,
          externalAuthentication: {
            ...state.externalAuthentication,
            status: 'FAILED',
          },
        };
      case EXTERNAL_LOGIN_FULFILLED:
        return {
          ...state,
          status: "AUTHENTICATED"
        }
    default:
      return state
  }
}
