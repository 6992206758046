const INITIAL_STATE = {
  status: "PENDING",
  credit_cards: [],
  gift_cards: [],
  deleting_credit_card_id: "",
  retrying_payment_credit_card_id: "",
  new: {
    mode: "credit-card",
    status: "READY",
    show: false,
    errors: [],
    stripe_error: "",
    credit_card_cardholder_name: "",
    credit_card_expiry: "",
    credit_card_cvc: "",
    credit_card_card_number: "",
    gift_card_pin: "",
    gift_card_number: "",
    gift_card_error: ""
  }
}

export default INITIAL_STATE
