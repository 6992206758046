export const UPDATE_TEAM_SETTINGS = "UPDATE_TEAM_SETTINGS"

export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS"
export const GET_TEAM_MEMBERS_PENDING = "GET_TEAM_MEMBERS_PENDING"
export const GET_TEAM_MEMBERS_FAILED = "GET_TEAM_MEMBERS_REJECTED"
export const GET_TEAM_MEMBERS_FULFILLED = "GET_TEAM_MEMBERS_FULFILLED"

export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE"
export const CHANGE_USER_ROLE_PENDING = "CHANGE_USER_ROLE_PENDING"
export const CHANGE_USER_ROLE_FAILED = "CHANGE_USER_ROLE_REJECTED"
export const CHANGE_USER_ROLE_FULFILLED = "CHANGE_USER_ROLE_FULFILLED"

export const TOGGLE_USER_STATUS = "TOGGLE_USER_STATUS"
export const TOGGLE_USER_STATUS_PENDING = "TOGGLE_USER_STATUS_PENDING"
export const TOGGLE_USER_STATUS_FAILED = "TOGGLE_USER_STATUS_REJECTED"
export const TOGGLE_USER_STATUS_FULFILLED = "TOGGLE_USER_STATUS_FULFILLED"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_PENDING = "DELETE_USER_PENDING"
export const DELETE_USER_FAILED = "DELETE_USER_REJECTED"
export const DELETE_USER_FULFILLED = "DELETE_USER_FULFILLED"

export const RESEND_USER_INVITE = "RESEND_USER_INVITE"
export const RESEND_USER_INVITE_PENDING = "RESEND_USER_INVITE_PENDING"
export const RESEND_USER_INVITE_FAILED = "RESEND_USER_INVITE_REJECTED"
export const RESEND_USER_INVITE_FULFILLED = "RESEND_USER_INVITE_FULFILLED"

export const UPDATE_NEW_USER = "UPDATE_NEW_USER"

export const INVITE_USER = "INVITE_USER"
export const INVITE_USER_PENDING = "INVITE_USER_PENDING"
export const INVITE_USER_FAILED = "INVITE_USER_REJECTED"
export const INVITE_USER_FULFILLED = "INVITE_USER_FULFILLED"