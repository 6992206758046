export const GET_EMAIL_NOTIFICATIONS = "GET_EMAIL_NOTIFICATIONS"
export const GET_EMAIL_NOTIFICATIONS_PENDING = "GET_EMAIL_NOTIFICATIONS_PENDING"
export const GET_EMAIL_NOTIFICATIONS_FAILED = "GET_EMAIL_NOTIFICATIONS_REJECTED"
export const GET_EMAIL_NOTIFICATIONS_FULFILLED = "GET_EMAIL_NOTIFICATIONS_FULFILLED"

export const UPDATE_EMAIL_NOTIFICATION = "UPDATE_EMAIL_NOTIFICATION"

export const SAVE_EMAIL_NOTIFICATION = "SAVE_EMAIL_NOTIFICATION"
export const SAVE_EMAIL_NOTIFICATION_PENDING = "SAVE_EMAIL_NOTIFICATION_PENDING"
export const SAVE_EMAIL_NOTIFICATION_FAILED = "SAVE_EMAIL_NOTIFICATION_REJECTED"
export const SAVE_EMAIL_NOTIFICATION_FULFILLED = "SAVE_EMAIL_NOTIFICATION_FULFILLED"
