import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import { getTeamMembers } from "@api/settings/team"
import { getTeamRoles } from "@api/settings/roles"
import { getOrganization } from '@api/settings/organization'
import Helmet from "react-helmet"
import Header from "./components/header"
import List from "./components/list"
import Add from "./components/add"

const Team = ({ getTeamRoles, getTeamMembers, showAdd, getOrganization }) => {
  useDidMount(() => {
    getTeamRoles()
    getTeamMembers()
    getOrganization()
  })
  return (
    <div>
      <Helmet title='CineSend | Users'/>
      <div className='bg-white rounded box-shadow p2'>
        <Header/>
        <List/>
        {showAdd && <Add/>}
      </div>
    </div>
  )
}

Team.propTypes = {
  getTeamMembers: PropTypes.func.isRequired,
  getTeamRoles: PropTypes.func.isRequired,
  showAdd: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  showAdd: state.settings.team.new.show
})

const mapDispatchToProps = dispatch => ({
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch),
  getTeamRoles: bindActionCreators(getTeamRoles, dispatch),
  getOrganization: bindActionCreators(getOrganization, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team)
