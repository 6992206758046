const INITIAL_STATE = {
  search: "",
  new: {
    show: false,
    status: "READY",
    full_name: "",
    email: "",
    role_id: "",
    errors: []
  },
  list: [],
  status: "PENDING"
}

export default INITIAL_STATE
