import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, NavLink } from "react-router-dom"
import styled from 'styled-components'
import createSidebar from "./links"

const LinkContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 240px;
`

const Sidebar = ({ user, organization }) => (
  <LinkContainer className='box-shadow pt2 pb3 bg-white overflow-auto col-12'>
    {createSidebar(user, organization).map((link, index) => (
      <div key={link.to}>
        {link.label_before && (
          <div className={`pl1 caption mb1 bold ml1 mr2 ${index > 0 ? "mt2" : ""}`}>
            <div className='border-bottom border-gray-5 py1'>{link.label_before}</div>
          </div>
        )}
        <NavLink
          exact
          to={link.to}
          style={{
            borderWidth: '4px'
          }}
          className='border-left border-white pl1 pr3 py1 block col-12'
          activeStyle={{ borderColor: "#FB0F3B" }}
          activeClassName='bold'>
          <div className='flex items-center'>
            <span className='material-icons muted mr1'>{link.icon}</span>
            {link.text}
          </div>
        </NavLink>
      </div>
    ))}
  </LinkContainer>
)

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string
  })
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  organization: state.user.organization
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Sidebar)
)
