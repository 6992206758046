export const AUTH_CHECK = "AUTH_CHECK"
export const AUTH_CHECK_PENDING = "AUTH_CHECK_PENDING"
export const AUTH_CHECK_FAILED = "AUTH_CHECK_REJECTED"
export const AUTH_CHECK_FULFILLED = "AUTH_CHECK_FULFILLED"

export const LOG_OUT = "LOG_OUT"
export const LOG_OUT_PENDING = "LOG_OUT_PENDING"
export const LOG_OUT_FAILED = "LOG_OUT_REJECTED"
export const LOG_OUT_FULFILLED = "LOG_OUT_FULFILLED"

export const GET_LOGIN_TYPE = "GET_LOGIN_TYPE"
export const GET_LOGIN_TYPE_PENDING = "GET_LOGIN_TYPE_PENDING"
export const GET_LOGIN_TYPE_FAILED = "GET_LOGIN_TYPE_FAILED"
export const GET_LOGIN_TYPE_FULFILLED = "GET_LOGIN_TYPE_FULFILLED"
