import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateNewPaymentMethod } from "@api/settings/billing/index"
import CreditCardInput from "react-credit-card-input"

const Form = ({ newPaymentMethod, updateNewPaymentMethod }) =>
  newPaymentMethod.mode === "credit-card" ? (
    <React.Fragment>
      <div>
        <label className='cs-label' htmlFor='name'>Cardholder Full Name</label>
        <input
          value={newPaymentMethod.credit_card_cardholder_name}
          id='name'
          placeholder='Enter your name'
          onChange={e => updateNewPaymentMethod({ credit_card_cardholder_name: e.target.value })}
          className='cs-input block col-12 mb2'/>
      </div>
      <div>
        <label className='cs-label' htmlFor='card-number'>Credit Card</label>
        <CreditCardInput
          cardNumberInputProps={{
            value: newPaymentMethod.credit_card_card_number,
            style: { flex: "auto", minWidth: "160px" },
            onChange: e => updateNewPaymentMethod({ credit_card_card_number: e.target.value })
          }}
          cardExpiryInputProps={{
            value: newPaymentMethod.credit_card_expiry,
            style: { minWidth: "60px" },
            onChange: e => updateNewPaymentMethod({ credit_card_expiry: e.target.value })
          }}
          cardCVCInputProps={{
            value: newPaymentMethod.credit_card_cvc,
            style: { minWidth: "40px" },
            onChange: e => updateNewPaymentMethod({ credit_card_cvc: e.target.value })
          }}
          containerClassName='col-12 block mb2 rounded'
          fieldStyle={{
            border: "1px solid #C7CDCF",
            height: "40px",
            display: "flex"
          }}
          fieldClassName='col-12 block'/>
      </div>
    </React.Fragment>
  ) : (
    <div className='flex items-center mb2'>
      <div className='col-8 pr2'>
        <label className='cs-label' htmlFor='gift-card-pin'>Gift Card Number</label>
        <input
          value={newPaymentMethod.gift_card_number}
          onChange={e => updateNewPaymentMethod({ gift_card_number: e.target.value })}
          className='cs-input col-12 block'/>
      </div>
      <div className='col-4'>
        <label className='cs-label' htmlFor='gift-card-pin'>Gift Card Pin</label>
        <input
          value={newPaymentMethod.gift_card_pin}
          onChange={e => updateNewPaymentMethod({ gift_card_pin: e.target.value })}
          className='cs-input col-12 block'/>
      </div>
    </div>
  )

Form.propTypes = {
  newPaymentMethod: PropTypes.shape({
    credit_card_cardholder_name: PropTypes.string.isRequired,
    credit_card_cvc: PropTypes.string.isRequired,
    credit_card_expiry: PropTypes.string.isRequired,
    credit_card_card_number: PropTypes.string.isRequired,
    gift_card_pin: PropTypes.string.isRequired,
    gift_card_number: PropTypes.string.isRequired
  }).isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  newPaymentMethod: state.settings.billing.new
})

const mapDispatchToProps = dispatch => ({
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
