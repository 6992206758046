export const GET_ASPERA_ENTITLEMENTS = "GET_ASPERA_ENTITLEMENTS"
export const GET_ASPERA_ENTITLEMENTS_PENDING = "GET_ASPERA_ENTITLEMENTS_PENDING"
export const GET_ASPERA_ENTITLEMENTS_FULFILLED = "GET_ASPERA_ENTITLEMENTS_FULFILLED"
export const GET_ASPERA_ENTITLEMENTS_FAILED = "GET_ASPERA_ENTITLEMENTS_REJECTED"

export const SELECT_ENTITLEMENT = "SELECT_ENTITLEMENT"
export const TOGGLE_PURCHASE_MODAL = "TOGGLE_PURCHASE_MODAL"

export const PURCHASE_ASPERA_ENTITLEMENT = "PURCHASE_ASPERA_ENTITLEMENT"
export const PURCHASE_ASPERA_ENTITLEMENT_PENDING = "PURCHASE_ASPERA_ENTITLEMENT_PENDING"
export const PURCHASE_ASPERA_ENTITLEMENT_FULFILLED = "PURCHASE_ASPERA_ENTITLEMENT_FULFILLED"
export const PURCHASE_ASPERA_ENTITLEMENT_FAILED = "PURCHASE_ASPERA_ENTITLEMENT_REJECTED"

export const GET_ASPERA_ENTITLEMENT_ITEMS = "GET_ASPERA_ENTITLEMENT_ITEMS"
export const GET_ASPERA_ENTITLEMENT_ITEMS_PENDING = "GET_ASPERA_ENTITLEMENT_ITEMS_PENDING"
export const GET_ASPERA_ENTITLEMENT_ITEMS_FULFILLED = "GET_ASPERA_ENTITLEMENT_ITEMS_FULFILLED"
export const GET_ASPERA_ENTITLEMENT_ITEMS_FAILED = "GET_ASPERA_ENTITLEMENT_ITEMS_REJECTED"

export const GET_ASPERA_ENTITLEMENT_QUOTE = "GET_ASPERA_ENTITLEMENT_QUOTE"
export const GET_ASPERA_ENTITLEMENT_QUOTE_PENDING = "GET_ASPERA_ENTITLEMENT_QUOTE_PENDING"
export const GET_ASPERA_ENTITLEMENT_QUOTE_FULFILLED = "GET_ASPERA_ENTITLEMENT_QUOTE_FULFILLED"
export const GET_ASPERA_ENTITLEMENT_QUOTE_FAILED = "GET_ASPERA_ENTITLEMENT_QUOTE_REJECTED"
