import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, withRouter } from "react-router-dom"
import RedirectWithStatus from "./redirect_with_status"

const PublicRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !authenticated ? <Component {...props}/> : <RedirectWithStatus status={301} to='/settings/apps'/>
      }/>
  )
}

PublicRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

const mapStateToProps = state => ({
  authenticated: state.auth.status === "AUTHENTICATED"
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PublicRoute)
)
