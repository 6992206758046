import React from "react"
import ReactToggle from "react-toggle"
import styled from 'styled-components'
import style from "./style"

const ToggleWrapper = styled.span`
  ${style}
`

const Toggle = props => (
  <ToggleWrapper className='inline-flex items-center'>
    <ReactToggle {...props} icons={false}/>
  </ToggleWrapper>
)

export default Toggle
