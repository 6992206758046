export const GET_ORGANIZATION = "GET_ORGANIZATION"
export const GET_ORGANIZATION_PENDING = "GET_ORGANIZATION_PENDING"
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_REJECTED"
export const GET_ORGANIZATION_FULFILLED = "GET_ORGANIZATION_FULFILLED"

export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION"

export const SAVE_ORGNANIZATION = "SAVE_ORGNANIZATION"
export const SAVE_ORGNANIZATION_PENDING = "SAVE_ORGNANIZATION_PENDING"
export const SAVE_ORGNANIZATION_FAILED = "SAVE_ORGNANIZATION_REJECTED"
export const SAVE_ORGNANIZATION_FULFILLED = "SAVE_ORGNANIZATION_FULFILLED"

export const ACTIVATE_APPLICATION = "ACTIVATE_APPLICATION"
export const ACTIVATE_APPLICATION_PENDING = "ACTIVATE_APPLICATION_PENDING"
export const ACTIVATE_APPLICATION_FAILED = "ACTIVATE_APPLICATION_REJECTED"
export const ACTIVATE_APPLICATION_FULFILLED = "ACTIVATE_APPLICATION_FULFILLED"
