import React from "react"
import PropTypes from "prop-types"
import Number from "./number"
import Footer from "./footer"
import Header from "./header"

const CreditCard = ({ card, isDefault, brand, isExpired, isFailed }) => (
  <React.Fragment>
    <Header card={card} isDefault={isDefault} isExpired={isExpired} isFailed={isFailed}/>
    <Number number={card.last4} brand={brand} isExpired={isExpired} isFailed={isFailed}/>
    <Footer
      expiry={(card.exp_month < 10 ? "0" : "") + "" + (card.exp_month + " / " + card.exp_year)}
      brand={brand}
      isExpired={isExpired}
      isFailed={isFailed}/>
  </React.Fragment>
)

CreditCard.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.oneOf(["credit-card", "gift-card"]).isRequired,
    _id: PropTypes.string.isRequired
  }).isRequired,
  isDefault: PropTypes.bool.isRequired,
  brand: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired
}

export default CreditCard
