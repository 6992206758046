import React from "react"
import DevTools from "@src/redux/tools/"

const Tools = () => (
  <React.Fragment>
    {process.env.NODE_ENV === "development" && (
      <span>
        <DevTools/>
      </span>
    )}
  </React.Fragment>
)

export default Tools
