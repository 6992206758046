import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import { getEmailNotifications, updateEmailNotification, saveEmailNotification } from "@api/settings/notifications"
import Helmet from "react-helmet"
import { Status } from "@bitcine/cs-theme"
import Toggle from "@src/components/toggle/"
import getAvailableNotifications from "./config"

const Notifications = ({
  user,
  organization,
  getEmailNotifications,
  notifications,
  updateEmailNotification,
  saveEmailNotification
}) => {
  useDidMount(() => {
    getEmailNotifications()
  })
  const update = (object, key) => {
    const { list } = notifications
    const payload = {
      ...list,
      [object]: {
        ...list[object],
        [key]: list[object] ? !list[object][key] : true
      }
    }
    updateEmailNotification({ [object]: payload[object] })
    saveEmailNotification(payload)
  }
  return (
    <div data-test-id='personal-profile'>
      <Helmet title='CineSend | Notifications'/>
      <div className='box-shadow rounded mb3 p2 bg-white max-width-3'>
        <h4>Email Notifications</h4>
        <Status pending={notifications.status === "PENDING"} error={notifications.status === "ERROR"}>
          <form>
            {getAvailableNotifications(user, organization).map((notification, index) => (
              <div key={index} className='py2'>
                <strong className='block'>{notification.type}</strong>
                <div>
                  {notification.rows
                    // .filter(
                    //   row =>
                    //     notifications.list[notification.key] && notifications.list[notification.key][row.flag] !== null
                    // )
                    .map(row => (
                      <label
                        key={row.flag}
                        htmlFor={row.flag}
                        className='flex items-center py1 pointer noselect'>
                        <Toggle
                          id={row.flag}
                          checked={notifications.list[notification.key] && notifications.list[notification.key][row.flag]}
                          onChange={() => update(notification.key, row.flag)}
                          className='mr1'/>
                        <div className='pl1'>
                          <strong
                            style={{
                              marginBottom: '-0.25em',
                              color: '#424a50'
                            }}
                            className='block'>
                            {row.text}
                          </strong>
                          <p className='mb0'>{row.description}</p>
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            ))}
          </form>
        </Status>
      </div>
    </div>
  )
}

Notifications.propTypes = {
  user: PropTypes.object.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string
  }),
  notifications: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired
  }).isRequired,
  getEmailNotifications: PropTypes.func.isRequired,
  updateEmailNotification: PropTypes.func.isRequired,
  saveEmailNotification: PropTypes.func.isRequired,
  availableNotifications: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  notifications: state.settings.notifications,
  user: state.user,
  organization: state.user.organization
})

const mapDispatchToProps = dispatch => ({
  getEmailNotifications: bindActionCreators(getEmailNotifications, dispatch),
  updateEmailNotification: bindActionCreators(updateEmailNotification, dispatch),
  saveEmailNotification: bindActionCreators(saveEmailNotification, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
