import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status, Table, ButtonDropdown } from "@bitcine/cs-theme"
import Select from "@src/components/select/"
import { changeUserRole, toggleUserStatus, resendUserInvite, deleteUser } from "@api/settings/team"

const BACKGROUNDS = {
  Active: "#00d19c",
  Pending: "#ffc765",
  Suspended: "#ff5a5a"
}

const List = ({ team, roles, user, changeUserRole, toggleUserStatus, resendUserInvite, deleteUser }) => (
  <Status pending={team.status === "PENDING"} error={team.status === "ERROR"}>
    <Table
      widths={["auto", 260, 200, 65]}
      className='mt2'
      header={{
        columns: [{ text: "User" }, { text: "Role" }, { text: "Status" }, { text: "" }]
      }}
      body={{
        data: team.list.filter(({ full_name = "" }) =>
          full_name
            .toLowerCase()
            .trim()
            .includes(team.search.toLowerCase().trim())
        ),
        row: {
          render: [
            data => (
              <div>
                {data.full_name}
                <small className='block muted'>{data.email}</small>
              </div>
            ),
            data =>
              data.is_owner ? (
                data.role_name + ' (Owner)'
              ) : (
                <Select
                  options={roles}
                  value={roles.find(({ _id }) => _id === data.role_id)}
                  clearable={false}
                  className='col-12 mr2'
                  onChange={role => changeUserRole(data._id, role.value)}/>
              ),
            data => (
              <div className='flex items-center'>
                <div
                  style={{
                    background: BACKGROUNDS[data.status],
                    width: '8px',
                    height: '8px',
                  }}
                  className='inline-block mr1 circle'/>
                {data.status}
              </div>
            ),
            data => (
              <ButtonDropdown
                button={{
                  className: "material-icons",
                  text: "more_vert",
                  menu: true
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: "Suspend",
                      show: data.status === "Active",
                      disabled: data.is_owner,
                      onClick: () => toggleUserStatus(data),
                      icon: "lock"
                    },
                    {
                      text: "Activate",
                      show: data.status === "Suspended" && !data.is_owner,
                      onClick: () => toggleUserStatus(data),
                      icon: "lock_open"
                    },
                    {
                      text: "Resend Invite",
                      show: data.status === "Pending",
                      onClick: () => resendUserInvite(data),
                      icon: "email"
                    },
                    {
                      text: "Remove",
                      // Show if data is not owner, and current user is an admin or owner
                      show: !data.is_owner && (user.is_org_admin || (user._id === user.organization.owner_id)),
                      // Disable if data is for admin and user is not the owner
                      disabled: (data.is_admin && !(user._id === user.organization.owner_id)),
                      onClick: () => deleteUser(data),
                      icon: "delete"
                    }
                  ].filter(({ show }) => show)
                }}/>
            )
          ]
        }
      }}/>
  </Status>
)

List.propTypes = {
  team: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  roles: PropTypes.array.isRequired,
  changeUserRole: PropTypes.func.isRequired,
  toggleUserStatus: PropTypes.func.isRequired,
  resendUserInvite: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  team: state.settings.team,
  roles: state.settings.roles.list.map(role => ({ _id: role._id, value: role._id, label: role.name })),
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  changeUserRole: bindActionCreators(changeUserRole, dispatch),
  toggleUserStatus: bindActionCreators(toggleUserStatus, dispatch),
  resendUserInvite: bindActionCreators(resendUserInvite, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
