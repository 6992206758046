import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import dayjs from "dayjs"
import { getAsperaDownloads } from "@api/settings/aspera_downloads"
import { Status, Table } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/invoices.empty.inline.svg"
import humanFileSize from "@src/helpers/humanFileSize"

const AsperaDownloads = ({ getAsperaDownloads, asperaDownloads }) => {
  useDidMount(() => {
    getAsperaDownloads()
  })
  return (
    <div data-test-id='aspera-downloads'>
      <h4>Aspera Downloads</h4>
      <Status pending={asperaDownloads.status === "PENDING"} error={asperaDownloads.status === "ERROR"}>
        <Table
          widths={["auto", 300, 180]}
          className='mt2'
          header={{
            columns: [{ text: "File Name" }, { text: "Downloader" }, { text: "Last Updated" }]
          }}
          body={{
            data: asperaDownloads.list,
            row: {
              compact: true,
              render: [
                data => (
                  <div className='truncate'>
                    {data.file_name}
                    <span className='block light small'>{humanFileSize(data.total_size)}</span>
                  </div>
                ),
                data => (
                  <div className='truncate'>
                    {data.downloader}
                    <span className='block light small'>
                      {data.location} - {data.connection}
                    </span>
                  </div>
                ),
                data =>
                  dayjs
                    .utc(data.updated_at)
                    .local()
                    .format("MMM D, YYYY h:mma")
              ]
            },
            empty: {
              icon: <Icon/>,
              title: "No downloads",
              text: "Once your content is downloaded via Aspera, activity will appear here."
            }
          }}
          paginate={{
            currentPage: asperaDownloads.currentPage,
            pageCount: Math.ceil(asperaDownloads.totalResults / 5),
            onPageChange: page => getAsperaDownloads(page)
          }}/>
      </Status>
    </div>
  )
}

AsperaDownloads.propTypes = {
  asperaDownloads: PropTypes.object.isRequired,
  getAsperaDownloads: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  asperaDownloads: state.settings.aspera_downloads
})

const mapDispatchToProps = dispatch => ({
  getAsperaDownloads: bindActionCreators(getAsperaDownloads, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsperaDownloads)
