export const UPDATE_NEW_PASSWORD = "UPDATE_NEW_PASSWORD"

export const POST_UPDATE_USER_PASSWORD = "POST_UPDATE_USER_PASSWORD"
export const POST_UPDATE_USER_PASSWORD_PENDING = "POST_UPDATE_USER_PASSWORD_PENDING"
export const POST_UPDATE_USER_PASSWORD_FAILED = "POST_UPDATE_USER_PASSWORD_REJECTED"
export const POST_UPDATE_USER_PASSWORD_FULFILLED = "POST_UPDATE_USER_PASSWORD_FULFILLED"

export const UPDATE_MFA = "UPDATE_MFA"

export const GET_MFA_QR_CODE = "GET_MFA_QR_CODE"
export const GET_MFA_QR_CODE_PENDING = "GET_MFA_QR_CODE_PENDING"
export const GET_MFA_QR_CODE_FAILED = "GET_MFA_QR_CODE_REJECTED"
export const GET_MFA_QR_CODE_FULFILLED = "GET_MFA_QR_CODE_FULFILLED"

export const VALIDATE_ENABLE_MFA_QR_CODE = "VALIDATE_ENABLE_MFA_QR_CODE"
export const VALIDATE_ENABLE_MFA_QR_CODE_PENDING = "VALIDATE_ENABLE_MFA_QR_CODE_PENDING"
export const VALIDATE_ENABLE_MFA_QR_CODE_FAILED = "VALIDATE_ENABLE_MFA_QR_CODE_REJECTED"
export const VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED = "VALIDATE_ENABLE_MFA_QR_CODE_FULFILLED"

export const DISABLE_MFA = "DISABLE_MFA"
export const DISABLE_MFA_PENDING = "DISABLE_MFA_PENDING"
export const DISABLE_MFA_FULFILLED = "DISABLE_MFA_FULFILLED"
export const DISABLE_MFA_FAILED = "DISABLE_MFA_REJECTED"
