import { UPDATE_USER, SAVE_USER } from "@actions/user"
import { put } from "@src/helpers/fetch"
import { addGlobalMessage } from "./global_messages"

/**
 * Updates state.user
 *
 * @param {Object} [payload]
 *
 * @returns {Function} A redux dispatch
 */

export const updateUser = payload => dispatch => dispatch({ type: UPDATE_USER, payload })

/**
 * Saves user properties to the server
 *
 * @param {Object} [payload]
 *
 * @returns {Function} A redux dispatch
 */

export const saveUser = payload => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_USER,
      `users/${getState().user._id}`,
      payload,
      () => dispatch(addGlobalMessage("Saved!", "success")),
      () => dispatch(addGlobalMessage("An error occurred.", "error"))
    )
  )
