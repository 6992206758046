import React from "react"
import Slider from "react-rangeslider"
import styled from 'styled-components'
import style from "./style"

const Wrapper = styled.span`
  ${style}
`

const Range = props => (
  <Wrapper>
    <Slider {...props}/>
  </Wrapper>
)

export default Range
