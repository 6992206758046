import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Helmet from "react-helmet"
import favicon from "@src/media/favicon.png"
import { hot, setConfig } from "react-hot-loader"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjsPluginUTC from "dayjs-plugin-utc"
import relativeTime from "dayjs/plugin/relativeTime"
import { Switch, withRouter, Route } from "react-router-dom"
import styled from 'styled-components'
import { Status } from '@bitcine/cs-theme'
import PublicRoute from "@src/components/router/public"
import PrivateRoute from "@src/components/router/private"
import RedirectWithStatus from "@src/components/router/redirect_with_status"
import { injectGlobal } from "emotion"
import { getCountries, getUserLocation, updateUtilities } from "@api/utilities"
import { authCheck } from "@api/auth"
import Messages from "@src/components/global_messages"
import Account from "@src/pages/account/"
import Tools from "@src/components/tools/"
import Navigation from "@src/components/navigation"
import PageNotFound from "@src/pages/404/"
import PageError from "@src/pages/500"
import Settings from "@src/pages/settings/"
import LogOut from "@src/pages/logout/"
import "@src/css/index.css"
import css from "@src/css/default"
import '@bitcine/cs-theme/dist/index.scss'
import AuthCallback from "../pages/auth_callback"

injectGlobal(`${css}`)

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(dayjsPluginUTC)

// This line is needed to allow react-hot-loader to work with React Hooks
setConfig({ pureSFC: true })

const scripts = [
  { src: "https://js.stripe.com/v2/", props: { defer: true } },
  { src: "https://cdn.trackjs.com/releases/current/tracker.js", props: { defer: true } }
]

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  padding-top: 56px;
`

class Root extends React.Component {
  componentDidMount() {
    this.props.getCountries()
    this.props.getUserLocation()
    this.props.authCheck()
    this.props.updateUtilities("history", this.props.history)
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>CineSend Account</title>
          <meta httpEquiv='X-UA-Compatible' content='IE=edge'/>
          <meta charSet='utf-8'/>
          <meta httpEquiv='Content-Language' content='en'/>
          <meta name='viewport' content='width=device-width, initial-scale=1'/>
          <meta name='theme-color' content='white'/>
          {scripts.map(script => (
            <script key={script.src} src={script.src} type='text/javascript' {...script.props}/>
          ))}
          <link id='favicon' rel='shortcut icon' href={favicon} sizes='16x16 32x32' type='image/png'/>
        </Helmet>
        <Status pending={this.props.pending}>
          <PageContainer>
            <Navigation/>
            <ContentContainer>
              <Switch>
                <Route exact path='/authorization-callback' component={AuthCallback}/>
                <RedirectWithStatus status={301} exact from='/' to='/settings/apps'/>
                <RedirectWithStatus status={301} exact from='/index.html' to='/settings/apps'/>
                <PublicRoute path='/login' component={Account}/>
                <PublicRoute path='/signup' component={Account}/>
                <PublicRoute path='/payment' component={Account}/>
                <PublicRoute path='/reset-password' component={Account}/>
                <PublicRoute path='/forgot' component={Account}/>
                <PublicRoute path='/invite' component={Account}/>
                <PublicRoute path='/mfa' component={Account}/>
                <PrivateRoute path='/settings' component={Settings}/>
                <PrivateRoute path='/logout' component={LogOut}/>
                <PublicRoute path='/error' component={PageError}/>
                <PrivateRoute component={PageNotFound}/>
              </Switch>
            </ContentContainer>
            <Messages/>
          </PageContainer>
        </Status>
        <Tools/>
      </React.Fragment>
    )
  }
}

Root.propTypes = {
  getCountries: PropTypes.func.isRequired,
  getUserLocation: PropTypes.func.isRequired,
  updateUtilities: PropTypes.func.isRequired,
  authCheck: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  pending: state.auth.status === 'PENDING'
})

const mapDispatchToProps = dispatch => ({
  getCountries: bindActionCreators(getCountries, dispatch),
  getUserLocation: bindActionCreators(getUserLocation, dispatch),
  updateUtilities: bindActionCreators(updateUtilities, dispatch),
  authCheck: bindActionCreators(authCheck, dispatch)
})

export default hot(module)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Root)
  )
)
