import { GET_INVOICES } from "@actions/settings/invoices"
import { get } from "@src/helpers/fetch"

/**
 * Fetches user/organization invoices from the server
 *
 * @returns {Promise}
 */

export const getInvoices = (skip = 0) => dispatch =>
    dispatch(get(GET_INVOICES, `settings/invoices?skip=${skip}`))
