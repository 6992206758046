import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Switch, Redirect } from "react-router-dom"
import styled from 'styled-components'
import PrivateRoute from "@src/components/router/private"
import RedirectWithStatus from "@src/components/router/redirect_with_status"
import Error from "@src/components/error/"
import PageNotFound from "@src/pages/404/"
import PersonalSettings from "./pages/personal"
import Security from "./pages/security"
import Apps from "./pages/apps"
import Notifications from "./pages/notifications"
import Roles from "./pages/roles"
import Team from "./pages/team"
import Organization from "./pages/organization"
import Billing from "./pages/billing"
import AsperaDownloads from "./pages/aspera"
import Sidebar from "./components/sidebar"
import Branding from "./pages/branding"
import { canWhiteLabel } from "./components/links"

const ContentContainer = styled.div`
  margin-left: 240px;
`

const Settings = ({ match, canWhiteLabel }) => (
  <div>
    <Sidebar/>
    <Error>
      <ContentContainer className='flex-auto max-width-4 p2'>
        <Switch>
          <RedirectWithStatus status={301} exact from={match.path} to={`${match.path}/apps`}/>
          <Redirect exact from={`${match.path}/organization`} to ={`${match.path}/team`}/>
          <PrivateRoute path={`${match.path}/apps`} component={Apps}/>
          <PrivateRoute path={`${match.path}/profile`} component={PersonalSettings}/>
          <PrivateRoute path={`${match.path}/security`} component={Security}/>
          <PrivateRoute path={`${match.path}/notifications`} component={Notifications}/>
          <PrivateRoute path={`${match.path}/roles`} component={Roles}/>
          <PrivateRoute path={`${match.path}/users`} component={Team}/>
          <PrivateRoute path={`${match.path}/team`} component={Organization}/>
          <PrivateRoute path={`${match.path}/plans`} component={Billing}/>
          <PrivateRoute path={`${match.path}/billing`} component={Billing}/>
          <PrivateRoute path={`${match.path}/aspera`} component={AsperaDownloads}/>
          {canWhiteLabel && <PrivateRoute path={`${match.path}/screeners`} component={Branding}/>}
          <PrivateRoute component={PageNotFound}/>
        </Switch>
      </ContentContainer>
    </Error>
  </div>
)

Settings.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired,
  canWhiteLabel: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  canWhiteLabel: canWhiteLabel(state.user.organization)
})

export default connect(mapStateToProps)(Settings)
