import { combineReducers } from "redux"
import security from "./security"
import notifications from "./notifications"
import team from "./team"
import roles from "./roles"
import organization from "./organization"
import invoices from "./invoices"
import billing from "./billing"
import branding from "./branding"
import aspera_downloads from "./aspera_downloads"
import aspera_entitlements from "./aspera_entitlements"

const rootReducer = combineReducers({
  security,
  notifications,
  team,
  roles,
  organization,
  invoices,
  billing,
  branding,
  aspera_downloads,
  aspera_entitlements
})

export default rootReducer
