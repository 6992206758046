import React from "react"
import PropTypes from "prop-types"
import { CompactPicker } from "react-color"
import Toggle from "react-toggle"
import { SlideDown } from "react-slidedown"

const defaultColor = {
  hex: "#000000",
  hsl: { h: 1.8867924528301887, s: 0, l: 0, a: 1 },
  hsv: { h: 1.8867924528301887, s: 0, v: 0, a: 1 },
  oldHue: 1.8867924528301887,
  rgb: { r: 0, g: 0, b: 0, a: 1 }
}

class ColorPicker extends React.Component {
  render() {
    const { label, color, onChange, className } = this.props
    return (
      <div className={className}>
        <div className='flex items-center mb1'>
          <Toggle
            defaultChecked={!!color}
            onChange={() => {
              if (color) {
                onChange(null)
              } else {
                onChange(color || defaultColor)
              }
            }}/>
          <strong className='ml2'>{label}</strong>
        </div>

        <SlideDown>
          {!!color ? (
            <CompactPicker color={color} onChange={newColor => onChange(newColor)} disableAlpha={true}/>
          ) : null}
        </SlideDown>
      </div>
    )
  }
}

ColorPicker.defaultProps = {
  className: ""
}

ColorPicker.propTypes = {
  color: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}

export default ColorPicker
