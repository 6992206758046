const INITIAL_STATE = {
  status: "PENDING",
  initHash: "",
  newHash: "",
  items: {
    accent_color: null,
    background_color: null,
    header_text_font_color: null,
    header_text_font_family: null,
    body_text_font_color: null,
    body_text_font_family: null,
    screener_logo_url: null,
    show_company_name: false,
    use_custom_font: false,
    custom_header_font_id: null,
    custom_body_font_id: null,
    email_text_font_family: null
  },
  isUploadingFont: false
}

export default INITIAL_STATE
