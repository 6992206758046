export const GET_BRANDING = "GET_BRANDING"
export const GET_BRANDING_PENDING = "GET_BRANDING_PENDING"
export const GET_BRANDING_FAILED = "GET_BRANDING_REJECTED"
export const GET_BRANDING_FULFILLED = "GET_BRANDING_FULFILLED"

export const UPDATE_BRANDING = "UPDATE_BRANDING"

export const SAVE_BRANDING = "SAVE_BRANDING"
export const SAVE_BRANDING_PENDING = "SAVE_BRANDING_PENDING"
export const SAVE_BRANDING_FAILED = "SAVE_BRANDING_REJECTED"
export const SAVE_BRANDING_FULFILLED = "SAVE_BRANDING_FULFILLED"

export const SAVE_FONT = "SAVE_FONT"
export const SAVE_FONT_PENDING = "SAVE_FONT_PENDING"
export const SAVE_FONT_FULFILLED = "SAVE_FONT_FULFILLED"
