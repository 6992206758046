const canViewFiles = organization => (organization && organization.plan.can_access_files) || !organization
const canViewFestivals = organization => organization && organization.plan.can_access_festivals

const getAvailableNotifications = (user, organization) =>
  [
    {
      type: "Account",
      key: "account",
      description: "Notify me about:",
      show: true,
      rows: [
        // {
        // 	flag: "remaining_screener_credits",
        // 	text: "Remaining screener credits",
        // 	description: "When your account is running out screener credits."
        // },
        {
          flag: "payment_updates",
          text: "Payment updates",
          description: "When your payment has been changed or if there is a processing error."
        }
      ]
    },
    {
      type: "Security",
      key: "security",
      description: "Notify me about:",
      show: true,
      rows: [
        {
          flag: "device_access",
          text: "Device access",
          description: "When your account is accessed by a new web browser."
        },
        {
          flag: "password_attempts",
          text: "Password attempts",
          description: "If 3 or more password attempts are used to log in."
        },
        {
          flag: "log_in_credential_updates",
          text: "Log in credential updates",
          description: "When your log in password is changed or two step verification is enabled or disabled."
        }
      ]
    },
    {
      type: "CineSend Festivals",
      key: "festivals",
      app: "festivals",
      description: "Notify me about:",
      show: canViewFestivals(organization),
      rows: [
        {
          flag: "submissions",
          text: "Submissions",
          description: "When submissions are provided to file requests."
        }
      ]
    },
    {
      type: "CineSend Files",
      key: "projects",
      app: "files",
      description: "Notify me about:",
      show: canViewFiles(organization),
      rows: [
        // {
        // 	flag: "project_file_activity",
        // 	text: "Project file activity",
        // 	description: "When project files are shared and received."
        // },
        {
          flag: "plan_updates",
          text: "Subscription updates",
          description: "When your account subscription has been upgraded or downgraded."
        },
        {
          flag: "storage_space",
          text: "Storage space",
          description: "When your account is running out of storage space."
        },
        {
          flag: "order_updates",
          text: "Order updates",
          description: "When project orders are created, updated, and completed."
        },
        {
          flag: "file_requests",
          text: "File requests",
          description: "When file requests are shared and files are uploaded to the project."
        }
      ]
    },
    {
      type: "Screeners and Screener Rooms",
      key: "screeners_and_screener_rooms",
      app: "files",
      description: "Notify me about:",
      show: canViewFiles(organization),
      rows: [
        {
          flag: "screener_updates",
          text: "Screener updates",
          description: "When screener rooms or screeners are created, modified and shared."
        }
        // {
        // 	flag: "screener_security",
        // 	text: "Screener security",
        // 	description: "When screener active watermarking detects malicious activity."
        // },
        // {
        // 	flag: "reviewer_activity",
        // 	text: "Reviewer activity",
        // 	description: "When reviewers open and watch shared screener rooms or screeners."
        // }
      ]
    },
    {
      type: "Updates from CineSend",
      key: "updates_from_cinesend",
      description: "Notify me about:",
      show: true,
      rows: [
        // {
        // 	flag: "cinesend_news",
        // 	text: "Screener updates",
        // 	description: "News about CineSend product and feature updates."
        // },
        {
          flag: "cinesend_surveys",
          text: "CineSend surveys",
          description: "Participate in CineSend research surveys to help improve future updates."
        }
      ]
    }
  ].filter(opt => opt.show)

export default getAvailableNotifications
