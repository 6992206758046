import {
  UPDATE_TEAM_SETTINGS,
  GET_TEAM_MEMBERS_PENDING,
  GET_TEAM_MEMBERS_FAILED,
  GET_TEAM_MEMBERS_FULFILLED,
  CHANGE_USER_ROLE_FULFILLED,
  DELETE_USER_FULFILLED,
  TOGGLE_USER_STATUS_FULFILLED,
  UPDATE_NEW_USER,
  INVITE_USER_PENDING,
  INVITE_USER_FAILED,
  INVITE_USER_FULFILLED
} from "@actions/settings/team"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_TEAM_SETTINGS:
      return {
        ...state,
        ...action.payload
      }

    case GET_TEAM_MEMBERS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_TEAM_MEMBERS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_TEAM_MEMBERS_FULFILLED:
      return {
        ...state,
        list: action.payload,
        status: "READY"
      }

    case TOGGLE_USER_STATUS_FULFILLED:
    case DELETE_USER_FULFILLED:
    case CHANGE_USER_ROLE_FULFILLED:
      return {
        ...state,
        list: action.payload
      }

    case UPDATE_NEW_USER:
      return {
        ...state,
        new: {
          ...state.new,
          ...action.payload
        }
      }

    case INVITE_USER_PENDING:
      return {
        ...state,
        new: {
          ...state.new,
          status: "PENDING"
        }
      }
    case INVITE_USER_FAILED:
      return {
        ...state,
        new: {
          ...state.new,
          status: "ERROR"
        }
      }
    case INVITE_USER_FULFILLED:
      return {
        ...state,
        new: INITIAL_STATE.new,
        list: action.payload
      }

    default:
      return state
  }
}
