import {
  GET_ASPERA_ENTITLEMENTS_PENDING,
  GET_ASPERA_ENTITLEMENTS_FULFILLED,
  GET_ASPERA_ENTITLEMENTS_FAILED,
  SELECT_ENTITLEMENT,
  PURCHASE_ASPERA_ENTITLEMENT_PENDING,
  PURCHASE_ASPERA_ENTITLEMENT_FULFILLED,
  TOGGLE_PURCHASE_MODAL,
  GET_ASPERA_ENTITLEMENT_ITEMS_FULFILLED,
  GET_ASPERA_ENTITLEMENT_ITEMS_PENDING,
  GET_ASPERA_ENTITLEMENT_QUOTE_PENDING,
  GET_ASPERA_ENTITLEMENT_QUOTE_FULFILLED
} from "@actions/settings/aspera_entitlements"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_ASPERA_ENTITLEMENTS_PENDING:
      return {
        ...state,
        status: "PENDING"
      }

    case GET_ASPERA_ENTITLEMENTS_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_ASPERA_ENTITLEMENTS_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.list,
        currentPage: parseInt(action.payload.current_page),
        totalResults: parseInt(action.payload.total_results)
      }

    case SELECT_ENTITLEMENT:
      return {
        ...state,
        selectedEntitlement: action.payload.entitlement
      }

    case PURCHASE_ASPERA_ENTITLEMENT_PENDING:
      return {
        ...state,
        isSubmitting: true
      }

    case PURCHASE_ASPERA_ENTITLEMENT_FULFILLED:
      return {
        ...state,
        status: "READY",
        list: action.payload.list,
        currentPage: parseInt(action.payload.current_page),
        totalResults: parseInt(action.payload.total_results),
        isSubmitting: false,
        showPurchaseModal: false
      }

    case TOGGLE_PURCHASE_MODAL:
      return {
        ...state,
        showPurchaseModal: !state.showPurchaseModal
      }

    case GET_ASPERA_ENTITLEMENT_ITEMS_PENDING:
      return {
        ...state,
        items: [],
        isLoadingItems: true,
        selectedEntitlement: null
      }

    case GET_ASPERA_ENTITLEMENT_ITEMS_FULFILLED:
      return {
        ...state,
        items: action.payload,
        isLoadingItems: false,
        selectedEntitlement: {
          value: action.payload[0].code,
          label: action.payload[0].name
        }
      }

    case GET_ASPERA_ENTITLEMENT_QUOTE_PENDING:
      return {
        ...state,
        quote: {
          ...state.quote,
          status: "PENDING",
          subtotal: 0,
          tax: 0,
          total: 0
        }
      }

    case GET_ASPERA_ENTITLEMENT_QUOTE_FULFILLED:
      return {
        ...state,
        quote: {
          ...state.quote,
          status: "READY",
          subtotal: action.payload.subtotal,
          tax: action.payload.tax_total,
          total: action.payload.total
        }
      }

    default:
      return state
  }
}
