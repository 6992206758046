import {
  GET_PAYMENT_METHODS,
  DELETE_CREDIT_CARD,
  UPDATE_NEW_PAYMENT_METHOD,
  ADD_CREDIT_CARD,
  ADD_GIFT_CARD,
  RETRY_PAYMENT,
  RESET_NEW_PAYMENT_METHOD,
  GET_UPCOMING_INVOICE,
  UPDATE_DEFAULT_CREDIT_CARD
} from "@actions/settings/billing"
import { get, del, post, put } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"
import { setStripeKey, getStripeToken } from "./stripe"

/**
 * Gets the upcoming subscription invoice from Stripe
 *
 * @returns {Promise}
 */
export const getUpcomingInvoice = () => dispatch => dispatch(get(GET_UPCOMING_INVOICE, `billing/upcoming-invoice`))

/**
 * Fetches user/organization payment methods from the server
 *
 * @returns {Promise}
 */

export const getPaymentMethods = () => dispatch => dispatch(get(GET_PAYMENT_METHODS, `settings/paymentmethods`))

/**
 * Deletes user/organization credit cards from the server
 *
 * @returns {Promise}
 */

export const deleteCreditCard = _id => (dispatch, getState) =>
  dispatch(
    del(
      DELETE_CREDIT_CARD,
      `settings/creditcards`,
      { cardID: _id },
      () => dispatch(addGlobalMessage("Credit card deleted!", "success")),
      () => dispatch(addGlobalMessage("An error occurred", "error"))
    )
  )

/**
 * Adds a user/organization credit card
 *
 * @param {String} [token] The token response from Stripe.card.createToken()
 *
 * @returns {Promise}
 */

const addCreditCard = token => (dispatch, getState) =>
  dispatch(
    post(
      ADD_CREDIT_CARD,
      "billing/cards",
      { token },
      () => dispatch(addGlobalMessage("Credit card added!", "success")),
      error => dispatch(addGlobalMessage(`An error occurred: ${error.message}`, "error"))
    )
  )

/**
 * Adds a user/organization gift card
 *
 * @param {String} [code] A 15 digit code
 * @param {String} [pin] A 4 digit pin
 *
 * @returns {Promise}
 */

const addGiftCard = (code, pin) => (dispatch, getState) =>
  dispatch(
    post(
      ADD_GIFT_CARD,
      "settings/giftcards/redeem",
      { code, pin },
      () => dispatch(addGlobalMessage("Gift card added!", "success")),
      () => dispatch(addGlobalMessage("An error occurred", "error"))
    )
  )

/**
 * Retries a failed storage payment
 *
 * @param {String} [invoiceID] The credit card id to use
 *
 * @returns {Promise}
 */

export const retryPayment = invoiceID => dispatch =>
  dispatch(
    put(
      RETRY_PAYMENT,
      `billing/storageplans/retry/${invoiceID}`,
      {},
      () => dispatch(addGlobalMessage("Payment successful!", "success")),
      () => dispatch(addGlobalMessage("Your payment was unsuccessful.", "error"))
    )
  )

/**
 * Sets a user/organization default credit card
 *
 * @param {String} [creditCardID]
 *
 * @returns {Function}
 */

export const setDefaultCreditCard = creditCardID => (dispatch, getState) => {
  dispatch(
    post(
      UPDATE_DEFAULT_CREDIT_CARD,
      `settings/creditcards/${creditCardID}/set-as-default`,
      {},
      () => dispatch(addGlobalMessage("Successfully set default credit card.", "success")),
      () => dispatch(addGlobalMessage("Setting the default credit card failed.", "error"))
    )
  )
}

/**
 * Updates settings.billing.new state
 *
 * @param {Object} [payload]
 *
 * @returns {Void}
 */

export const updateNewPaymentMethod = payload => dispatch => dispatch({ type: UPDATE_NEW_PAYMENT_METHOD, payload })

/**
 * Resets settings.billing.new to original state
 *
 * @returns {Void}
 */

export const resetNewPaymentMethod = () => dispatch => dispatch({ type: RESET_NEW_PAYMENT_METHOD })

/**
 * Submits a new payment method (credit card or gift card)
 *
 * @returns {Void}
 */

export const submitNewPaymentMethod = () => (dispatch, getState) => {
  const new_payment_method = getState().settings.billing.new
  if (new_payment_method.mode === "credit-card") {
    dispatch(validateCreditCard())
  } else {
    dispatch(validateGiftCard())
  }
}

/**
 * Validates a new gift card
 *
 * @returns {Void}
 */

const validateGiftCard = () => (dispatch, getState) => {
  const new_payment_method = getState().settings.billing.new
  const errors = ["gift_card_number", "gift_card_pin"].filter(key => !new_payment_method[key])
  dispatch(updateNewPaymentMethod({ errors }))
  if (!errors.length) {
    dispatch(addGiftCard(new_payment_method.gift_card_number, new_payment_method.gift_card_pin))
  }
}

/**
 * Validates a new credit card and creates a Stripe token
 *
 * @returns {Void}
 */

export const validateCreditCard = (callback = null, errorCallback = null) => (dispatch, getState) => {
  const new_payment_method = getState().settings.billing.new
  const errors = [
    "credit_card_cardholder_name",
    "credit_card_card_number",
    "credit_card_cvc",
    "credit_card_expiry"
  ].filter(key => !new_payment_method[key])
  const month = new_payment_method.credit_card_expiry.split("/")[0].trim()
  const year = new_payment_method.credit_card_expiry.split("/")[1]
            ? new_payment_method.credit_card_expiry.split("/")[1].trim()
            : ""
  if (errors.length) {
    return dispatch(updateNewPaymentMethod({ errors }))
  }
  dispatch(updateNewPaymentMethod({ status: "PENDING", stripe_error: "", errors: [] }))
  return setStripeKey()
    .then(() => {
      const payload = {
        name: new_payment_method.credit_card_cardholder_name,
        number: new_payment_method.credit_card_card_number,
        cvc: new_payment_method.credit_card_cvc,
        exp_month: month,
        exp_year: year
      }
      return getStripeToken(payload.name, payload.number, payload.cvc, payload.exp_month, payload.exp_year)
        .then(token => {
          if (typeof callback === 'function') {
            callback(token)
          }
          else {
            dispatch(addCreditCard(token))
          }
        })
        .catch(error => {
          if (typeof errorCallback === 'function') {
            errorCallback(error)
          }
          dispatch(
            updateNewPaymentMethod({
              status: "READY",
              stripe_error: error
            })
          )
        })
    })
    .catch(err => dispatch(updateNewPaymentMethod({ status: "ERROR" })))
}

export const changeBillingInterval = interval => dispatch => {
  window.alert("Not yet implemented")
}