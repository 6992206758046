import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const RedirectWithStatus = ({ from, to, status, exact }) => (
  <Route
    render={({ staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
      if (staticContext) staticContext.status = status
      return <Redirect exact={exact} from={from} to={to}/>
    }}/>
)

RedirectWithStatus.defaultProps = {
  exact: true
}

RedirectWithStatus.propTypes = {
  from: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  status: PropTypes.number.isRequired,
  exact: PropTypes.bool
}

export default RedirectWithStatus
