import { GET_COUNTRIES, GET_USER_LOCATION, UPDATE_UTILITIES } from "@actions/utilities"
import { get } from "@src/helpers/fetch"

export const getCountries = () => (dispatch, getState) => {
  if (getState().utilities.countries.status !== "READY") {
    dispatch(get(GET_COUNTRIES, "utilities/countries"))
  }
}

export const getUserLocation = () => dispatch => dispatch(get(GET_USER_LOCATION, "users/location"))

export const updateUtilities = (key, value) => dispatch => dispatch({ type: UPDATE_UTILITIES, key, value })

export const confirmAction = text =>
  new Promise((resolve, reject) => {
    if (window.confirm(text)) {
      resolve()
    } else {
      reject()
    }
  })
