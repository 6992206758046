import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import dayjs from "dayjs"
import { getAsperaEntitlements } from "@api/settings/aspera_entitlements"
import { Status, Table } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/invoices.empty.inline.svg"
import humanFileSize from "@helpers/humanFileSize"
import DownloadUsage from "./download_usage"

const Entitlements = ({ getAsperaEntitlements, asperaEntitlements }) => {
  const [showUsage, setShowUsage] = useState(null)
  useDidMount(() => {
    getAsperaEntitlements()
  })
  return (
    <div data-test-id='aspera-entitlements'>
      <h4>Aspera Entitlements</h4>
      <Status pending={asperaEntitlements.status === "PENDING"} error={asperaEntitlements.status === "ERROR"}>
        <Table
          widths={["auto", 150, 150, 250, 65]}
          className='mt2'
          header={{
            columns: [{ text: "Name" }, { text: "Purchased" }, { text: "Expires" }, { text: "Usage" }, { text: "" }]
          }}
          body={{
            data: asperaEntitlements.list,
            row: {
              compact: true,
              render: [
                data => data.name,
                data => dayjs.utc(data.created_at).format("MMM D, YYYY"),
                data => dayjs.utc(data.expires_at).format("MMM D, YYYY"),
                data => <Usage data={data}/>,
                data => <div className='material-icons pointer' onClick={() => setShowUsage(data)}>list</div> 
              ]
            },
            empty: {
              icon: <Icon/>,
              title: "No entitlements",
              text: "Your Aspera entitlement purchase history will show here."
            }
          }}
          paginate={{
            currentPage: asperaEntitlements.currentPage,
            pageCount: Math.ceil(asperaEntitlements.totalResults / 5),
            onPageChange: page => getAsperaEntitlements(page)
          }}/>
      </Status>
      {showUsage &&
        <DownloadUsage
          asperaDownloads={showUsage.aspera_downloads}
          asperaDownloadsUsed={showUsage.downloads}
          asperaEntitlement={showUsage}
          onClose={() => setShowUsage(false)}/>}
    </div>
  )
}

const Usage = ({ data }) => <div
  className='col-12 pr2'
  style={{
    paddingTop: '5px'
  }}>
  <div
    style={{
      position: 'relative',
      height: '10px',
      width: '100%',
      background: '#ededed',
      borderRadius: '5px',
      overflow: 'hidden'
    }}>
    <div
      style={{
        background: 'linear-gradient(180deg, #fb0f3b 0%, #e30a33 97.83%)',
        width: `${(data.bytes_used / data.bytes_total) * 100}%`,
        height: '100%'
      }}/>
  </div>
  <div
    style={{
      fontSize: '0.75em'
    }}>
    <strong>{humanFileSize(data.bytes_used)}</strong> used of{" "}
    <strong>{humanFileSize(data.bytes_total)}</strong>
  </div>
</div>

Entitlements.propTypes = {
  asperaEntitlements: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired
  }).isRequired,
  getAsperaEntitlements: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  asperaEntitlements: state.settings.aspera_entitlements
})

const mapDispatchToProps = dispatch => ({
  getAsperaEntitlements: bindActionCreators(getAsperaEntitlements, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entitlements)
