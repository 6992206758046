import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { submitNewPaymentMethod } from "@api/settings/billing/index"

const Footer = ({ submitNewPaymentMethod }) => (
  <div className='right-align mt3'>
    <button className='cs-button' onClick={submitNewPaymentMethod}>Add Payment Method</button>
  </div>
)

Footer.propTypes = {
  submitNewPaymentMethod: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  submitNewPaymentMethod: bindActionCreators(submitNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
