import React from "react"
import { Link, withRouter } from "react-router-dom"

const map = {
  signup: {
    to: 'login',
    label: 'Already have an account?',
    button: 'Log in'
  },
  login: {
    to: 'signup',
    label: "Don't have an account?",
    button: 'Sign up'
  },
  payment: {
    to: 'login',
    label: 'Already have an account?',
    button: 'Log in'
  },
  forgot: {
    to: 'login',
    label: 'Remember your password?',
    button: 'Log in'
  },
  mfa: {
    to: 'login',
    button: 'Return to login page'
  }
}

const Toggle = ({ to, label, button, location }) => {
  const mode = location.pathname.split("/").pop()
  if (!map[mode]) {
    return null
  }
  return (
    <div className='bg-white rounded p3 mb2 box-shadow flex items-center justify-center'>
      {map[mode].label}
      <Link
        data-test-id='account-navigating-link'
        to={{
          pathname: map[mode].to,
          search: location.search
        }}>
        <button
          style={{
            height: '23px',
            color: '#fb0f3b'
          }}
          className='cs-button link ml1'>
          {map[mode].button}
        </button>
      </Link>
    </div>
  )
}

export default withRouter(Toggle)
