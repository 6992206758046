import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import Helmet from "react-helmet"
import { Status } from "@bitcine/cs-theme"
import { useDispatch, useSelector } from "react-redux"
import { getLoginType } from "@api/auth"
import ExternalLogin from './external_login'
import InternalLogin from './internal_login'

const Login = () => {

  const externalAuthentication = useSelector(state => state.auth.externalAuthentication)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLoginType())
  }, [])
  
  return (
    <>
      <Helmet title='CineSend | Log In' />
      <Status pending={externalAuthentication.status !== "FULFILLED"}>
        {externalAuthentication.external_authentication_enabled ? <ExternalLogin info={externalAuthentication} /> : <InternalLogin />}
      </Status>
    </>
  )
}


export default withRouter(Login)