import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getTeamRoles } from "@api/settings/roles"
import { getOrganization } from "@api/settings/organization"
import { useDidMount } from "rooks"
import Helmet from "react-helmet"
import Header from "./components/header"
import List from "./components/list"
import Edit from "./components/edit"

const Roles = ({ getTeamRoles, showEdit, getOrganization }) => {
  useDidMount(() => {
    getTeamRoles()
    getOrganization()
  })
  return (
    <div>
      <Helmet title='CineSend | Roles'/>
      <div className='bg-white rounded box-shadow p2'>
        <Header/>
        <List/>
      </div>
      {showEdit && <Edit/>}
    </div>
  )
}

Roles.propTypes = {
  getTeamRoles: PropTypes.func.isRequired,
  showEdit: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  showEdit: state.settings.roles.edit.show
})

const mapDispatchToProps = dispatch => ({
  getTeamRoles: bindActionCreators(getTeamRoles, dispatch),
  getOrganization: bindActionCreators(getOrganization, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Roles)
