import React, { useEffect } from "react"
import { Status } from "@bitcine/cs-theme"
import { withRouter } from "react-router-dom"
import { useDispatch } from "react-redux"
import { externalLogin } from "@api/account"


const AuthCallback = ({ location }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const code = params.get("code")
    const stateID = params.get("state")
    dispatch(externalLogin(code, stateID))
  }, [])

  return (
    <Status pending={true} />
  )
}

export default withRouter(AuthCallback)
