import {
  GET_BRANDING_PENDING,
  GET_BRANDING_FULFILLED,
  UPDATE_BRANDING,
  SAVE_BRANDING_PENDING,
  SAVE_BRANDING_FULFILLED,
  SAVE_FONT_FULFILLED,
  SAVE_FONT_PENDING
} from "@actions/settings/branding"
import hash from "object-hash"
import INITIAL_STATE from "./config"

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_BRANDING_PENDING:
      return {
        ...state
      }
    case GET_BRANDING_FULFILLED:
      return {
        ...state,
        status: "READY",
        items: action.payload,
        initHash: hash(action.payload),
        newHash: hash(action.payload)
      }
    case UPDATE_BRANDING:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.key]: action.payload.value
        },
        newHash: hash({
          ...state.items,
          [action.payload.key]: action.payload.value
        })
      }
    case SAVE_BRANDING_PENDING:
      return {
        ...state,
        status: "SAVING"
      }
    case SAVE_BRANDING_FULFILLED:
      return {
        ...state,
        status: "READY",
        initHash: hash(action.payload),
        newHash: hash(action.payload)
      }
    case SAVE_FONT_PENDING:
      return {
        ...state,
        isUploadingFont: true
      }
    case SAVE_FONT_FULFILLED:
      return {
        ...state,
        status: "READY",
        initHash: hash(action.payload),
        newHash: hash(action.payload),
        items: action.payload,
        isUploadingFont: false
      }
    default:
      return state
  }
}
