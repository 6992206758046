import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import { getPaymentMethods, updateNewPaymentMethod } from "@api/settings/billing/index"
import { Status, Empty } from "@bitcine/cs-theme"
import { ReactComponent as Icon } from "@src/media/icons/payment-methods.empty.inline.svg"
import Card from "./card/index"
import Add from "./add/index"

const PaymentMethods = ({ getPaymentMethods, billing, updateNewPaymentMethod, defaultCreditCardID }) => {
  useDidMount(() => {
    getPaymentMethods()
  })
  const cards = [
    ...billing.credit_cards.map(card => ({ ...card, type: "credit-card" })),
    ...billing.gift_cards.map(card => ({ ...card, type: "gift-card" }))
  ]
  return (
    <div className='bg-white rounded box-shadow p2 mb2'>
      <div className='flex items-center justify-between'>
        <h4>Payment Methods</h4>
        <button className='cs-button white small' style={{width: '180px'}} onClick={() => updateNewPaymentMethod({ show: true })}>
          Add Payment Method
        </button>
      </div>
      <Status pending={billing.status === "PENDING"} error={billing.status === "ERROR"}>
        {cards.length === 0 ? (
          <Empty
            icon={<Icon/>}
            title='No Payment Methods'
            text='Gift cards and credit cards you add to your account will show here'/>
        ) : (
          <ul
            style={{
              marginLeft: '-0.5em',
              marginRight: '-0.5em'
            }}
            className='block sm-flex flex-wrap items-start mt2 p0 list-style-none'>
            {cards.map(card => (
              <Card
                key={card._id}
                card={card}
                isDefault={card.is_default}
                isDeleting={billing.deleting_credit_card_id === card._id}/>
            ))}
          </ul>
        )}
        {billing.new.show && <Add allow={["gift-card", "credit-card"]}/>}
      </Status>
    </div>
  )
}

PaymentMethods.propTypes = {
  billing: PropTypes.shape({
    status: PropTypes.string.isRequired,
    credit_cards: PropTypes.array.isRequired,
    gift_cards: PropTypes.array.isRequired,
    deleting_credit_card_id: PropTypes.string.isRequired,
    new: PropTypes.shape({
      show: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  defaultCreditCardID: PropTypes.string
}

const mapStateToProps = state => ({
  billing: state.settings.billing,
  defaultCreditCardID: state.user.default_credit_card_id
})

const mapDispatchToProps = dispatch => ({
  getPaymentMethods: bindActionCreators(getPaymentMethods, dispatch),
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethods)
