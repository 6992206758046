import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateTeamSettings, updateNewUser } from "@api/settings/team"

const Header = ({ updateTeamSettings, updateNewUser, search }) => (
  <div className='flex items-center'>
    <h4>Users</h4>
    <div className='flex items-center justify-end flex-auto'>
      <input
        value={search}
        placeholder='Search for a user...'
        className='cs-input'
        onChange={e => updateTeamSettings({ search: e.target.value })}/>
      <button onClick={() => updateNewUser({ show: true })} className='ml2 cs-button'>
        Add Team Members
      </button>
    </div>
  </div>
)

Header.propTypes = {
  updateTeamSettings: PropTypes.func.isRequired,
  updateNewUser: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  search: state.settings.team.search
})

const mapDispatchToProps = dispatch => ({
  updateTeamSettings: bindActionCreators(updateTeamSettings, dispatch),
  updateNewUser: bindActionCreators(updateNewUser, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
