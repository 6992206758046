import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useDidMount } from "rooks"
import { updateOrganization, saveOrganization } from "@api/settings/organization"
import { getTeamMembers } from "@api/settings/team"
import { getCountries } from "@api/utilities/"
import Select from "@src/components/select/"
import Logo from "./logo"
import Links from "./links"
import ExternalAuth from "./external_auth"

const Form = ({ getCountries, countries, getTeamMembers, teamList, organization, user, updateOrganization, saveOrganization }) => {
  useDidMount(() => {
    getCountries()
    getTeamMembers()
  })
  return (
    <React.Fragment>
      <div className='box-shadow rounded p2 bg-white'>
        <h4 className='mb2'>{organization.name} Profile</h4>
        <div>
          <div className='flex items-center border-bottom border-gray-5 pb2 mb2'>
            <Logo/>
            <div className='pl2 flex-auto'>
              <label className='cs-label' htmlFor='organization-name'>Organization Name</label>
              <input
                id='organization-name'
                value={organization.name}
                className={"cs-input col-12 block " + (!organization.name ? "error" : "")}
                onChange={e => updateOrganization({ name: e.target.value })}
                onBlur={e => {
                  if (e.target.value) {
                    saveOrganization("name", e.target.value)
                  }
                }} />
              {!organization.name && <div className='red'>Organization name is required</div>}
              <label htmlFor='organization-about' className='mt2 cs-label'>
                About
              </label>
              <textarea
                id='organization-about'
                value={organization.about || ""}
                className='cs-textarea col-12 block'
                onChange={e => updateOrganization({ about: e.target.value })}
                onBlur={e => saveOrganization("about", e.target.value)} />
            </div>
          </div>
          <div className='flex flex-wrap items-center'>
            <div className='col-4 pr2'>
              <label className='cs-label' htmlFor='organization-owner'>Owner</label>
              <Select
                id='organization-owner'
                isDisabled={organization.owner_id !== user._id}
                options={teamList}
                isLoading={!teamList.length}
                value={teamList.find(({ value }) => value === organization.owner_id)}
                clearable={false}
                className='col-12 mr2'
                onChange={user => {
                  updateOrganization({ owner_id: user.value })
                  saveOrganization("owner_id", user.value)
                }} />
            </div>
            <div className='col-4 pr2'>
              <label className='cs-label' htmlFor='organization-city'>City</label>
              <input
                id='organization-city'
                value={organization.city || ""}
                className='cs-input col-12 block'
                onChange={e => updateOrganization({ city: e.target.value })}
                onBlur={e => saveOrganization("city", e.target.value)} />
            </div>
            <div className='col-4'>
              <label className='cs-label' htmlFor='organization-country'>Country</label>
              <Select
                id='organization-country'
                options={countries.list}
                isLoading={countries.status !== "READY"}
                value={countries.list.find(({ country_code }) => country_code === organization.country_code)}
                clearable={false}
                className='col-12 mr2'
                onChange={country => {
                  updateOrganization({ country_code: country.value })
                  saveOrganization("country_code", country.value)
                }} />
            </div>
          </div>
        </div>
      </div>
      <Links />
      {organization.external_authentication_type === 'openidconnect' && <ExternalAuth />}
    </React.Fragment>
  )
}

Form.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  updateOrganization: PropTypes.func.isRequired,
  saveOrganization: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  countries: PropTypes.shape({
    status: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired
  }).isRequired,
  getTeamMembers: PropTypes.func.isRequired,
  teamList: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.settings.organization,
  countries: state.utilities.countries,
  teamList: state.settings.team.list.map(user => ({ label: user.email, value: user._id }))
})

const mapDispatchToProps = dispatch => ({
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch),
  getCountries: bindActionCreators(getCountries, dispatch),
  getTeamMembers: bindActionCreators(getTeamMembers, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
