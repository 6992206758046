import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status } from "@bitcine/cs-theme"
import { updateMFA, getMfaQRCode, validateEnableMfaQrCode, disableMfa } from "@api/settings/security"
import { ReactComponent as Google } from "@src/media/mfa/google.svg"
import { ReactComponent as Apple } from "@src/media/mfa/apple.svg"

const MFA = ({ mfa, user, updateMFA, getMfaQRCode, validateEnableMfaQrCode, disableMfa }) => {
  if (!user.mfa || !user.mfa.can_be_enabled) return null
  return (
    <div className='box-shadow rounded p2 bg-white col-12'>
      <h4 className='mb2'>Two-Factor with Google Authenticator</h4>
      {user.mfa.is_enabled ? (
        <div className='center py3'>
          <span
            style={{
              fontSize: '5em'
            }}
            className='material-icons green'>
            check_circle
          </span>
          <h4 className='muted'>Enabled</h4>
          <button onClick={disableMfa} className='cs-button white small mt2'>
            Disable Two-Factor
          </button>
        </div>
      ) : !user.mfa.is_enabled && !mfa.enabling ? (
        <div className='center py3'>
          <span
            style={{
              fontSize: '5em',
              color: '#e0e3e4'
            }}
            className='material-icons'>
            lock_open
          </span>
          <h4 className='muted'>Not Enabled</h4>
          <button
            onClick={() => {
              updateMFA({ enabling: true })
              getMfaQRCode()
            }}
            className='cs-button white small mt2'>
            Enable Two-Factor
          </button>
        </div>
      ) : (
        <Status
          pending={mfa.code_status === "PENDING" || mfa.disabling_status === "PENDING"}
          error={mfa.code_status === "ERROR" || mfa.disabling_status === "ERROR"}>
          <form>
            <div className='center'>
              <div dangerouslySetInnerHTML={{ __html: mfa.qr_code }}/>
            </div>
            <p>
              To enable Two-Factor security, scan the QR above with the Google Authenticator App on your mobile phone and type the code
              that is generated below.
            </p>
            <div className='center'>
              <a
                href='https://itunes.apple.com/ca/app/google-authenticator/id388497605'
                target='_blank'
                className='mx1'
                rel='noopener noreferrer'>
                <Apple
                  style={{
                    width: '100px'
                  }}/>
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA'
                target='_blank'
                className='mx1'
                rel='noopener noreferrer'>
                <Google
                  style={{
                    width: '100px'
                  }}/>
              </a>
            </div>
            <div className='mt2 pt2 border-top border-gray-5'>
              <label className='block col-12' htmlFor='code'>
                Generated Code
              </label>
              <input
                id='code'
                autoFocus
                value={mfa.code}
                max='6'
                placeholder='123456'
                className='cs-input block col-12'
                onChange={e => {
                  if (e.target.value.length > 6) return
                  else {
                    updateMFA({ code: e.target.value })
                  }
                }}/>
              {mfa.error && <div className='pt1 red'>{mfa.error}</div>}
              <div className='mt2 right-align'>
                <button
                  style={{
                    width: '80px'
                  }}
                  onClick={e => {
                    e.preventDefault()
                    validateEnableMfaQrCode()
                  }}
                  disabled={mfa.status === "PENDING"}
                  className={"cs-button small " + (mfa.status === "PENDING" ? "pending" : "")}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Status>
      )}
    </div>
  )
}

MFA.propTypes = {
  updateMFA: PropTypes.func.isRequired,
  getMfaQRCode: PropTypes.func.isRequired,
  validateEnableMfaQrCode: PropTypes.func.isRequired,
  disableMfa: PropTypes.func.isRequired,
  mfa: PropTypes.shape({
    enabling: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    code_status: PropTypes.string.isRequired,
    disabling_status: PropTypes.string.isRequired,
    qr_code: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    mfa: PropTypes.shape({
      can_be_enabled: PropTypes.bool,
      is_enabled: PropTypes.bool
    })
  }).isRequired
}

const mapStateToProps = (state, ownProps) => ({
  mfa: state.settings.security.mfa,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  updateMFA: bindActionCreators(updateMFA, dispatch),
  getMfaQRCode: bindActionCreators(getMfaQRCode, dispatch),
  validateEnableMfaQrCode: bindActionCreators(validateEnableMfaQrCode, dispatch),
  disableMfa: bindActionCreators(disableMfa, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MFA)
