export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_METHODS_PENDING = "GET_PAYMENT_METHODS_PENDING"
export const GET_PAYMENT_METHODS_FAILED = "GET_PAYMENT_METHODS_REJECTED"
export const GET_PAYMENT_METHODS_FULFILLED = "GET_PAYMENT_METHODS_FULFILLED"

export const DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD"
export const DELETE_CREDIT_CARD_PENDING = "DELETE_CREDIT_CARD_PENDING"
export const DELETE_CREDIT_CARD_FAILED = "DELETE_CREDIT_CARD_REJECTED"
export const DELETE_CREDIT_CARD_FULFILLED = "DELETE_CREDIT_CARD_FULFILLED"

export const UPDATE_NEW_PAYMENT_METHOD = "UPDATE_NEW_PAYMENT_METHOD"

export const ADD_CREDIT_CARD = "ADD_CREDIT_CARD"
export const ADD_CREDIT_CARD_PENDING = "ADD_CREDIT_CARD_PENDING"
export const ADD_CREDIT_CARD_FAILED = "ADD_CREDIT_CARD_REJECTED"
export const ADD_CREDIT_CARD_FULFILLED = "ADD_CREDIT_CARD_FULFILLED"

export const ADD_GIFT_CARD = "ADD_GIFT_CARD"
export const ADD_GIFT_CARD_PENDING = "ADD_GIFT_CARD_PENDING"
export const ADD_GIFT_CARD_FAILED = "ADD_GIFT_CARD_REJECTED"
export const ADD_GIFT_CARD_FULFILLED = "ADD_GIFT_CARD_FULFILLED"

export const RETRY_PAYMENT = "RETRY_PAYMENT"
export const RETRY_PAYMENT_PENDING = "RETRY_PAYMENT_PENDING"
export const RETRY_PAYMENT_FAILED = "RETRY_PAYMENT_REJECTED"
export const RETRY_PAYMENT_FULFILLED = "RETRY_PAYMENT_FULFILLED"

export const RESET_NEW_PAYMENT_METHOD = "RESET_NEW_PAYMENT_METHOD"

export const GET_UPCOMING_INVOICE = "GET_UPCOMING_INVOICE"
export const GET_UPCOMING_INVOICE_PENDING = "GET_UPCOMING_INVOICE_PENDING"
export const GET_UPCOMING_INVOICE_FAILED = "GET_UPCOMING_INVOICE_REJECTED"
export const GET_UPCOMING_INVOICE_FULFILLED = "GET_UPCOMING_INVOICE_FULFILLED"

export const UPDATE_DEFAULT_CREDIT_CARD = "UPDATE_DEFAULT_CREDIT_CARD"
export const UPDATE_DEFAULT_CREDIT_CARD_PENDING = "UPDATE_DEFAULT_CREDIT_CARD_PENDING"
export const UPDATE_DEFAULT_CREDIT_CARD_FAILED = "UPDATE_DEFAULT_CREDIT_CARD_FAILED"
export const UPDATE_DEFAULT_CREDIT_CARD_FULFILLED = "UPDATE_DEFAULT_CREDIT_CARD_FULFILLED"