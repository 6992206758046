import React from "react"
import PropTypes from "prop-types"
import { Empty } from "@bitcine/cs-theme"

class Error extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }
  componentDidCatch(error, info) {
    this.setState({ error: true })
    window.trackJs.console.error(error)
  }
  render() {
    if (this.state.error) {
      return (
        <div className='text-center col-12'>
          <Empty icon='error' title='An Error Occurred' text='This error has been logged with our team'/>
        </div>
      )
    } else return this.props.children
  }
}

Error.propTypes = {
  children: PropTypes.node.isRequired
}

export default Error
