import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal } from "@bitcine/cs-theme"
import { closeEditRole, updateEditRole, saveRole } from "@api/settings/roles"
import Form from "./form"

const Edit = ({ editRole, apps, closeEditRole, updateEditRole, saveRole }) => (
  <Modal open={true} title='Edit Role' onClose={closeEditRole}>
    <div>
      <Form
        form={editRole.role}
        apps={apps}
        errors={editRole.errors}
        onUpdate={(key, value) => updateEditRole({ role: { ...editRole.role, [key]: value } })}/>
      <div className='mt3 right-align'>
        <button
          disabled={editRole.status === "PENDING" || editRole.role.name == null || editRole.role.name === "" }
          className={`${editRole.status === "PENDING" ? "pending" : ""} cs-button`}
          onClick={() => saveRole(editRole.role)}>
          Save Role
        </button>
      </div>
    </div>
  </Modal>
)

Edit.propTypes = {
  editRole: PropTypes.shape({
    status: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired,
    role: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      permissions: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  closeEditRole: PropTypes.func.isRequired,
  updateEditRole: PropTypes.func.isRequired,
  saveRole: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  editRole: state.settings.roles.edit,
  apps: state.user.app_permissions
})

const mapDispatchToProps = dispatch => ({
  closeEditRole: bindActionCreators(closeEditRole, dispatch),
  updateEditRole: bindActionCreators(updateEditRole, dispatch),
  saveRole: bindActionCreators(saveRole, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit)
