import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import styled from 'styled-components'
import { activateApplication } from "@api/settings/organization"
import { Link } from "react-router-dom"
import { ButtonDropdown } from "@bitcine/cs-theme"
import AppSwitcher from "@bitcine/cinesend-app-switcher"
import { ReactComponent as Logo } from "@src/media/logos/cinesend/blue.inline.svg"

const Bar = styled.div`
  position: fixed;
  height: 56px;
  width: 100%;
  box-shadow: 0 1px 8px 0 rgba(146, 153, 159, 0.21);
`

const Navigation = ({ apps, authenticated, name, push, activateApplication, activating }) => (
  <Bar
    data-test-id={"navigation-" + (authenticated ? "authenticated" : "guest")}
    className='bg-white relative z1'>
    <div
      style={{
        height: '56px'
      }}
      className='flex items-center justify-between px3'>
      <div className='flex items-center'>
        {authenticated && (
          <div
            style={{
              marginLeft: '-12px'
            }}
            className='mr2 flex items-center justify-center'>
            {activating ? (
              <div className='spinner spinner-small'/>
            ) : (
              <AppSwitcher
                apps={Object.keys(apps)
                  .filter(key => !apps[key].hide)
                  .reduce((all, key) => ({ ...all, [key]: apps[key] }), {})}
                activeApplicationKey='accounts'
                onActivate={app => activateApplication(app.toLowerCase())}/>
            )}
          </div>
        )}
        <Link to='/' className='flex items-center'>
          <Logo
            style={{
              width: '100px',
              height: '40px'
            }}/>
          <strong className='ml2'>Accounts</strong>
        </Link>
      </div>
      {authenticated && (
        <ButtonDropdown
          button={{
            className: "link",
            text: (
              <div className='flex items-center'>
                {name}
                <span className='material-icons ml2'>keyboard_arrow_down</span>
              </div>
            )
          }}
          dropdown={{
            content: [
              {
                text: "Log Out",
                icon: "exit_to_app",
                onClick: () => push("/logout")
              }
            ]
          }}/>
      )}
    </div>
  </Bar>
)

Navigation.defaultProps = {
  apps: {},
  name: ""
}

Navigation.propTypes = {
  apps: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  name: PropTypes.string,
  push: PropTypes.func,
  activateApplication: PropTypes.func.isRequired,
  activating: PropTypes.bool.isRequired
}

const mapDispatchToProps = dispatch => ({
  activateApplication: bindActionCreators(activateApplication, dispatch)
})

const mapStateToProps = state => ({
  apps: state.user.app_permissions,
  name: state.user.full_name,
  authenticated: state.auth.status === "AUTHENTICATED",
  push: state.utilities.history ? state.utilities.history.push : () => null,
  activating: state.settings.organization.activation_status === "PENDING"
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)
