import {
  CREATE_SUBSCRIPTION,
  SET_STORAGE_AMOUNT,
  CANCEL_SUBSCRIPTION
} from "@actions/settings/subscription"
import { post, del } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"

export const createSubscription = (interval) => (dispatch, getState) => {
  if (getState().settings.billing.credit_cards.length === 0) {
    dispatch(addGlobalMessage("Please add a default credit card before creating a subscription.", "error"))
    return
  }
  dispatch(
    post(
      CREATE_SUBSCRIPTION,
      `billing/subscriptions`,
      { interval },
      res => {
        if (res.success) {
          dispatch(addGlobalMessage("Subscription created!", "success"))
        }
        else {
          dispatch(addGlobalMessage(res.message, "error"))
        }
      },
      e => {
        dispatch(addGlobalMessage("We could not create your subscription, please try again. If this message persists, contact customer support.", "error"))
      }
    )
  )
}

export const updateStorage = amount => (dispatch, getState) => {
  if (getState().settings.billing.credit_cards.length === 0) {
    dispatch(addGlobalMessage("Please add a default credit card before modifying storage.", "error"))
    return
  }
  dispatch(
    post(
      SET_STORAGE_AMOUNT,
      "billing/storage",
      { amount },
      res => {
        if (res.success) {
          dispatch(addGlobalMessage(`Total storage allowance updated to ${amount + 1}TB!`, "success"))
        }
        else {
          dispatch(addGlobalMessage(res.message, "error"))
        }
      },
      e => {
        dispatch(addGlobalMessage("We could not modify your storage, please try again. If this message persists, contact customer support.", "error"))
      }
    )
  )
}

export const cancelSubscription = () => (dispatch, getState) =>
  dispatch(
    del(
      CANCEL_SUBSCRIPTION,
      "billing/subscriptions",
      null,
      res => {
        if (res.success) {
          dispatch(addGlobalMessage("Subscription cancelled.", "success"))
        }
        else {
          dispatch(addGlobalMessage(res.message, "error"))
        }
      },
      e => {
        dispatch(addGlobalMessage("We could not cancel your subscription, please try again. If this message persists, contact customer support.", "error"))
      }
    )
  )
