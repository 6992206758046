import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { saveFont, setToPending } from "@api/settings/branding"
import { addGlobalMessage } from "@api/global_messages"
import { post } from "@src/helpers/fetch"

const UPLOAD_FONT = 'UPLOAD_FONT'

const FontUploader = ({ organization, branding, saveFont, setToPending, onUploadStart, post, addGlobalMessage }) => {
  const onDrop = files => {
    const file = files[0]
    post(
      UPLOAD_FONT,
      `organizations/${organization._id}/branding/upload-font`,
      { fileName: file.name },
      res => {
        setToPending()
        onUploadStart()
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', res.signedUrl);
        xhr.onreadystatechange = function() {
          if (xhr.readyState !== 4) return
          if (xhr.status === 200) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.onload = () => {
              saveFont(file.name, res.fontUrl)
            }
          }
          else {
            addGlobalMessage("An error occurred uploading your font file. Please try again.", "error")
          }
        };
        xhr.send(file)
      }
    )
  }
  return branding.isUploadingFont ? (
    <div>
      <strong>&nbsp;</strong>
      <button className='cs-button' disabled>Uploading...</button>
    </div>
  ) : (
    <Dropzone multiple={false} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()}/>
          {isDragActive ? <div>Drop files here...</div> : <button className='cs-button'>Upload Font File</button>}
        </div>
      )}
    </Dropzone>
  )
}

FontUploader.propTypes = {
  onUploadStart: PropTypes.func,
  onUploadComplete: PropTypes.func,
  organization: PropTypes.object,
  saveFont: PropTypes.func,
  branding: PropTypes.object,
  setToPending: PropTypes.func,
  post: PropTypes.func,
  addGlobalMessage: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  saveFont: bindActionCreators(saveFont, dispatch),
  setToPending: bindActionCreators(setToPending, dispatch),
  post: bindActionCreators(post, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch)
})

const mapStateToProps = (state, ownProps) => ({
  organization: state.user.organization,
  branding: state.settings.branding
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FontUploader)
