import {
  GET_COUNTRIES_PENDING,
  GET_COUNTRIES_FAILED,
  GET_COUNTRIES_FULFILLED,
  GET_USER_LOCATION_FULFILLED,
  UPDATE_UTILITIES,
} from "@actions/utilities"

const initialState = {
  countries: {
    status: "PENDING",
    list: []
  },
  user_location: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_UTILITIES:
      return {
        ...state,
        [action.key]: action.value
      }

    case GET_COUNTRIES_PENDING:
      return {
        ...state,
        countries: {
          ...state.countries,
          status: "PENDING"
        }
      }
    case GET_COUNTRIES_FAILED:
      return {
        ...state,
        countries: {
          ...state.countries,
          status: "ERROR"
        }
      }

    case GET_COUNTRIES_FULFILLED:
      return {
        ...state,
        countries: {
          ...state.countries,
          status: "READY",
          list: action.payload
        }
      }
    case GET_USER_LOCATION_FULFILLED:
      return {
        ...state,
        user_location: action.payload
      }

    default:
      return state
  }
}
