import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Select from "@src/components/select/"

class CustomFontPicker extends React.Component {
  render() {
    const { branding, onChange, selected } = this.props
    const value =
      selected && !!branding.items.fonts.filter(font => font._id === selected)[0]
        ? {
          value: selected,
          label: branding.items.fonts.filter(font => font._id === selected)[0].name
        }
        : null
    const options = branding.items.fonts.map(font => {
      return {
        value: font._id,
        label: font.name
      }
    })
    return (
      <Select
        options={[
          {
            value: null,
            label: "-- None --"
          },
          ...options
        ]}
        value={value}
        className='col-12 mr2'
        onChange={option => {
          onChange(option.value)
        }}/>
    )
  }
}

CustomFontPicker.propTypes = {
  selected: PropTypes.string,
  organization: PropTypes.object.isRequired,
  branding: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  organization: state.user.organization,
  branding: state.settings.branding
})

export default connect(mapStateToProps)(CustomFontPicker)
