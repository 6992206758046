export const GET_SUBSCRIPTION_PRICE = "GET_SUBSCRIPTION_PRICE"
export const GET_SUBSCRIPTION_PRICE_PENDING = "GET_SUBSCRIPTION_PRICE_PENDING"
export const GET_SUBSCRIPTION_PRICE_FAILED = "GET_SUBSCRIPTION_PRICE_FAILED"
export const GET_SUBSCRIPTION_PRICE_FULFILLED = "GET_SUBSCRIPTION_PRICE_FULFILLED"

export const GET_TEAM_SEAT_CYCLE_PRICE = "GET_TEAM_SEAT_CYCLE_PRICE"
export const GET_TEAM_SEAT_CYCLE_PRICE_PENDING = "GET_TEAM_SEAT_CYCLE_PRICE_PENDING"
export const GET_TEAM_SEAT_CYCLE_PRICE_FULFILLED = "GET_TEAM_SEAT_CYCLE_PRICE_FULFILLED"
export const GET_TEAM_SEAT_CYCLE_PRICE_FAILED = "GET_TEAM_SEAT_CYCLE_PRICE_FAILED"

export const GET_STORAGE_CYCLE_PRICE = "GET_STORAGE_CYCLE_PRICE"
export const GET_STORAGE_CYCLE_PRICE_PENDING = "GET_STORAGE_CYCLE_PRICE_PENDING"
export const GET_STORAGE_CYCLE_PRICE_FULFILLED = "GET_STORAGE_CYCLE_PRICE_FULFILLED"
export const GET_STORAGE_CYCLE_PRICE_FAILED = "GET_STORAGE_CYCLE_PRICE_FAILED"

export const GET_STORAGE_PRORATED_PRICE = "GET_STORAGE_PRORATED_PRICE"
export const GET_STORAGE_PRORATED_PRICE_PENDING = "GET_STORAGE_PRORATED_PRICE_PENDING"
export const GET_STORAGE_PRORATED_PRICE_FULFILLED = "GET_STORAGE_PRORATED_PRICE_FULFILLED"
export const GET_STORAGE_PRORATED_PRICE_FAILED = "GET_STORAGE_PRORATED_PRICE_FAILED"