import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import dayjs from "dayjs"
import { ButtonDropdown } from '@bitcine/cs-theme'
// import Toggle from "@src/components/toggle/"
import { createSubscription, cancelSubscription } from "@api/settings/subscription"
import { retryPayment } from "@api/settings/billing/index"
import { getSubscriptionPrice } from '@api/prices'
import Storage from './storage'
import ChangeInterval from './change_interval'

// const YEARLY = 'yearly'
const MONTHLY = 'monthly'
const FREE = 'free'
const CANCELED = 'canceled'
const TRIALING = 'trialing'
const CANCEL_CONFIRMATION = 'Are you sure you want to cancel your subscription? Your account will be closed at the end of this billing cycle.'

const Subscription = ({ 
  user, 
  isEnterprise, 
  getSubscriptionPrice, 
  createSubscription, 
  cancelSubscription, 
  monthlyPrice, 
  yearlyPrice,
  requiresPayment, 
  invoiceToRetry,
  retryPayment
}) => {
  const showSubscribe = user.subscription.type === FREE || user.subscription.type === CANCELED
  const showCancel = user.subscription.type !== FREE && !user.subscription.stripe_subscription_ends_at
  const [showChangeInterval, setShowChangeInterval] = useState(null)
  useEffect(() => {
    getSubscriptionPrice()
  }, [getSubscriptionPrice])
  return (
    <div className='p2 bg-white rounded box-shadow mb2'>
      <div className='flex items-center justify-between'>
        <h4>Billing and Usage</h4>
        <div className='flex items-center'>
          {requiresPayment && <button className='cs-button small flex items-center' onClick={() => retryPayment(invoiceToRetry)}>
            <i className='material-icons pr1'>error</i>
            <div>Retry Payment</div>
          </button>}
          {(!isEnterprise && (showSubscribe || showCancel)) && <ButtonDropdown
            button={{
              className: 'white small ml1',
              text: showSubscribe && showCancel
                ? 'Modify or Cancel Subscription'
                : showCancel 
                  ? 'Cancel Subscription'
                  : 'Modify Subscription'
            }}
            dropdown={{
              content: [
                // {
                //   text: `Subscribe to Yearly${yearlyPrice}`,
                //   onClick: () => createSubscription(YEARLY),
                //   hide: user.subscription.interval === YEARLY && !user.subscription.stripe_subscription_ends_at
                // },
                {
                  text: `Subscribe to Monthly${monthlyPrice}`,
                  onClick: () => createSubscription(MONTHLY),
                  show: showSubscribe
                },
                {
                  text: 'Cancel Subscription',
                  onClick: () => {
                    if (window.confirm(CANCEL_CONFIRMATION)) {
                      cancelSubscription() 
                    }
                  },
                  show: showCancel
                }
              ].filter(opt => opt.show)
            }}/>}
        </div>
      </div>
      {user.subscription.stripe_plan_status && (
        <>
          {
            user.subscription.stripe_plan_status === CANCELED ?
            <div>
              You are not currently on an active subscription. Subscribe now to add storage to your account.
            </div> :
            <div>
              You are currently on a <b>{user.subscription.interval} subscription</b>.
              {user.subscription.stripe_subscription_ends_at ? (
                <span>
                  {" "}Your subscription will end on <b>{dayjs.utc(user.subscription.stripe_subscription_ends_at).format("MMM D, YYYY")}</b>.
                </span>
              ) : user.subscription.stripe_plan_status === TRIALING && (
                <span>
                  {" "}Your trial will end on <b>{user.subscription.stripe_trial_ends_at}</b>.
                </span>
              )}
            </div>
          }
        </>
      )}
      <Storage/>
      {showChangeInterval &&
        <ChangeInterval
          newInterval={showChangeInterval}
          closeModal={() => setShowChangeInterval(null)}/>}
    </div>
  )
}

Subscription.propTypes = {
  user: PropTypes.shape({}).isRequired,
  isEnterprise: PropTypes.bool.isRequired,
  monthlyPrice: PropTypes.string,
  yearlyPrice: PropTypes.string,
  requiresPayment: PropTypes.bool.isRequired,
  invoiceToRetry: PropTypes.string.isRequired,
  retryPayment: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user,
  isEnterprise: state.user?.organization?.is_enterprise || false,
  monthlyPrice: state.prices.subscription.price.monthly.interval_amount_formatted
    ? ` (${state.prices.subscription.price.monthly.interval_amount_formatted})`
    : '',
  yearlyPrice: state.prices.subscription.price.yearly.interval_amount_formatted
    ? ` (${state.prices.subscription.price.yearly.interval_amount_formatted})`
    : '',
  requiresPayment: state.user.is_pending_storage_payment,
  invoiceToRetry: state.user.failed_payment_invoice || null,
})

const mapDispatchToProps = dispatch => ({
  createSubscription: bindActionCreators(createSubscription, dispatch),
  cancelSubscription: bindActionCreators(cancelSubscription, dispatch),
  getSubscriptionPrice: bindActionCreators(getSubscriptionPrice, dispatch),
  retryPayment: bindActionCreators(retryPayment, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription)
