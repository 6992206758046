import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import styled from 'styled-components'

const Wrapper = styled.div`
  :hover {
    background: #f9f9f9;
  }
`

const Apps = ({ apps }) => (
  <section data-test-id='apps-section'>
    <Helmet title='CineSend | My Apps'/>
    <div className='box-shadow p2 rounded bg-white max-width-3'>
      <h4>My Apps</h4>
      <ul
        style={{
          marginLeft: '-0.5em',
          marginRight: '-0.5em'
        }}
        className='flex flex-wrap items-center list-style-none p0'>
        {Object.keys(apps)
          .filter(key => !apps[key].hide)
          .map(key => (
            <li key={key} className='col-6 px1 pt2'>
              <a
                target='_self'
                rel='noopener noreferrer'
                href={apps[key].url}
                data-test-id='apps-section-link'
                className=''>
                <Wrapper
                  className={
                    "p2 rounded border border-gray-5 flex items-center " + (key === "accounts" ? "bg-gray-6" : "")
                  }>
                  <img
                    src={apps[key].thumbnail_url}
                    alt={apps[key].name}
                    style={{
                      width: '40px',
                      height: '40px'
                    }}
                    className='mr2'/>
                  <div>
                    <strong
                      className='block'
                      style={{
                        marginBottom: '-0.25em'
                      }}>
                      {apps[key].name}
                    </strong>
                    <div>{apps[key].description}</div>
                  </div>
                </Wrapper>
              </a>
            </li>
          ))}
      </ul>
    </div>
  </section>
)

Apps.defaultProps = {
  apps: {}
}

Apps.propTypes = {
  apps: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  apps: state.user.app_permissions
})

export default connect(
  mapStateToProps,
  null
)(Apps)
