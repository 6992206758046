import {
  GET_SUBSCRIPTION_PRICE,
  GET_STORAGE_CYCLE_PRICE,
  GET_STORAGE_PRORATED_PRICE,
  GET_TEAM_SEAT_CYCLE_PRICE
} from "@actions/prices"
import { get } from "@src/helpers/fetch"
import queryString from "query-string"

export const getSubscriptionPrice = queries => dispatch => dispatch(
  get(
    GET_SUBSCRIPTION_PRICE,
    `billing/subscription?${queryString.stringify(queries)}`
  )
)

export const getStorageCyclePrice = amount => (dispatch, getState) =>
  dispatch(
    get(GET_STORAGE_CYCLE_PRICE, `billing/storage`)
  )

export const getStorageProratedPrice = amount => (dispatch, getState) =>
  dispatch(
    get(GET_STORAGE_PRORATED_PRICE, `billing/storage?amount=${amount}`)
  )

export const getTeamSeatCyclePrice = () => (dispatch, getState) =>
  dispatch(get(GET_TEAM_SEAT_CYCLE_PRICE, `billing/team-seat`))
