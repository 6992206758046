const INITIAL_STATE = {
  status: "PENDING",
  list: [],
  search: "",
  edit: {
    show: false,
    status: "READY",
    errors: [],
    role: {}
  },
  defaults: {
    status: "PENDING"
  }
}

export default INITIAL_STATE
