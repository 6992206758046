import {
  GET_ASPERA_ENTITLEMENTS,
  GET_ASPERA_ENTITLEMENT_QUOTE,
  PURCHASE_ASPERA_ENTITLEMENT,
  SELECT_ENTITLEMENT,
  TOGGLE_PURCHASE_MODAL,
  GET_ASPERA_ENTITLEMENT_ITEMS
} from "@actions/settings/aspera_entitlements"
import { get, post } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"

export const getAsperaEntitlements = (page = 0) => dispatch => {
  dispatch(get(GET_ASPERA_ENTITLEMENTS, `billing/aspera-entitlements?page=` + page))
}

export const getAsperaEntitlementItems = () => dispatch => {
  dispatch(get(GET_ASPERA_ENTITLEMENT_ITEMS, `billing/aspera-entitlements/items`))
}

export const selectEntitlement = entitlement => dispatch =>
  dispatch({
    type: SELECT_ENTITLEMENT,
    payload: { entitlement }
  })

export const getEntitlementQuote = code => dispatch => {
  dispatch(get(GET_ASPERA_ENTITLEMENT_QUOTE, `billing/aspera-entitlements/quote?key=` + code))
}

export const purchaseEntitlement = selectedCardID => (dispatch, getState) => {
  dispatch(
    post(
      PURCHASE_ASPERA_ENTITLEMENT,
      `billing/aspera-entitlements`,
      {
        key: getState().settings.aspera_entitlements.selectedEntitlement.value,
        customerCardID: selectedCardID
      },
      res => {
        dispatch(addGlobalMessage("The entitlements have been added to your account!", "success"))
      },
      err => {
        dispatch(addGlobalMessage("There was an issue. Please try again.", "error"))
      }
    )
  )
}

export const togglePurchaseModal = () => dispatch => {
  dispatch({ type: TOGGLE_PURCHASE_MODAL })
}
