import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import {
  selectEntitlement,
  purchaseEntitlement,
  togglePurchaseModal,
  getAsperaEntitlementItems,
  getEntitlementQuote
} from "@api/settings/aspera_entitlements"
import { useDidMount } from "rooks"
import { Status } from "@bitcine/cs-theme"
import { getPaymentMethods, updateNewPaymentMethod } from "@api/settings/billing/index"
import Add from "../../billing/components/add/index"

const Purchase = ({
  selectedEntitlement,
  selectEntitlement,
  purchaseEntitlement,
  togglePurchaseModal,
  isSubmitting,
  getAsperaEntitlementItems,
  isLoadingItems,
  items,
  getPaymentMethods,
  billing,
  defaultCreditCardID,
  updateNewPaymentMethod,
  getEntitlementQuote,
  quote
}) => {
  const [step, setStep] = useState("select")
  const [selectedCardID, setSelectedCardID] = useState("")
  useDidMount(() => {
    getAsperaEntitlementItems()
    getPaymentMethods()
    setSelectedCardID(defaultCreditCardID)
  })
  useEffect(() => {
    if (selectedEntitlement) {
      getEntitlementQuote(selectedEntitlement.value)
    }
  }, [getEntitlementQuote, selectedEntitlement])
  return (
    <div>
      <Status pending={isLoadingItems} error={false}>
        <>
          {step === "select" && !isLoadingItems && (
            <>
              <Select
                value={selectedEntitlement}
                onChange={selected => selectEntitlement(selected)}
                options={filterItems(items)}/>
              <div className='right-align py1'>
                <span
                  style={{
                    fontSize: '1.25em'
                  }}>
                  Price:{" "}
                </span>
                <strong
                  style={{
                    fontSize: '1.25em'
                  }}>
                  {quote.status === "READY" ? quote.subtotal : "..."}
                </strong>
              </div>
              <div className='flex justify-between mt3'>
                <button
                  className='cs-button white'
                  disabled={isSubmitting}
                  onClick={() => {
                    togglePurchaseModal()
                    selectEntitlement(null)
                  }}>
                  Cancel
                </button>
                <button
                  className='cs-button'
                  disabled={!selectedEntitlement || isSubmitting}
                  onClick={() => setStep("payment_method")}>
                  Next: Payment Method
                </button>
              </div>
            </>
          )}
          {step === "payment_method" && !isLoadingItems && billing.status === "READY" && (
            <>
              <h4 className='mb1'>Summary</h4>
              <div className='flex justify-between mb1'>
                <span>{selectedEntitlement.label}</span>
                <span>{quote.status === "READY" ? quote.subtotal : "..."}</span>
              </div>
              <div className='flex justify-between mb1'>
                <span>Tax</span>
                <span>{quote.status === "READY" ? quote.tax : "..."}</span>
              </div>
              <div className='flex justify-between mb3'>
                <span>Total</span>
                <strong>{quote.status === "READY" ? quote.total : "..."}</strong>
              </div>
              {billing.credit_cards.length > 0 && (
                <Select
                  className='mb1'
                  value={parseCard(
                    billing.credit_cards.filter(card => card._id === selectedCardID)[0],
                    defaultCreditCardID
                  )}
                  onChange={selected => setSelectedCardID(selected.value)}
                  options={billing.credit_cards.map(card => parseCard(card, defaultCreditCardID))}/>
              )}
              <div className='mb3'>
                <button className='cs-button white small' onClick={() => updateNewPaymentMethod({ show: true })}>
                  Add Payment Method
                </button>
              </div>
              <div className='flex justify-between mt3'>
                <button className='cs-button white' disabled={isSubmitting} onClick={() => setStep("select")}>
                  Previous: Entitlement Size
                </button>
                <button
                  className='cs-button'
                  disabled={isSubmitting}
                  onClick={() => purchaseEntitlement(selectedCardID)}>
                  {isSubmitting ? (
                    <>Please wait...</>
                  ) : (
                    <>Purchase ({quote.status === "READY" ? quote.total : "..."})</>
                  )}
                </button>
              </div>
            </>
          )}
          {billing.new.show && <Add allow={["credit-card"]}/>}
        </>
      </Status>
    </div>
  )
}

Purchase.propTypes = {
  organization: PropTypes.object,
  selectedEntitlement: PropTypes.object,
  selectEntitlement: PropTypes.func.isRequired,
  togglePurchaseModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  getAsperaEntitlementItems: PropTypes.func.isRequired,
  isLoadingItems: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  billing: PropTypes.object.isRequired,
  defaultCreditCardID: PropTypes.string,
  updateNewPaymentMethod: PropTypes.func.isRequired,
  getEntitlementQuote: PropTypes.func.isRequired,
  quote: PropTypes.object.isRequired,
  purchaseEntitlement: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.user.organization,
  selectedEntitlement: state.settings.aspera_entitlements.selectedEntitlement,
  isSubmitting: state.settings.aspera_entitlements.isSubmitting,
  isLoadingItems: state.settings.aspera_entitlements.isLoadingItems,
  items: state.settings.aspera_entitlements.items,
  billing: state.settings.billing,
  defaultCreditCardID: state.user.default_credit_card_id,
  quote: state.settings.aspera_entitlements.quote
})

const mapDispatchToProps = dispatch => ({
  selectEntitlement: bindActionCreators(selectEntitlement, dispatch),
  purchaseEntitlement: bindActionCreators(purchaseEntitlement, dispatch),
  togglePurchaseModal: bindActionCreators(togglePurchaseModal, dispatch),
  getAsperaEntitlementItems: bindActionCreators(getAsperaEntitlementItems, dispatch),
  getPaymentMethods: bindActionCreators(getPaymentMethods, dispatch),
  updateNewPaymentMethod: bindActionCreators(updateNewPaymentMethod, dispatch),
  getEntitlementQuote: bindActionCreators(getEntitlementQuote, dispatch)
})

const filterItems = items => {
  return items.map(item => {
    return {
      value: item.code,
      label: item.name
    }
  })
}

const parseCard = (card, defaultID) => {
  return {
    value: card._id,
    label: `${card.brand} **** **** **** ${card.last4} ${card._id === defaultID ? "(Default)" : ""}`
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Purchase)
