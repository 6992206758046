import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import Helmet from "react-helmet"
import { updateAccount, validateEmail, signUp } from "@api/account"
import Select from "@src/components/select/"
import { ReactComponent as GoogleIcon } from "@src/media/icons/btn_google_light_normal_ios.svg"
import { GLOBALS } from "@src/config"

const SignUp = ({ account, updateAccount, validateEmail, signUp, countries, location, history }) => {
  const callbackQueries = `?${queryString.stringify(queryString.parse(window.location.search))}`
  const festival = queryString.parse(window.location.search).festival || null
  useEffect(() => {
    if (festival) {
      updateAccount({ festival })
    }
  }, [festival, updateAccount])
  return (
    <>
      <Helmet title='CineSend | Sign Up'/>
      <form>
        {festival && <div className={'border-bottom border-gray-5 mb3 pb3'}>
          <label className='cs-label' htmlFor={`__input-festival`}>Festival Name</label>
          <input
            autoFocus={true}
            value={account.festival}
            data-test-id={`account-festival-input`}
            className={`cs-input col-12 ${account.validation_errors.includes('festival') ? "error" : ""}`}
            type={'text'}
            id={`__input-festival`}
            onChange={event => updateAccount({ festival: event.target.value })}/>
        </div>}
        <label className='cs-label' htmlFor={`__input-name`}>Name</label>
        <input
          autoFocus={true}
          value={account.full_name}
          data-test-id={`account-name-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('full_name') ? "error" : ""}`}
          type={'text'}
          id={`__input-name`}
          onChange={event => updateAccount({ full_name: event.target.value })}/>
        <label className='cs-label mt2' htmlFor={`__input-email`}>Email</label>
        <input
          value={account.email}
          data-test-id={`account-email-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('email') ? "error" : ""}`}
          type={'text'}
          id={`__input-email`}
          onChange={event => updateAccount({ email: event.target.value })}/>
        {account.validation_errors.includes('email') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-email-error-text`}
            className='block red'>
            Valid email is required.
          </small>
        )}
        <label className='cs-label mt2'  htmlFor={`__input-password`}>Password</label>
        <input
          value={account.password}
          data-test-id={`account-password-input`}
          className={`cs-input col-12 ${account.validation_errors.includes('password') ? "error" : ""}`}
          type={'password'}
          id={`__input-password`}
          onChange={event => updateAccount({ password: event.target.value })}/>
        {account.validation_errors.includes('password') && (
          <small
            style={{
              marginTop: '0.3em'
            }}
            data-test-id={`account-password-error-text`}
            className='block red'>
            Password is required.
          </small>
        )}
        <label className='cs-label mt2'  htmlFor={`__input-country`}>Country</label>
        <Select
          options={countries}
          value={countries.find(opt => opt.value === account.country_code)}
          className={`col-12 ${account.validation_errors.includes('country_code') ? "error" : ""}`}
          isLoading={!countries || !countries.length}
          onChange={event => updateAccount({ country_code: event.value })}
          clearable={false}/>
        {account.error_message && (
          <small data-test-id='account-error-text' className='red block mt1 mb2'>
            {account.error_message}
          </small>
        )}
        <button
          id='cinesend-next-button'
          type='submit'
          tabIndex='1'
          data-test-id='account-submit-btn'
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            if (account.festival) {
              signUp()
            }
            else {
              validateEmail(() => history.push(`/payment`))
            }
          }}
          disabled={account.status === "PENDING" || !account.full_name || !account.email || !account.password || !account.country_code}
          className={`cs-button ${account.status.toLowerCase()} col-12 mt2`}>
          {account.festival ? 'Sign up' : 'Next'}
        </button>
      </form>
      <div className='mt3 py1 flex justify-center'>
        Or, sign up with your Google Account:
      </div>
      <a href={GLOBALS.SOCIAL_AUTH_URL + callbackQueries}>
        <button
          id='cinesend-sign-up-with-google-button'
          className='cs-button white col-12 flex items-center p0 cinesend-sign-up-with-google-button'
          tabIndex='2'
          onClick={e => {
            e.preventDefault()
            window.location = GLOBALS.SOCIAL_AUTH_URL + callbackQueries
          }}>
          <GoogleIcon style={{ height: '38px' }} className='absolute'/>
          <div className='flex flex-auto justify-center'>
            Sign up with Google
          </div>
        </button>
      </a>
    </>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  countries: state.utilities.countries.list,
})

const mapDispatchToProps = dispatch => ({
  updateAccount: bindActionCreators(updateAccount, dispatch),
  validateEmail: bindActionCreators(validateEmail, dispatch),
  signUp: bindActionCreators(signUp, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))