export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_PENDING = "GET_COUNTRIES_PENDING"
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_REJECTED"
export const GET_COUNTRIES_FULFILLED = "GET_COUNTRIES_FULFILLED"

export const GET_USER_LOCATION = "GET_USER_LOCATION"
export const GET_USER_LOCATION_PENDING = "GET_USER_LOCATION_PENDING"
export const GET_USER_LOCATION_FAILED = "GET_USER_LOCATION_REJECTED"
export const GET_USER_LOCATION_FULFILLED = "GET_USER_LOCATION_FULFILLED"

export const UPDATE_UTILITIES = "UPDATE_UTILITIES"