import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateOrganization, saveOrganization } from "@api/settings/organization"
import { addGlobalMessage } from "@api/global_messages"
import Dropzone from "react-dropzone"
import { post } from "@src/helpers/fetch"

const UPLOAD_LOGO = 'UPLOAD_LOGO'

const Logo = ({ post, saveOrganization, updateOrganization, addGlobalMessage, organization }) => {
  const onDrop = files => {
    const file = files[0]
    post(
      UPLOAD_LOGO,
      `organizations/${organization._id}/settings/logo`,
      { fileName: file.name },
      res => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', res.signedUrl);
        xhr.onreadystatechange = function() {
          if (xhr.readyState !== 4) return
          if (xhr.status === 200) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.onload = () => {
              updateOrganization({ logo_url: reader.result })
              saveOrganization("logo_url", res.logoUrl)
            }
          }
          else {
            addGlobalMessage("An error occurred uploading your logo. Please try again.", "error")
          }
        };
        xhr.send(file)
      }
    )
  }
  return (
    <Dropzone accept={['.png', '.jpg', '.jpeg', '.gif']} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          style={{
            width: '220px',
            height: '220px'
          }}
          className={
            "box-shadow rounded flex items-center justify-center p2 pointer " +
            (isDragActive ? "bg-gray-5" : "bg-white")
          }>
          <input {...getInputProps()}/>
          {isDragActive ? (
            <div>Drop files here...</div>
          ) : organization.logo_url ? (
            <img
              className='block'
              src={organization.logo_url}
              alt={organization.name}
              style={{
                maxHeight: '100%',
                maxWidth: '100%'
              }}/>
          ) : (
            <div className='center'>
              <span className='material-icons block muted'>image</span>
              <strong
                style={{
                  marginTop: '-0.5em'
                }}
                className='muted block'>
                No Logo
              </strong>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  )
}

Logo.propTypes = {
  organization: PropTypes.shape({
    logo_url: PropTypes.string,
    _id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  updateOrganization: PropTypes.func.isRequired,
  saveOrganization: PropTypes.func.isRequired,
  addGlobalMessage: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.settings.organization
})

const mapDispatchToProps = dispatch => ({
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch),
  addGlobalMessage: bindActionCreators(addGlobalMessage, dispatch),
  post: bindActionCreators(post, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logo)
