import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import CreditCard from "./components/credit/index"
import GiftCard from "./components/gift/index"
import Wrap from "./components/wrap"
import Background from "./components/background"

const Card = ({ card, isDefault, isDeleting }) => {
  const brand = card.type === "gift-card" ? "gift-card" : card.brand.toLowerCase().replace(" ", "_")
  const year = dayjs().format("YYYY")
  const month = dayjs().format("M")
  const expired = brand !== "gift-card" && (card.exp_year < year || (card.exp_year === year && card.exp_month < month))
  const failed = card.has_failed_payment === true
  return (
    <Wrap isDeleting={isDeleting}>
      <Background brand={brand}>
        {card.type === "gift-card" ? (
          <GiftCard card={card}/>
        ) : (
          <CreditCard isDefault={isDefault && !failed} brand={brand} card={card} isExpired={expired} isFailed={failed}/>
        )}
      </Background>
    </Wrap>
  )
}

Card.propTypes = {
  card: PropTypes.shape({
    type: PropTypes.oneOf(["credit-card", "gift-card"]).isRequired,
    _id: PropTypes.string.isRequired
  }).isRequired,
  isDefault: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired
}

export default Card
