import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Status } from "@bitcine/cs-theme"
import { getSubscriptionPrice } from "@api/prices"
import { changeBillingInterval } from '@api/settings/billing'

const ChangeInterval = ({ closeModal, newInterval, changeBillingInterval, getSubscriptionPrice, pending, price }) => {
  useEffect(() => {
    getSubscriptionPrice({ interval: newInterval })
  }, [getSubscriptionPrice])
  return (
    <Modal open={true} title='Change Billing Interval' onClose={() => closeModal()}>
      <Status pending={pending}>
        {price.interval_amount > 0 &&
          <div className={'bg-gray-5 rounded p2 my1'}>
            <div>Your new subscription will be <b>{price.interval_amount_formatted}</b>.</div>
            {price.prorated_amount &&
            <div>
              Approximate prorated amount being charged today for your current billing cycle: <b>{price.prorated_amount_formatted}</b>.
            </div>}
          </div>}
        <div className='mt3 right-align'>
          <button
            disabled={pending}
            className={`${pending ? "pending" : ""} cs-button capitalize`}
            onClick={e => changeBillingInterval()}>
            Change to {newInterval} Billing
          </button>
        </div>
      </Status>
    </Modal>
  )
}

ChangeInterval.propTypes = {
  pending: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  getSubscriptionPrice: PropTypes.func.isRequired,
  changeBillingInterval: PropTypes.func.isRequired,
  price: PropTypes.object,
  newInterval: PropTypes.string
}

const mapStateToProps = state => ({
  price: state.utilities.subscription_price,
  pending: !state.utilities.subscription_price.interval_amount
})

const mapDispatchToProps = dispatch => ({
  changeBillingInterval: bindActionCreators(changeBillingInterval, dispatch),
  getSubscriptionPrice: bindActionCreators(getSubscriptionPrice, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeInterval)
