const css = `
  body {
    background: #f9f9f9;
    min-height: 100vh;
  }
  .tether-element {
    z-index: 5;
  }
`

export default css
