import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Status } from '@bitcine/cs-theme'
import { getUpcomingInvoice } from "@api/settings/billing"

const UpcomingInvoice = ({ getUpcomingInvoice, invoice }) => {
  useEffect(() => {
    getUpcomingInvoice()
  }, [])
  if (invoice && !invoice.items) {
    return null
  }
  return (
    <div className={'p2 bg-white rounded box-shadow mb2'}>
      <h4>Upcoming Invoice</h4>
      <Status pending={!invoice}>
        {invoice && <>
          <p className={'mb2'}>
            This is a preview of the invoice that will be billed on <b>{invoice.billing_date}</b>. It may change if your subscription is updated.
          </p>
          <div className={'border-bottom border-gray-5'}/>
          {invoice.items.filter(item => item.quantity).map((item, i) =>
            <div>
              {(i === 0 || i > 0 && item.period_start !== invoice.items[i - 1].period_start) &&
                <div className={'border-bottom border-gray-5 p1'}>
                  <span className={'muted'}>{item.period_start} - {item.period_end}</span>
                </div>}
              <div key={i} className={'flex items-center justify-between border-bottom border-gray-5 p1'}>
                <span>{item.description}</span>
                <span>{item.formatted}</span>
              </div>
            </div>)}
          <div className={'flex justify-end mt2'}>
            <b>Total: {invoice.formatted_total}</b>
          </div>
        </>}
      </Status>
    </div>
  )
}

UpcomingInvoice.propTypes = {
  invoice: PropTypes.object
}

const mapStateToProps = state => ({
  invoice: state.settings.billing.upcoming_invoice
})

const mapDispatchToProps = dispatch => ({
  getUpcomingInvoice: bindActionCreators(getUpcomingInvoice, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingInvoice)
